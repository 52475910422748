import { t } from 'i18next'
import '../../assets/css/product.css'

export const ProductIngredientsNutrients = ({ingredients, nutrients}) => {    

    const productPageTabClick = e => {
        const makeActive = `.product_page_tabs__content__unit[data-content="${e.target.dataset.show}"]`
        document.querySelector('.product_page_tabs__tab.active').classList.remove('active')
        e.target.classList.add('active')
        document.querySelector('.product_page_tabs__content__unit.active').classList.remove('active')
        document.querySelector(makeActive).classList.add('active')
    }

    return (
        <div className="product_page_tabs wrapper">
            <div className="product_page_tabs__tabs">
                {ingredients.length > 0 &&
                    <button className="product_page_tabs__tab active" data-show="ingredients_and_allergens" onClick={productPageTabClick}>{t('niki.b2b.productpage.ingredientsallergens')}</button>
                }
                {nutrients.length > 0 &&
                    <button className="product_page_tabs__tab" data-show="nutritional_information" onClick={productPageTabClick}>{t('niki.b2b.productpage.nutritionalinformation')}</button>
                }
                {/* <button className="product_page_tabs__tab" data-show="additional_information" onClick={ProductPageTabClick}>{t('niki.b2b.productpage.additionalinfomation')}</button> */}
            </div>
            <div className="product_page_tabs__content">
                {ingredients.length > 0 &&
                    <div className="product_page_tabs__content__unit active" data-content="ingredients_and_allergens">
                        <h4>{t('niki.b2b.productpage.ingredientsallergens')}</h4>
                        <p>{ingredients}</p>
                    </div>
                }
                {nutrients.length > 0 &&
                    <div className="product_page_tabs__content__unit" data-content="nutritional_information">
                        <table className="product_page_tabs__table">
                            <thead>
                                <tr>
                                    <th>{t('niki.b2b.productpage.nutrient')}</th>
                                    <th>{t('niki.b2b.cart.value')}</th>
                                    {/* <th>% {t('niki.b2b.productpage.based')}*</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {nutrients.map((e, i) =>
                                    <tr key={`nutrient${i}`}>
                                        <td>{e.name}</td>
                                        <td>{e.value}</td>
                                        {/* <td>x%</td> */}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="product_page_tabs__content__unit__star">*{t('niki.b2b.productpage.typicalvalues')}<br />{t('niki.b2b.productpage.reference')}</div>
                    </div>
                }
                {/* <div className="product_page_tabs__content__unit" data-content="additional_information">
                </div> */}
            </div>
        </div>
    )
}
            
            