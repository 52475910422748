import { t } from "i18next"

export const ModalShowRows = (props) => {
    const { refEl, title1, title, ClosePopup, content, buttonOne, buttonTwo, onClickOneButton } = props
    
    return (
        <div className="cart_popup__wrapper" ref={refEl}>
            <div className="cart_popup__content">
                <div className="cart_popup__header">{t(title)}</div>
                    <div className="cart_popup__r_u_sure">{t(title1)}</div>
                <div className="wrapper_content_show_rows">
                    {content.map(email => <div key={email.email}>{email.email}</div>)}
                </div>
                {buttonTwo
                    ? <div className="rename_cart__popup__buttons">
                        <button className="btn btn-primary">{t("niki.b2b.shoppingcart.deletecart")}</button>
                        <button className="btn" type="button" onClick={ClosePopup}>{t('niki.b2b.cartflow.cancel')}</button>
                    </div>
                    : <div style={{display: 'flex', justifyContent: 'center'}}>
                        <button className="btn" type="button" onClick={onClickOneButton}>
                            {t(buttonOne ? buttonOne : 'niki.b2b.cartflow.cancel')}
                        </button>
                    </div>
                }
            </div>
            <div className="cart_popup__overlay" onClick={ClosePopup}></div>
        </div>
    )
}