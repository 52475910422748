import { NavLink } from 'react-router-dom'
import { ReactComponent as HomeGrey } from './assets/svg/homegrey.svg'

import './assets/css/breadcrumbs.css'

export const Breadcrumbs = props => {
    
    return (
        <nav className={!props.noclasswrapper ? 'wrapper' : null }>
            <ul className="breadcrumbs">
                <li><NavLink to="/"><HomeGrey /></NavLink></li>
                {props.itemlist.map((el,i)=><li key={`breadcrumb${i}`}><NavLink to={el.url}>{el.title}</NavLink></li>)}
            </ul>
        </nav>
    )
}