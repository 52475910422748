import { t } from 'i18next'
import { xCompanyID, xTenant } from '../config'


export const PageMaintenance = () => {

    return (
        <>
            <header className="header">
                <div style={{height: 30}}></div>
                <div className="header_white">
                    <div className={[`header_white__inner wrapper`,'overduehide'].join(' ')}>
                        <div className="main_logo"><img src={`/logo/${xTenant}_${xCompanyID}.svg`} alt="Logo" /></div>
                    </div>
                </div>
            </header>
            <div className="wrapper center page404">
                <div className="big404">{t('niki.b2b.maintenance')}!</div>
                {/* {t('niki.b2b.404.h1')}*/}
                <h1>{t('niki.b2b.maintenance.apologies_inconvenience')}!</h1>
                <div>{t('niki.b2b.maintenance.undergoing_scheduled_maintenance_enhance')}. {t('niki.b2b.maintenance.be_back_online_shortly')}!</div>
                <div className="help_404">{t('niki.b2b.maintenance.thanks_patience_understanding')}.</div>
            </div>
            <footer className="footer"></footer>
        </>  
    )
}