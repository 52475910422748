import { t } from "i18next"
import { useDispatch } from 'react-redux'
import { clearCartContent } from '../reduxToolkit/cartSlice'
import { getRequestApi } from '../helpers/apiHelper'
import { PriceFormat, ShowLoader, HideLoader } from "../functions"

const requestDeleteCartsItem = cartItemID => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/cart-headers/${cartItemID}`
    const response = getRequestApi({URL, method: 'DELETE'})
    return response
}

export const ModalDeleteCart = ({refEl, cartIDReal, skus, gros, cartID, ClosePopup, ConfirmDelete, props}) => {
    
    const dispatch = useDispatch() 

    const onDelete = e => {
        const deleteID = e.target.dataset.id
        const deleteIDReal = parseInt(e.target.dataset.idreal)
        const cartIDReal = localStorage.getItem('cart.id.real') ? parseInt(localStorage.getItem('cart.id.real')) : null
        ShowLoader()
        requestDeleteCartsItem(deleteID)
        .then(response => {
            if (response.ok) {
                ConfirmDelete(deleteID)
                if (deleteIDReal === cartIDReal) {
                    localStorage.removeItem('cart.uuid')
                    localStorage.removeItem('cart.id')
                    localStorage.removeItem('cart.id.real')
                }
                dispatch(clearCartContent())
                ClosePopup()
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    return (
        <div className="cart_popup__wrapper" ref={refEl}>
                <div className="cart_popup__content">
                    <div className="cart_popup__header">{t('niki.b2b.shoppingcart.deletecart')}</div>
                    <div className="cart_popup__r_u_sure">{t('niki.b2b.shoppingcart.deletecartconfirm')}</div>
                    <div className="cart_popup__data">
                        <div>
                            <div className="cart_popup__data__def">{t('niki.b2b.cart.shoppingcart')}</div>
                            <div>{cartIDReal}</div>
                        </div>
                        <div>
                            <div className="cart_popup__data__def">{t('niki.b2b.debts.skus')}</div>
                            <div>{skus}</div>
                        </div>
                        <div>
                            <div className="cart_popup__data__def">{t('niki.b2b.product.gross')}</div>
                            <div>{PriceFormat(gros)}</div>
                        </div>
                    </div>
                    <div className="cart_popup__buttons" style={{ display: 'flex', justifyContent: 'center', paddingLeft: 0}}>
                        <button className="btn btn-info" data-id={cartID} data-idreal={cartIDReal} onClick={onDelete}
                            style={{ whiteSpace: 'pre-wrap', width: 'max-content', maxWidth: 275, wordBreak: 'break-all', lineHeight: '2rem'}}
                        >{t('niki.b2b.cartflow.yesdelete')}</button>
                        <button className="btn" onClick={ClosePopup}>{t('niki.b2b.cartflow.cancel')}</button>
                    </div>
                </div>
                <div className="cart_popup__overlay" onClick={ClosePopup}></div>
         </div>
    )
}