import { useEffect, useState } from 'react'

import { getRequestOptions } from "../../helpers/apiHelper"
import { ShowLoader, HideLoader } from '../../functions'

import '../../assets/css/header.css'

const now = new Date()
const todayIs = `${String(now.getDate()).padStart(2, '0')}.${String(now.getMonth() + 1).padStart(2, '0')}.${now.getFullYear()}`

export const RedBanner = () => {

    const [redBanner, setRedBanner] = useState('')
    
    useEffect(() => {
        if (localStorage.getItem('red.banner') && localStorage.getItem('red.banner.date') && localStorage.getItem('red.banner.date') === todayIs) {
            setRedBanner(localStorage.getItem('red.banner'))
        } else {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/announcements?announcement_channel_id=RgpxYPa3MnJjqMw8`,getRequestOptions())
            .then(response => response.json())
                .then(json => {
                    if (json.data && json.data[0] && json.data[0].matchcode) {
                    const redBannerTextTranslate = json.data[0]?.descriptions ? json.data[0].descriptions.filter(el => el.key === "description") : []
                    const redBannerMatchcode = json.data[0].matchcode
                    const redBannerText = redBannerTextTranslate.length > 0
                        ? redBannerTextTranslate[0].text.replaceAll('p>', '').replace('</', '').replace('<', '')
                        : redBannerMatchcode

                    setRedBanner(redBannerText)
                    localStorage.setItem('red.banner',redBannerText)
                    localStorage.setItem('red.banner.date', todayIs)
                    }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }, [])

    return (
        <div className="header_red">
            <div className="wrapper">{redBanner}</div>
        </div>
    )
}