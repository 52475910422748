import { t } from 'i18next'
import { GetHistoryData } from '../../functions'

import '../../assets/css/product.css'
import '../../assets/css/svg.css'
import { ReactComponent as Box } from '../../assets/svg/box.svg'

export const QtyInBox = ({ props, productInBox, stockUnitCode }) => {
    return GetHistoryData(props.id).length > 0
        ? <div className={["prod_qty_in_box", props.productView !== 'grid' && "prod_qty_in_box__list"].join(' ')}>
            <div>{t('niki.b2b.tooltip.box')}</div>
            <Box />{productInBox || 1} {stockUnitCode}
        </div>
        : <div></div>
}
