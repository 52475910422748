import React, { useEffect, useState, useRef } from 'react'
import { xCompanyID, xTenant, siteDomain } from '../../config'
import { ShowLoader, HideLoader } from '../../functions'
import { t } from "i18next"

import { applyAccount } from './loginFunctions'
import { LoginFormFooter } from './LoginFormFooter'
import LoadableImage from "../LoadableImage/LoadableImage"

import '../../assets/css/style.css'
import '../../assets/css/loginform.css'

const GetBannerData = e => {
    const toReturn = {}
    if (e.attachments && e.attachments.data && e.attachments.data[0] && e.attachments.data[0].awsFileUrl) toReturn.image = e.attachments.data[0].awsFileUrl
    if (e.matchcode) toReturn.title = e.matchcode
    if (e.contentUrl && e.contentUrl.data && e.contentUrl.data.url) toReturn.url = e.contentUrl.data.url || ''
    if (toReturn.url) toReturn.target = toReturn.url.includes('http') ? `_blank` : `_self`
    return toReturn
}

export const SelectAccount = () => {

    const [entityID, setEntityID] = useState('')
    const [accountName, setAccountName] = useState('')
    const [entityData, setEntityData] = useState([])
    const [initialData, setInitialData] = useState([])
    const [bannerTwo, setBannerTwo] = useState({})
    
    const inputRef = useRef(null);
    const clearButtonRef = useRef(null);

    useEffect(() => {
        ShowLoader()
        const data = JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            e.content_subtype_id === 'J8RvMwowm7k2pNOr' && e.contentType?.data?.real_id === 1 && setBannerTwo(GetBannerData(e))
        })
        
        const accountsData = JSON.parse(localStorage.getItem('entity_accounts'))
        if (accountsData) {
            const accounts = accountsData.map(account => { return { ...account, account_name: account.external_id + ' - ' + account.matchcode } })
            setEntityData(accounts)
            setInitialData(accounts)
            setEntityID(accountsData[0].id)
            setAccountName(accountsData[0].external_id + ' - ' + accountsData[0].matchcode)
        }
        HideLoader()
    }, [])

    // start hide chat button
    const buttonElement = document.getElementById('silc-btn')
    const [isButtonElement, setIsButtonElement] = useState(false)
    useEffect(() => {
        let timer;
        setIsButtonElement(true)
        clearTimeout(timer);
        timer = setTimeout(() => {           
            if (buttonElement) {
                if (siteDomain === 'b2b-generix.niki24.pt' || siteDomain === 'b2b-demo.niki24.pt') {
                    buttonElement.style.display = 'none';
                }
            } else {
                setIsButtonElement(false)
            }
        }, 100);
 
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isButtonElement])
    // end hide chat button

    const SelectAccountListSelected = () => {
        document.querySelector('.custom_select__wrapper').classList.add('active')
        const currentElement = document.querySelector('li.current');
       
        if (currentElement) {
            currentElement.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            })
        }
    }

    const onChangeInput = (event) => {
        setAccountName(event.target.value)
        if (!event.target.value) {
            setEntityData(initialData)
            return
        } else {
            const accountsFilter = initialData.filter(ac => ac.account_name.toLowerCase().includes(event.target.value.toLowerCase()))
            setEntityData(accountsFilter)
        }
    }

    const SelectAccountList = e => {
        const thisElement = e.target
        document.querySelector('.custom_select__wrapper').classList.remove('active')
        document.querySelector('.custom_select__selected').dataset.id = thisElement.dataset.id
        document.querySelector('.custom_select__selected').dataset.entityname = thisElement.dataset.accountname
        document.querySelector('.custom_select__list li.current') && document.querySelector('.custom_select__list li.current').classList.remove('current')

        setEntityID(thisElement.dataset.id)
        setAccountName(thisElement.dataset.accountname)
        setEntityData(initialData)
        document.querySelector('.custom_select__wrapper').classList.remove('active')
    }

    const onApplyAccount = () => {
        applyAccount(entityID)
    }

    const handleClearClick = () => {
        setAccountName('');
        SelectAccountListSelected();
        setEntityData(initialData);
        inputRef.current.focus();
    };

    return (
        <>
            <div className="login_form__wrapper">
                <div className="login_form">
                    <a href="/" className="login_form__logo"><img src={`/logo/${xTenant}_${xCompanyID}.svg`} alt="Logo" /></a>
                    <div className="login_form__header">{t('niki.b2b.login.selectaccount')}</div>
                    <div className="marginbottom20">{t('niki.b2b.account.hello')} {localStorage.getItem('user_name')}. {t('niki.b2b.login.selectaccountplease')}.</div>

                    {entityData && (
                        <>
                            <div className="custom_select__wrapper marginbottom20">
                                <input className="custom_select__selected"
                                    type="text"
                                    value={accountName}
                                    placeholder={t('niki.b2b.login.selectaccount')}
                                    onClick={SelectAccountListSelected}
                                    onChange={(e) => onChangeInput(e)}
                                    data-id={entityID}                                  
                                    data-accountname={accountName}
                                    ref={inputRef}
                                />
                                 <span
                                    ref={clearButtonRef}
                                    className="clear-button"
                                    onClick={handleClearClick}
                                    style={{ display: accountName ? 'inline-block' : 'none' }}
                                >
                                    ×
                                </span>
                                <ul className="custom_select__list">
                                    {entityData.map((e,i)=><li
                                        key={`accountlist${i}`}
                                        onClick={SelectAccountList}
                                        data-id={e.id}
                                        data-accountname={e.account_name}
                                        className={e.account_name === accountName ? 'current' : null}
                                    >{e.account_name}</li>)}
                                </ul>
                            </div>
                            <div>
                                <button className="btn" onClick={onApplyAccount}>
                                    {t('niki.b2b.login.enter')}
                                </button>
                            </div>
                        </>
                    )}
                    <LoginFormFooter />
                </div>
                <a href={bannerTwo.link} className="login_form__banner">
                    <LoadableImage src={bannerTwo.image} alt={bannerTwo.title} />
                </a>
            </div>
            <div className="loader"></div>
        </>
    )
}