import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, useParams, useLocation } from 'react-router-dom'
import { t } from 'i18next'
import { priceLineID } from "../config"
import { PriceFormat, GetMainImageCart, ShowLoader, HideLoader, TranslateJSON, GetHistoryData, FormatDate, getTranslatedName, storingCartData } from '../functions'
import { setIsMore5CartsAlert } from '../reduxToolkit/universalSlice'

import { Breadcrumbs } from "../breadcrumbs"
import { TablePreloader } from '../components/TablePreloader'
import { ProdHistoryPopup } from '../components/ProdHistoryPopup'
import { getRequestOptions, getOpenPDF } from "../helpers/apiHelper"

import "./../assets/css/cart.css"

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

import { ReactComponent as Duplicate } from './../assets/svg/orderdetailsduplicate.svg'
import { ReactComponent as AdditionalNote } from './../assets/svg/additionalnote.svg'
import { ReactComponent as Download } from './../assets/svg/orderdetailsdownload.svg'
import { ReactComponent as Info } from './../assets/svg/orderdetailsinfo.svg'
import { ReactComponent as Refresh } from './../assets/svg/refresh.svg'
import { ReactComponent as ProdHistoryIcon } from './../assets/svg/prodhistory.svg'

import { AG_GRID_LOCALE } from '../aggridtranslations/locale.full'

import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey("CompanyName=SmartDigit, Lda,LicensedApplication=NIKI24,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-030570,SupportServicesEnd=27_August_2023_[v2]_MTY5MzA5MDgwMDAwMA==197101bde71f7a31a7dac0b3d3f0a6c8")

const DateFormat = localDate => localDate.split(' ')[0].replaceAll('/', '.')
const GetDiscountPrice = data => {
    let toReturn = 0
    /*
    if (data && data.length > 0) {
        data.forEach(e => {
            if (e.priceline_id === priceline_id) toReturn = e.tax_included_price
        })
    }
    */
    if (data && data.length > 0 && data[0]) toReturn = data[0].tax_included_price
    return toReturn
}

let cartSummOld = 0
let cartSumm = 0

const OrderDetailsContent = props => {
    const dispatch = useDispatch()
    const location = useLocation()

    const orderID = props.order_id
    const currentUrl = window.location
    const isDocument = currentUrl.pathname.includes('document')

    const gridRef = useRef()
    const [rowData, setRowData] = useState([])
    const [orderData, setOrderData] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false)

    const localeText = useMemo(() => {
        return TranslateJSON(AG_GRID_LOCALE)
    }, [])

    /* HistoryProduct */
    const [openHistoryProduct, setOpenHistoryProduct] = useState(false)
    const [idHistoryProduct, setIdHistoryProduct] = useState('')
    const [productData, setProductData] = useState({})

    useEffect(() => {
        if (openHistoryProduct) {
            const PopupSelector = `.product_history__wrapper_${idHistoryProduct}`
            document.querySelector(PopupSelector).classList.add('active')
        }
    }, [openHistoryProduct, idHistoryProduct])

    const ShowProdHistory = e => {
        setOpenHistoryProduct(true)
        setIdHistoryProduct(e.target.dataset.id)
        setProductData(JSON.parse(e.target.dataset.data))
    }
    /* HistoryProduct */

    const gridStyles = {
        color: 'var(--darkgrey)',
        fontSize: '16px'
    }
    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        }
    }, [])

    const onClickGoToProduct = () => {
        localStorage.setItem('url.back', `${location.pathname}`)
    }

    const columnDefsIncluded = [
        {
            headerName: '',
            field: 'prodimg',
            width: 60,
            floatingFilter: false,
            pinned: 'left',
            // checkboxSelection: true,
            cellRenderer: params => <NavLink to={`/productpage/${params.data.id}/`} onClick={onClickGoToProduct} className="grid_prod_img"><img src={params.data.image} alt={params.data.productName} loading="lazy" /></NavLink>
        },
        {
            field: 'item_id',
            headerName: 'ID',
            width: 120,
            pinned: 'left',
            // cellRenderer: params => params.data.item.data.item_id
        },
        {
            field: 'productName',
            headerName: t('niki.b2b.cartflow.product'),
            filter: 'agTextColumnFilter',
            width: 300,
            autoHeight: true,
            cellRenderer: params => {
                const prodHistory = GetHistoryData(params.data.id)
                return (
                    <>
                        <strong className="ag_prodname">{params.value}</strong>
                        {prodHistory.length > 0
                            &&
                            <>
                                <button
                                    className="ag_prodhistory"
                                    onClick={ShowProdHistory}
                                    data-id={params.data.item_id}
                                    data-data={JSON.stringify(params.data)}
                                ><Refresh className="ag_prodhistory__refresh" />{prodHistory.filter((_, index) => index === 0).map((e, i) =>
                                    <span key={`historyel${params.data.id}${i}`}>{FormatDate(e.created_at)} &mdash; {e.quantity} {t('niki.b2b.returnproducts.units')}</span>
                                )}<ProdHistoryIcon className="ag_prodhistory__list" /></button>
                            </>
                        }
                    </>
                )
            }
        },
        {
            field: 'group',
            headerName: t('niki.b2b.shoppingcart.group'),
            width: 100,
            enableRowGroup: true,
            filter: false,
        },
        {
            field: '',
            headerName: t('niki.b2b.feedback.type'),
            width: 100,
            enableRowGroup: true,
            filter: false,
            cellRenderer: params => 'Frozen'
        },
        {
            field: 'qty',
            headerName: t('niki.b2b.product.quantity'),
            cellStyle: { 'justifyContent': "center" },
            width: 110,
        },
        {
            field: 'price',
            headerName: t('niki.b2b.cart.unipr'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => `${PriceFormat(params.value)}`
        },
        {
            field: '',
            headerName: t('niki.b2b.allproducts.discounts'),
            width: 100,
            filter: false,
            cellRenderer: params => {
                const priceOld = params.data.priceOld
                const price = params.data.price
                if (priceOld > 0) {
                    return <div className="aggrid_discount">-{Math.round((priceOld - price) / priceOld * 100)}%</div>
                } else {
                    return null
                }
            }
        },
        {
            field: 'total',
            headerName: t('niki.b2b.cart.total'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => <strong>{PriceFormat(params.value)}</strong>
        },
        {
            field: 'VAT',
            headerName: t('niki.b2b.product.vat'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => params.data.VAT > 0 ? `${params.value}%` : null
        }
        /*
        {
            field: '',
            headerName: 'Disc.',
            width: 100,
            filter: false,
            cellRenderer: params => {
                let price_discount = 0
                const price = params.data.unit_price
                if (params.data.item && params.data.item.data.itemDiscounts.data) {
                    params.data.item.data.itemDiscounts.data.forEach(el => {
                        if (el.priceline_id === priceline_id) price_discount = el.tax_included_price
                    })
                }
                if (price_discount > 0) {
                    return <div className="aggrid_discount">-{Math.round((price_discount-price)/price_discount*100)}%</div>
                } else {
                    return null
                }
            }
        },
        */
    ]
    // eslint-disable-next-line
    const [columnDefs, setColumnDefs] = useState(columnDefsIncluded)

    useEffect(() => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/documents/${orderID}?include=details.item,details.item.itemDescriptions,status&realKey=true&showVat=true&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    const res = json.data
                    setOrderData({
                        orderIDReal: res.id_real,
                        orderDate: res.created_at ? DateFormat(res.created_at) : '',
                        deliveryDate: res.delivery_at ? DateFormat(res.delivery_at) : '',
                        status: res.status.data && res.status.data.matchcode,
                        processID: res.process_id,
                        processIDReal: res.process_id_real,
                        totalSKUs: (res.details && res.details.data && res.details.data.length) || 0,
                        totalGross: res.total_gross_amount,
                        totalNet: res.total_gross_amount,
                        totalVat: res.total_tax_amount,
                        invoice: `${res.key} ${res.number}`,
                        externalId: res.external_id || '',
                        key: `${res.key} - ${res.serial}`,
                        additional_info: res.footer_message
                    })
                    setRowData([])
                    // setHistoryData([])
                    json.data.details && json.data.details.data && json.data.details.data.forEach(e => {
                        if (Array.isArray(e?.item?.data)) {
                            if (e?.item?.data > 0) {
                                console.error('item.data is Array in orderdetails')
                                console.log('item.data is Array in orderdetails')
                            } else {
                                console.error('item.data is empty Array in orderdetails')
                            }
                        } else {
                            const priceDiscount = (e.item.data && e.item.data.itemDiscounts && e.item.data.itemDiscounts.data && GetDiscountPrice(e.item.data.itemDiscounts.data)) || 0
                            const prodQty = e.quantity || 0
                            // let price = (e.item && e.item.data && e.item.data.itemPrices && e.item.data.itemPrices.data && e.item.data.itemPrices.data.length > 0 && parseFloat(e.item.data.itemPrices.data[0].unit_price)) || 0

                            let price = e.unit_price || 0
                            let priceOld = 0
                            let prodGross = (e.item && e.item.data && e.item.data.itemPrices && e.item.data.itemPrices.data && e.item.data.itemPrices.data.length > 0 && e.item.data.itemPrices.data[0].tax_included_price && parseFloat(e.item.data.itemPrices.data[0].tax_included_price)) || 0

                            // if (e.item && e.item.data && e.item.data.itemDiscounts && e.item.data.itemDiscounts.data && e.item.data.itemDiscounts.data.length > 0 && e.item.data.itemDiscounts.data[0].unit_price) {
                            //     priceOld = price
                            //     price = parseFloat(e.item.data.itemDiscounts.data[0].unit_price)
                            //     prodGross = parseFloat(e.item.data.itemDiscounts.data[0].tax_included_price)
                            // }
                            const prodVat = prodGross - price

                            const tempObj = {
                                id: (e.item && e.item.data && e.item.data.id) || '',
                                productName: getTranslatedName(e.item.data) ? getTranslatedName(e.item.data) : e.description,
                                item_id: (e.item && e.item.data && e.item.data.item_id) || '',
                                group: e?.item?.data?.ItemFamily?.data?.description ? e.item.data.ItemFamily.data.description : '',
                                type: e?.item?.data?.itemKinds?.data[0]?.name ? e.item.data.itemKinds.data[0].name : '',
                                image: e?.item?.data?.attachments && GetMainImageCart(e.item.data.attachments, e.item.data.attachment_id),
                                stock: e?.item?.data?.itemStocks?.data[0]?.available ? e.item.data.itemStocks.data[0].available : 0,
                                price: price,
                                UNIcode: e.unit_code_name ? e.unit_code_name : e.unit_code,
                                qtyinbox: e.coefficient_2,
                                priceOld: priceOld,
                                price_discount: priceDiscount,
                                qty: prodQty,
                                VAT: Math.round(prodVat / price * 100),
                                total: parseFloat(prodQty * price).toFixed(2),
                            }
                            cartSummOld += (priceOld > 0 ? priceOld : price) * prodQty
                            cartSumm += price * prodQty
                            setRowData(prevState => ([...prevState, tempObj]))
                        }
                    })
                    setDataLoaded(true)
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }, [orderID])

    const DuplicateClick = () => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/document-to-cart/${orderID}?realKey=true`, getRequestOptions('POST'))
            .then(response => response.json())
            .then(json => {
                if (json.message && json.errors) {
                    dispatch(setIsMore5CartsAlert(true))
                }
                if (json.uuid) {
                    storingCartData(json.uuid, json.real_id, json.id)
                    window.location.href = '/myshoppingcart/'
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }
    return (
        <>
            {isDocument
                ? <Breadcrumbs itemlist={[
                    { 'url': '/account/', 'title': t('niki.b2b.menu.myaccount') },
                    { 'url': '/Invoices/', 'title': t('niki.b2b.menu.myinvoices') },
                    { 'url': `/document/${orderID}/`, 'title': `${t('niki.b2b.document')} ${orderData.orderIDReal ? `${orderData.key} ${orderData.orderIDReal}` : ''}` }
                ]} />
                : <Breadcrumbs itemlist={[
                    { 'url': '/account/', 'title': t('niki.b2b.menu.myaccount') },
                    { 'url': '/orders/', 'title': t('niki.b2b.account.myorders') },
                    { 'url': `/order/${orderID}/`, 'title': `${t('niki.b2b.feedback.orderid')} ${orderData.orderIDReal ? `${orderData.key} ${orderData.orderIDReal}` : ''}` }
                ]} />
            }
            <div className="wrapper">
                <h1>{isDocument ? t('niki.b2b.document') : t('niki.b2b.feedback.orderid')}  {orderData.key} {orderData.orderIDReal}</h1>
                <div className="order_details__two_columns">
                    <div>
                        {openHistoryProduct &&
                            <ProdHistoryPopup
                                matchcode={productData.name}
                                unit_code={productData.UNIcode}
                                qty_in_box={productData.qtyinbox}
                                item_id={productData.item_id}
                                imgsrc={productData.image}
                                setOpenHistoryProduct={setOpenHistoryProduct}
                                product_id={productData.id}
                            />
                        }

                        {dataLoaded
                            ? <div className="ag-theme-alpine" style={{ height: 1057, width: '100%' }}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowHeight="76"
                                    rowStyle={gridStyles}
                                    groupDisplayType={'groupRows'}
                                    groupRowRenderer={'agGroupCellRenderer'}
                                    rowGroupPanelShow={'always'}
                                    animateRows={true}
                                    singleClickEdit={true}
                                    localeText={localeText}
                                    suppressDragLeaveHidesColumns={true}
                                >
                                </AgGridReact>
                            </div>
                            : <TablePreloader />
                        }
                    </div>
                    <div>
                        <div className="order_details__summary">
                            <div className="order_details__aside__header">{t('niki.b2b.orders.summary')}</div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalskus')}</div>
                                <div>{orderData.totalSKUs}</div>
                            </div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalnet')}</div>
                                <div>{PriceFormat(orderData.totalNet)}</div>
                            </div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalvat')}</div>
                                <div>{PriceFormat(orderData.totalVat)}</div>
                            </div>
                            {cartSumm !== cartSummOld
                                &&
                                <>
                                    <hr />
                                    <div className="cart_aside__table red">
                                        <div>{t('niki.b2b.orders.saved')}</div>
                                        <div>{PriceFormat(cartSummOld - cartSumm)}</div>
                                    </div>
                                </>
                            }
                            <hr />
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalgros')}</div>
                                <div className="cart_aside__table__big_price">{PriceFormat(orderData.totalGross)}</div>
                            </div>
                            <button className="btn btn-block btn-dublicate_order" onClick={DuplicateClick}>{t('niki.b2b.orders.duplicateorder')}<Duplicate /></button>
                        </div>
                        <div className="order_details__status">
                            <div>
                                <div className="order_details__aside__header">{t('niki.b2b.orders.status')}</div>
                                <div className="cart_aside__table">
                                    <div>{t('niki.b2b.debts.orderdate')}</div>
                                    <div>{orderData.orderDate}</div>
                                </div>
                                {/* <div className="cart_aside__table">
                                    <div>{t('niki.b2b.orders.deliverystatus')}</div>
                                    <div className="processing">{orderData.status}</div>
                                </div> */}
                                <div className="cart_aside__table">
                                    <div>{t('niki.b2b.orders.deliverydate')}</div>
                                    <div>{orderData.deliveryDate}</div>
                                </div>
                                <div className="cart_aside__table">
                                    <div>{t('niki.b2b.orders.trackinginfo')}</div>
                                    <div>Monolith</div>
                                </div>
                                <div className="cart_aside__table">
                                    <div>{t('niki.b2b.orders.invoiceno')}</div>
                                    <div> {isDocument ? orderData.orderIDReal : orderData.externalId}</div>
                                </div>
                                <div className="cart_aside__table">
                                    <div>{t('niki.b2b.debts.processid')}</div>
                                    <div>{orderData.processIDReal}</div>
                                </div>
                                {orderData.additional_info && <div className="cart_aside__table">
                                    <div>{t('niki.b2b.orders.additionalnote')}</div>
                                    <div data-title={orderData.additional_info} className="orders_obs">
                                        <AdditionalNote />
                                    </div>
                                </div>}
                            </div>
                            <div className="order_details__status__buttons">
                                <button
                                    className="btn btn-info"
                                    data-uuid={orderID}
                                    onClick={() => getOpenPDF(orderID, dispatch)}
                                >{t('niki.b2b.orders.document')} <Download /></button>
                                {/* <button className="btn btn-info">{t('niki.b2b.orders.invoicedownload')} <Download /></button> href="/submit-feedback/"*/}
                                <button className="btn btn-info" onClick={() => { window.location.href = "/submit-feedback/" }}>
                                    {t('niki.b2b.feedback.submit')} <Info />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const OrderDetails = () => {
    const { id } = useParams()
    return (
        <OrderDetailsContent order_id={id} />
    )
}