import { useEffect, useState } from "react"
import { bookletsTypeID, currentMainColor } from "../../config"
import { t } from "i18next"
import { NavLink } from 'react-router-dom'

import { Breadcrumbs } from "../../breadcrumbs"
import { ShowLoader, HideLoader, getProductsIdArray } from "../../functions"
import { getRequestOptions } from "../../helpers/apiHelper"
import LoadableImage from "../LoadableImage/LoadableImage"

const FormatDate = localDate => {
    const fullDate = new Date(localDate)
    return `${String(fullDate.getDate()).padStart(2, '0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}

export const Promotions = () => {

    const BookletClick = (event, e) => {
        document.querySelector('.promotions__booklet.current').classList.remove('current')
        event.target.classList.add('current')
        setCurrentBooklet(e)
    }

    const [booklets,setBooklets] = useState([])
    const [currentBooklet,setCurrentBooklet] = useState({})

    useEffect(()=>{
        ShowLoader()
        // fetch(`${process.env.REACT_APP_API_DOMAIN}/marketing-campaigns?include=attachments,typeableType,marketingCampaignContents,group&search=group_id%3A${groupIDs[xCompanyID]}`,getRequestOptions())
        fetch(`${process.env.REACT_APP_API_DOMAIN}/marketing-campaigns?include=attachments,items&search=typeableType.id:${bookletsTypeID}&limit=30`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            setBooklets([])
            let currentAssigned = false
            const data = json.data || []
            data.sort((a, b) => {
                return new Date(a.created_at) - new Date(b.created_at);
            }).reverse()
            data.length > 0 && data.forEach((e, i) => {
                let pdfPath = ''
                let thumbPath = ''
                if (e.attachments && e.attachments.data && e.attachments.data.length > 0) {
                    e.attachments.data.reverse().forEach(el => {
                        if (el.extension === 'pdf') {
                            pdfPath = el.awsFileUrl
                        } else {
                            thumbPath = el.awsFileUrl
                        } 
                    })
                }
               
                const tempObj = {
                    id: e.id,
                    title: e.matchcode,
                    pdf: pdfPath,
                    thumb: thumbPath,
                    productsIdArray: e.items?.data?.length ? getProductsIdArray(e.items.data) : [],
                    from: FormatDate(e.start_at),
                    to: FormatDate(e.finish_at),
                }
                if (thumbPath && thumbPath.length > 0 ) {
                    setBooklets(prevState => ([...prevState, tempObj]))
                    if (!currentAssigned) {
                        setCurrentBooklet(tempObj)
                        currentAssigned = true
                    }
                }
            })
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }, [])


    const onBookletView = () => {
        localStorage.setItem('booklet.data', JSON.stringify(currentBooklet))
    }

    return (
        <>
            <Breadcrumbs itemlist={[{'url':'/marketing/','title':t('niki.b2b.menu.marketing')},{'url':'/promotional-booklets/','title':t('niki.b2b.marketing.booklets')}]} />
            <div className="wrapper">
                <h1 className="promotions__h1">{t('niki.b2b.marketing.booklets')}</h1>
                <div className="promotions__two_columns">
                    <div className="promotions__two_columns__first">
                        <a href={currentBooklet.pdf} target="_blank" rel="noopener noreferrer">
                            {/* <img className="promotional_booklet__main_image" src={currentBooklet.thumb} alt={currentBooklet.title} /> */}
                            <LoadableImage src={currentBooklet.thumb} alt={currentBooklet.title} styleImg={{border: `1px solid ${currentMainColor}`, borderRadius: 10}} />
                        </a>
                        <div className="promotions__two_columns__first__current_booklet">{t('niki.b2b.promotions.current')}</div>
                        <div>{currentBooklet.from} - {currentBooklet.to}</div>
                    </div>
                    <div className="promotions__two_columns__second">
                        <h2>{t('niki.b2b.promotions.currentoffers')}</h2>
                        <div className="promotions__two_columns__text">{t('niki.b2b.promotions.text')} {t('niki.b2b.promotions.text2')}</div>
                        <button className="btn btn-info" disabled={currentBooklet?.productsIdArray?.length === 0}>
                        <NavLink to={`/all-products/?booklet=${currentBooklet.id}`} onClick={onBookletView} className="btn btn-info" >{t('niki.b2b.promotions.view')}</NavLink>
                        </button>
                    </div>
                </div>
                <h3 className="all_promotional_booklets">{t('niki.b2b.promotions.all')}</h3>
                <div className="promotions__booklets">
                    {booklets.length > 0 && booklets.map((e,i) =>
                        <div
                            className={[`promotions__booklet`,i === 0 && 'current'].join(' ')}
                            onClick={(event) => BookletClick(event, e)}
                            key={`promobooklet${i}`}
                        >
                            <div className="promotions__booklet__img">{e.thumb &&
                                // <img className="promotions__booklet__image" src={e.thumb} alt={e.title} />
                                <LoadableImage  src={e.thumb} alt={e.title} />
                            }</div>
                            <div className="promotions__booklet__dates">{e.from} - {e.to}</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}