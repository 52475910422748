import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'

import { setIsClickOnAllProductsInHeader } from '../../reduxToolkit/universalSlice'

import '../../assets/css/header.css'

export const TopMenu = () => {
    const dispatch = useDispatch()

    return (
        <div className="top_menu">
            <ul className="wrapper">
                <li><NavLink to="/all-products/" onClick={() => { dispatch(setIsClickOnAllProductsInHeader(true)) }}>
                    {t('niki.b2b.menu.allproducts')}
                </NavLink></li>
                <li><NavLink to="/promotional-booklets/">{t('niki.b2b.menu.promotions')}</NavLink></li>
                <li><NavLink to="/brands/">{t('niki.b2b.menu.brands')}</NavLink></li>
                <li><NavLink to="/contact/">{t('niki.b2b.menu.contact')}</NavLink></li>
                <li><NavLink to="/marketing/">{t('niki.b2b.menu.marketing')}</NavLink></li>
            </ul>
        </div>
    )
}
