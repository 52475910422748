import React, { useEffect, useRef, useState } from 'react'
import { ShowLoader, HideLoader } from '../../functions'
import { t } from "i18next"
import { useDispatch } from 'react-redux'
import { setIsNoEntityAccount } from '../../reduxToolkit/universalSlice'

import { applyAccount } from './loginFunctions'
import { CodeLifeTimer } from './CodeLifeTimer'
import { getRequestOptionsNoAuth, getRequestOptions } from '../../helpers/apiHelper'

import '../../assets/css/style.css'
import '../../assets/css/loginform.css'

// import { ReactComponent as ArrowRightWhite } from '../../assets/svg/arrow-right-white.svg'

import 'react-phone-input-2/lib/style.css'

const FetchClientInfo = async () => {
    const response = await fetch(`https://geolocation-db.com/json/`)
    const json = await response.json()
    return json
}
const FetchCode = async (url,requestOptions) => {
    const response = await fetch(url,requestOptions)
    const json = await response.json()
    return json
}

// const tokenLifetime = 60*60*24*1000

export const SnedVerifyCode = ({ from, setWrongCode, setEmail, status, setIsCheckCode }) => {
    const dispatch = useDispatch()

    const [code, setCode] = useState(['', '', '', '', '', ''])
    const inputRefs = useRef([])

    useEffect(()=>{
        FetchClientInfo()
        .then(json => {
            localStorage.setItem('client.info',JSON.stringify(json))
        })
    }, [])

    const getEntityData = () => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/user/entity-accounts`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    if (json.data.length > 1) {
                        localStorage.setItem('entity_accounts', JSON.stringify(json.data))
                        window.location.href = '/select-account/'
                    } else if (json.data.length === 1) {
                        applyAccount(json.data[0].id)
                    } else if (json.data.length === 0) {
                        dispatch(setIsNoEntityAccount(true))
                    }
                }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const SubmitCode = formCode => {
        setIsCheckCode(true)
        const url = `${process.env.REACT_APP_API_DOMAIN}/login/verify-code`
        const requestOptions = getRequestOptionsNoAuth('POST', null, 'application/json')
        requestOptions.body = JSON.stringify({
            code: formCode,
            client_info: localStorage.getItem('client.info'),
            // username: 'webapp_user',
            // password: 'webapp_user',
        })
        
        ShowLoader()
        FetchCode(url,requestOptions).then(json => {
            if (json.access_token) {
                localStorage.setItem('user_name',`${json.user.Name}`)
                localStorage.setItem('access.token',`${json.access_token.trim()}`)
                localStorage.setItem('refresh.token',`${json.refresh_token.trim()}`)
                // localStorage.setItem('access.token.lifetime', parseInt(Date.now()) + tokenLifetime)
                localStorage.setItem('access.token.lifetime',parseInt(Date.now())+parseInt(json.expires_in)*1000)
                getEntityData()
            } else {
                setWrongCode(true)
            }
        })
        .finally(() => {
            HideLoader()
            setIsCheckCode(false)
        })
    }
   
    const OtpChangeInput = (event, index) => {
        const value = event.target.value
        setCode((prevCode) => {
            const newCode = [...prevCode]
            newCode[index] = value
            newCode.join('').length === 6 && SubmitCode(newCode.join(''))
            return newCode
        })
        if (value.length > 0 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus()
        }
    }
   
    const OtpKeyDown = (event, index) => {
        if (event.key === 'Backspace' && code[index] === '' && index > 0) {
            const newCode = [...code]
            newCode[index - 1] = ''
            setCode(newCode)
            inputRefs.current[index - 1].focus()
        }
    }
    
    const OtpCtrlV = (event) => {
        event.preventDefault()
        const clipboardData = event.clipboardData || window.clipboardData
        const pastedData = clipboardData.getData('Text')
        if(event.target.id === 'enteringEmail'){
            setEmail(pastedData)
        }else{
            const newCode = pastedData
                .slice(0, 6)
                .split('')
                .map((char) => (isNaN(char) ? '' : char))
            newCode.join('').length === 6 && SubmitCode(newCode.join(''))
            setCode(newCode.concat(Array(6 - newCode.length).fill('')))
            inputRefs.current[5].focus()
        }
    }

    useEffect(() => {
        document.addEventListener('paste', OtpCtrlV)
        return () => {
            document.removeEventListener('paste', OtpCtrlV)
        }
    // eslint-disable-next-line
    }, [])

    return (
        <>
            <h3>{t('niki.b2b.login.checkemail')}</h3>
            <div className="six_digits">
                {code.map((value, index) => (
                    <input
                        key={index}
                        type="text"
                        value={value}
                        className="six_digits__single"
                        onChange={(event) => OtpChangeInput(event, index)}
                        onKeyDown={(event) => OtpKeyDown(event, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                        autoFocus={index === 0 ? true : false}
                    />
                ))}
            </div>
            <CodeLifeTimer from={from} setWrongCode={setWrongCode} status={status} />
            {/* <button className="login_form__button" >
                {t('niki.b2b.login.login')}
                <ArrowRightWhite />
            </button> */}
        </>
    )
}