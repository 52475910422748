import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { t } from 'i18next'
import { priceLineID } from "../config"
import { PriceFormat, ShowLoader, HideLoader, TranslateJSON, storingCartData } from '../functions'
import { getRequestOptions } from "../helpers/apiHelper"
import { setIsMore5CartsAlert } from '../reduxToolkit/universalSlice'

import { Breadcrumbs } from "../breadcrumbs"
import { TablePreloader } from "../components/TablePreloader"

import "./../assets/css/cart.css"

import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

import { ReactComponent as AdditionalNote } from './../assets/svg/additionalnote.svg'
import { ReactComponent as Duplicate } from './../assets/svg/ordersduplicate.svg'
// import { ReactComponent as Download } from './../assets/svg/debtsdownload.svg'

import { AG_GRID_LOCALE } from '../aggridtranslations/locale.full'

import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey("CompanyName=SmartDigit, Lda,LicensedApplication=NIKI24,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-030570,SupportServicesEnd=27_August_2023_[v2]_MTY5MzA5MDgwMDAwMA==197101bde71f7a31a7dac0b3d3f0a6c8")

const DateFormat = localDate => localDate.split(' ')[0].replaceAll('/','.')

export const Orders = () => {
    return (
        <>
            <Breadcrumbs itemlist={[{'url':'/account/','title':t('niki.b2b.menu.myaccount')},{'url':'/orders/','title':t('niki.b2b.account.myorders')}]} />
            <OrdersContent />
        </>
    )
}

const gridStyles = {
    color: 'var(--darkgrey)',
    fontSize: '16px',
    overflow: 'visible'
}

const OrdersContent = () => {
    const dispatch = useDispatch()

    // eslint-disable-next-line
    const [gridApi, setGridApi] = useState(null)
    const [rowData, setRowData] = useState([])
    const [loading, setLoading] = useState(false)

    const perPage = 100

    const onGridReady = (params) => {
        setGridApi(params.api)
    }

    const localeText = useMemo(() => {
        return TranslateJSON(AG_GRID_LOCALE)
    }, [])

    useEffect(() => {
        // if (gridApi) {
                setLoading(true)
                ShowLoader()
            //const dataSource = {
                // getRows: (params) => {
                //     const page = params.endRow / perPage
                    fetch(`${process.env.REACT_APP_API_DOMAIN}/documents-invoices?document_type=1&include=details,status&searchFields=account_id%3A%3D%3Bkey%3A%3D&realKey=true&search=documentType.id%3A2%3Baccount_id%3A${localStorage.getItem('account.entity.id.real')}%3Bkey%3ANE&limit=${perPage}&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`,getRequestOptions())
                    .then(resp => resp.json())
                    .then(json => {
                        let localData = []
                        
                        json.data.length > 0 && json.data.forEach(e => {
                            const tempObj = {
                                uuid: e.uuid,
                                ProcessID: e.process_id,
                                ProcessIDReal: e.process_id_real,
                                OrderID: e.id,
                                Nuber: e.number,
                                OrderIDReal: e.id_real,
                                Date: DateFormat(e.created_at),
                                Month: DateFormat(e.created_at).split('.')[1],
                                Year: DateFormat(e.created_at).split('.')[2],
                                Source: 'TELEPHONE',
                                SKUs: (e.details && e.details.data && e.details.data.length) || 0,
                                Gros: e.total_tax_included_amount,
                                VAT: e.total_tax_amount,
                                Net: e.total_net_amount,
                                // Status: e.status.data && e.status.data.matchcode,
                                Invoice: `${e.key} ${String(e.number).padStart(6,'0')}`,
                                Obs: e.footer_message,
                                key: `${e.key} ${e.serial} - `,
                            }
                            localData.push(tempObj)
                        })
                        setRowData(localData)
                        setLoading(false)
                        // gridApi.setRowData(localData)
                        // params.successCallback(localData, json.meta.pagination.total)
                    }).catch(err => {
                        // params.successCallback([], 0)
                        setLoading(false)
                        console.error(err)
                    }).finally(() => HideLoader())
                //}
            //}
           // gridApi.setDatacource(dataSource)
        // }
    }, [])

    const DuplicateClick = e => {
        const dataset = e.target.dataset
        const uuid = dataset.uuid
    
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/document-to-cart/${uuid}?realKey=true`,getRequestOptions('POST'))
            .then(response => response.json())
            .then(json => {
                if (json.message && json.errors) {
                    dispatch(setIsMore5CartsAlert(true))
                }
                if (json.uuid) {
                    storingCartData(json.uuid, json.real_id, json.id)
                    window.location.href = '/myshoppingcart/'
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }
    
    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            // filter: 'agNumberColumnFilter',
            // filter: 'agTextColumnFilter',
            // floatingFilter: true
        }
    }, [])

    return (
        <div className="wrapper">
            <h1>{t('niki.b2b.account.myorders')}</h1>
            {loading
                ? <TablePreloader />
                : <div className="ag-theme-alpine" style={{ height: 810, width: '100%' }}>
                    <AgGridReact
                        rowModelType={'clientSide'}
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        //rowModelType={'infinite'}
                        //cacheBlockSize={perPage}
                        //paginationPageSize={perPage}
                        pagination={false}
                        onGridReady={onGridReady}
                        rowHeight={60}
                        rowStyle={gridStyles}
                        groupDisplayType={'groupRows'}
                        groupRowRenderer={'agGroupCellRenderer'}
                        rowGroupPanelShow={'always'}
                        localeText={localeText}
                        sortable={true}
                        enableFilter={true}
                        suppressDragLeaveHidesColumns={true}
                    >
                        <AgGridColumn
                            field="ProcessIDReal"
                            headerName={t('niki.b2b.debts.processid')}
                            width={130}
                            filter={'agNumberColumnFilter'}
                            // filter={'agTextColumnFilter'}
                            enableRowGroup={true}
                            cellRenderer={params => {
                                const obj = { ...params.data }
                                return <NavLink to={`/process/${obj.ProcessID}/`} className="orders_link" onClick={() => localStorage.setItem('from_enter_process', 'orders')}>
                                    {obj.ProcessIDReal}
                                </NavLink>
                            }}
                        />
                        <AgGridColumn
                            field="Nuber"
                            headerName={t('niki.b2b.feedback.orderid')}
                            width={150}
                            filter={'agTextColumnFilter'}
                            cellRenderer={params => {
                                const obj = { ...params.data }
                                return <NavLink to={`/order/${obj.uuid}/`} className="orders_link">{obj.key} {obj.Nuber}</NavLink>
                            }}
                        />
                        <AgGridColumn
                            field="Date"
                            headerName={t('niki.b2b.product.date')}
                            width={150}
                            filter={'text'}
                            enableRowGroup={true}
                        />
                        <AgGridColumn
                            field="Year"
                            headerName={t('niki.b2b.orders.year')}
                            width={130}
                            filter={'agSetColumnFilter'}
                            enableRowGroup={true}
                        />
                        <AgGridColumn
                            field="Month"
                            headerName={t('niki.b2b.orders.month')}
                            width={130}
                            filter={'agSetColumnFilter'}
                            enableRowGroup={true}
                        />
                        {/* <AgGridColumn
                        field="Source"
                        headerName={t('niki.b2b.orders.source')}
                        width={130}
                    /> */}
                        <AgGridColumn
                            field="SKUs"
                            headerName={t('niki.b2b.debts.skus')}
                            width={100}
                            cellStyle={{ 'justifyContent': "center" }}
                        />
                        <AgGridColumn
                            field="Net"
                            headerName={t('niki.b2b.product.net')}
                            width={140}
                            cellStyle={{ 'justifyContent': "end" }}
                            cellRenderer={params => `${PriceFormat(params.value)}`}
                        />
                        <AgGridColumn
                            field="VAT"
                            headerName={t('niki.b2b.product.vat')}
                            width={130}
                            cellStyle={{ 'justifyContent': "end" }}
                            cellRenderer={params => `${PriceFormat(params.value)}`}
                        />
                        <AgGridColumn
                            field="Gros"
                            headerName={t('niki.b2b.product.gross')}
                            width={130}
                            cellStyle={{ 'justifyContent': "end" }}
                            cellRenderer={params => {
                                return `${PriceFormat(params.value)}`
                            }}
                        />
                        {/* <AgGridColumn
                            field="Status"
                            headerName={t('niki.b2b.feedback.status')}
                            width={120}
                            cellRenderer={params => {
                                return <div className={['orders_status', String(params.value).toLocaleLowerCase()].join(' ')}>{params.value}</div>
                            }}
                            enableRowGroup={true}
                        /> */}
                        {/* <AgGridColumn
                            field="Invoice"
                            headerName={t('niki.b2b.debts.invoice')}
                            width={140}
                            cellRenderer={params => {
                                const obj = { ...params.data }
                                return <>{obj.Invoice} <button data-uuid={obj.uuid} onClick={OpenPDF} className="marginleft10"><Download /></button></>
                            }}
                        /> */}
                        <AgGridColumn
                            headerName={t('niki.b2b.orders.duplicate')}
                            width={130}
                            cellRenderer={params => {
                                const obj = { ...params.data }
                                return <button
                                    className="orders_duplicate"
                                    onClick={DuplicateClick}
                                    data-uuid={obj.uuid}
                                    data-processid={obj.ProcessID}
                                    data-processidreal={obj.ProcessIDReal}
                                    data-orderid={obj.OrderID}
                                    data-orderidreal={obj.OrderIDReal}
                                    data-createdat={obj.Date}
                                    data-skus={obj.SKUs}
                                    data-gros={obj.Gros}
                                    data-vat={obj.VAT}
                                    data-net={obj.Net}
                                    data-status={obj.Status}
                                    data-invoice={obj.Invoice}
                                    data-obs={obj.Obs}
                                ><Duplicate /></button>
                            }}
                        />
                        <AgGridColumn
                            field="Obs"
                            headerName={t('niki.b2b.orders.obs')}
                            width={230}
                            cellStyle={{ overflow: 'visible' }}
                            cellRenderer={params => {
                                return params.value ? <div data-title={params.value} className="orders_obs"><AdditionalNote /></div> : null
                            }}
                        />
                    </AgGridReact>
                </div>
            }
        </div>
    )
}