import { t } from 'i18next'
import { PriceFormat } from '../../functions'
import '../../assets/css/product.css'
import '../../assets/css/svg.css'

export const RegularVatGross = ({ vat, gross, price }) => {
    return (
        <div className="regular_vat_gros">
            <div>
                <div className="regular_vat_gros_popup">
                    {t('niki.b2b.product.vat')} {Math.round(vat / price * 100)}%
                    <div>{t('niki.b2b.tooltip.vat')}</div>
                </div>{PriceFormat(vat)}
            </div>
            <div>
                <div className="regular_vat_gros_popup">
                    {t('niki.b2b.product.gross')}
                    <div>{t('niki.b2b.tooltip.gross')}</div>
                </div>{PriceFormat(gross)}
            </div>
        </div>
    )
}