import { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { t } from 'i18next'

import { CartContent } from '../CartContent'
import { getRequestOptions, updateProductInCart } from "../../helpers/apiHelper"

import { PriceFormat, GetTotalNet } from '../../functions'

import '../../assets/css/header.css'

import { ReactComponent as CartWhite } from '../../assets/svg/cartwhite.svg'
import { ReactComponent as QuickOrderIcon } from '../../assets/svg/quickorder.svg'
import { ReactComponent as AllShoppingCarts } from '../../assets/svg/allshoppingcarts.svg'

import { useDispatch, useSelector } from 'react-redux'

import { deleteCartProduct, updateCartProduct, setDeleteProductID, setIsCloseCartPreviewModal } from '../../reduxToolkit/cartSlice'
import { updateProduct } from '../../reduxToolkit/productsSlice'

const FormatDate = localDate => {
    const fullDate = new Date(localDate)
    return `${String(fullDate.getDate()).padStart(2, '0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}

export const CartPreviewModal = ({ cartData, hideCartPopup, showQuickOrder, showAllShoppingCarts }) => {

    const dispatch = useDispatch()

    const localIdReal = localStorage.getItem('cart.id.real')
    const newCartCreated = localStorage.getItem('create_new_cart')

    const isOpenMyShopingCart = useSelector(state => state.cartContent.isOpenMyShopingCart)
    const isCloseCartPreviewModal = useSelector(state => state.cartContent.isCloseCartPreviewModal)
    const cartNumProducts = useSelector(state => state.cartContent.count)
    const cartSummProducts = useSelector(state => state.cartContent.summ)
    const cartName = useSelector(state => state.cartContent.name)
    const cart = useSelector(state => state.cartContent)
    
    const headerCartPopupWrapper = useRef(null)
   
    const [shoppingCartsData, setShoppingCartsData] = useState([])
    const [isCloseModalt, setIsCloseModalt] = useState(false)

    useEffect(() => {
        let timer;
        clearTimeout(timer);
        timer = setTimeout(() => {
            headerCartPopupWrapper.current.classList.add('active')
        }, 200); 
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        let timer;

        if (isCloseModalt && hideCartPopup) {
            clearTimeout(timer);
            timer = setTimeout(() => hideCartPopup(), 400)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isCloseModalt])

    const closeModal = () => {
        setIsCloseModalt(true)
        headerCartPopupWrapper.current.classList.remove('active')
    }

    useEffect(() => {
        if (isCloseCartPreviewModal) {
            closeModal()
            dispatch(setIsCloseCartPreviewModal(false))
        }
        // eslint-disable-next-line
    }, [isCloseCartPreviewModal])

    const onRemoveCartItem = e => {
        const productID = e.target.dataset.id
        const cartDetail = e.target.dataset.cartdetail
        RemoveCartItem(productID, cartDetail)
    }

    const RemoveCartItem = (productID, cartDetail) => {
        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details/${cartDetail}`,getRequestOptions('DELETE'))
        .then(response => {
            if (response.ok) {
                dispatch(setDeleteProductID(cartDetail))
                dispatch(deleteCartProduct(cartDetail))
                dispatch(updateProduct({ id: productID, qty: 0 }))
            }
            return 'end'
        })
        .catch(error => console.error(error))
    }

    const addedCartToShoppingCarts = (el) => {
        const tempObj = {
            id: el.id_real,
            id_hash: el.id,
            uuid: el.uuid,
            date: FormatDate(el.created_at),
            cartname: el.matchcode || t('niki.b2b.cartflow.cart'),
            itemscount: (el.cartDetails && el.cartDetails.data && el.cartDetails.data.length) || 0,
            totalnet: (el.cartDetails && el.cartDetails.data && el.cartDetails.data.length > 0 && GetTotalNet(el.cartDetails.data)) || 0
        }
        return tempObj
    }

    const ChangeCartInput = (e, sale_unit_code) => {
        const value = e.target.value
        const regex = /^\d+(\.)?(\d{1,2})?$/

        const localValue = sale_unit_code === 'KG' ? value.slice(0, value.indexOf('.')+3) : parseInt(value) || 0
        if (sale_unit_code === 'KG' && !regex.test(value)) return

        const cartDetail = e.target.name
        const productid = e.target.dataset.productid
        dispatch(updateCartProduct({productid: productid, cartdetail: cartDetail, qty: localValue}))
        dispatch(updateProduct({id: productid, qty: localValue}))
        updateProductInCart(cartDetail,localValue)
    }

    useEffect(() => {
        if (newCartCreated && shoppingCartsData.length > 0) {
            const el = JSON.parse(newCartCreated)
            const tempObj = addedCartToShoppingCarts(el)
            setShoppingCartsData(prevState => ([tempObj, ...prevState]))
            localStorage.setItem('create_new_cart', '')
        }
    // eslint-disable-next-line
    },[newCartCreated])

    useEffect(() => {
        setShoppingCartsData(prev => prev.map(i => i.id === cart.idreal ? {...i, cartname: cart.name} : i))
    // eslint-disable-next-line
    }, [cartName])
    
    const showAllShoppingCartsModal = () => {
        closeModal()
        showAllShoppingCarts()
    }

    const last50Product = cartData.length > 0 && cartData.slice(cartData?.length > 50 ? -50 : 0)
    
    return (
        <div className="header_cart__popup__wrapper" ref={headerCartPopupWrapper}>
            <div className="header_cart__popup__wrapper__container">
                <div className="header_cart__popup">
                    <div className="header_cart__popup__header">{t('niki.b2b.cart.shoppingcart')}</div>
                    <div className="header_cart__popup__footer">
                        <button className={["header_cart__popup__footer__quick_order",  "custom_button_hover"].join(' ')} disabled={isOpenMyShopingCart} onClick={showQuickOrder}>
                            {t('niki.b2b.cart.quickorder')}
                            <QuickOrderIcon />
                        </button>
                        <button className={["header_cart__popup__footer__all_shopping_carts", "custom_button_hover"].join(' ')} onClick={showAllShoppingCartsModal}>
                            {t('niki.b2b.menu.allshoppingcarts')}
                            <AllShoppingCarts />
                        </button>
                    </div>
                    <div className="header_cart__popup__status">
                        <div className="header_cart__popup__status__first">
                            {cartName.length > 0 ? `${localIdReal} ${cartName}` : `${t('niki.b2b.cart.shoppingcart')} ${localIdReal ? localIdReal : ''}`}
                        </div>
                        <div className="header_cart__popup__status__second">
                            {t('niki.b2b.cart.last')} {cartNumProducts < 50 ? cartNumProducts : 50} {t('niki.b2b.header.of')} {cartNumProducts} {t('niki.b2b.cart.items')}
                        </div>
                    </div>
                    <CartContent cartData={last50Product} ChangeCartInput={ChangeCartInput} RemoveCartItem={onRemoveCartItem} activeRemoveItem={true} />
                    <div className="header_cart__popup__summary">
                        <NavLink to="/myshoppingcart/" className="header_cart__popup__summary__full_cart" onClick={closeModal}>
                            {t('niki.b2b.cart.viewfull')}
                            <CartWhite />
                        </NavLink>
                        <div className="header_cart__popup__summary__cart_total">
                            {t('niki.b2b.cart.carttotal')}
                            <span>{t('niki.b2b.cart.exclvat')}</span>
                        </div>
                        <div className="header_cart__popup__summary__summ">
                            {PriceFormat(cartSummProducts)}
                        </div>
                    </div>                        
                    <button className="close_popup" onClick={closeModal}></button>
                </div>
            </div>
            <div className="header_cart__popup__overlay" onClick={closeModal}></div>
        </div>
    )
}