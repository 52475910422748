import '../../assets/css/product.css'
import '../../assets/css/svg.css'
import { ReactComponent as StarEmpty } from '../../assets/svg/starempty.svg'
import { ReactComponent as Star } from '../../assets/svg/star.svg'

const ShowStarRating = e => {
    const productID = e.target.dataset.id
    document.querySelector(`.rating_popup__wrapper_${productID}`).classList.add('active')
}

export const StarRating = ({ id, ratingData }) => {
    return (
        <div className="star_rating">
            <span className="starwrapper" data-id={id} onClick={ShowStarRating}>
                <span className="starempty">
                    <StarEmpty /><StarEmpty /><StarEmpty /><StarEmpty /><StarEmpty />
                </span>
                <span className="starsnotempty" style={{ width: ratingData.rating * 20 + '%' }}>
                    <Star /><Star /><Star /><Star /><Star />
                </span>
            </span>
            {ratingData.ratingVoices ? <span className="ratingvotes">{ratingData.ratingVoices}</span> : null}
        </div>
    )
}
