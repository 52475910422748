import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { t } from 'i18next'

import { changeAdditionalNotes, changeDeliveryMethod, changePaymentMethod, changeWarehousePickup } from '../reduxToolkit/checkoutSlice'
import { setIsOpenTermsSaleAlert, setIsAccountBlockedModal } from '../reduxToolkit/universalSlice'
import { PriceFormat, ShowLoader, HideLoader, LangFix } from '../functions'

import { Breadcrumbs } from '../breadcrumbs'
import { AlertPopup } from '../components/AlertPopup'
import { DeliveryMethods } from '../components/checkout/DeliveryMethods'
import { DeliveryPayment } from '../components/checkout/DeliveryPayment'
import { getRequestOptions } from '../helpers/apiHelper'

import { ReactComponent as ArrowLeft } from '../assets/svg/checkoutgreyleftarrow.svg'

const GetDeliveryAddresses = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/entity-account-address/${localStorage.getItem('account.entity.id')}?include=company&realKey=true`
    const response = await fetch(URL, getRequestOptions())
    return response
}

export const Checkout = () => {

    const dispatch = useDispatch()

    const uuid = localStorage.getItem('cart.uuid')
    const [cartName, setCartName] = useState('')
    const [userData, setUserData] = useState({})
    const [cartIDReal, setCartIDReal] = useState('')
    const [deliveryMethod, setDeliveryMethod] = useState('1')
    const [step, setStep] = useState('one')
    const [isStepTwo, setStepTwo] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState(localStorage.getItem('payment.name'))
    const [termsOfSaleText, setTermsOfSaleText] = useState('')
    const [isErrorOrderCreation, setIsErrorOrderCreation] = useState(false)

    const cartNumProducts = useSelector(state => state.cartContent.count)
    const cartSummProducts = useSelector(state => state.cartContent.summ)
    const cartSummVat = useSelector(state => state.cartContent.vat)
    const cartSumm = parseFloat(useSelector(state => state.cartContent.summ))
    const cartSummOld = parseFloat(useSelector(state => state.cartContent.summold))
    const isOpenTermsSaleAlert = useSelector(state => state.universalContent.isOpenTermsSaleAlert)
    const isAccountBlocked = useSelector(state => state.universalContent.isAccountBlocked)

    const shoppingCartSummary = JSON.parse(localStorage.getItem('suppliers_data')) || []

    const AccordeonClick = e => e.target.parentNode.classList.toggle('active')
    const RadioClick = e => {
        document.querySelector('.checkout_accorderon__content__delivery.active').classList.remove('active')
        e.target.parentNode.classList.add('active')
        setDeliveryMethod(e.target.value)
        dispatch(changeDeliveryMethod(e.target.value))
    }

    useEffect(() => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-header/${uuid}/${localStorage.getItem('account.entity.id')}?realKey=1`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                //setCartID(json.data.id)
                setCartIDReal(json.data.id_real)
                setCartName(json.data.matchcode)
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }, [uuid])

    useEffect(() => {
        isAccountBlocked && dispatch(setIsAccountBlockedModal(isAccountBlocked))
        // eslint-disable-next-line
    }, [isAccountBlocked])

    useEffect(() => {
        ShowLoader()
        try {
            GetDeliveryAddresses()
                .then(response => response.json())
                .then(json => {
                    setUserData({
                        companyname: json.data.matchcode || '',
                        street: json.data.street_1 || '',
                        postalcode: json.data.postal_code || '',
                        country: (json.data.company && json.data.company.data && json.data.company.data.CountryID) || '',
                        city: json.data.city,
                    })
                })
        } catch (error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    }, [])

    const myRef = useRef(null)
    const ProceedToPayment = () => {
        setStepTwo(true)
        setStep('two')
        document.querySelector('.checkout_accorderon__addresses').classList.remove('active')
        document.querySelector('.checkout_accorderon__delivery_method').classList.remove('active')
        myRef.current.scrollIntoView()
    }
    const ChangePayment = e => {
        setPaymentMethod(e.target.dataset.value)
        dispatch(changePaymentMethod(e.target.dataset.value))
    }

    /* start DeliveryMethods */
    const formatDate = (date) => {
        return new Date(date.split('/').reverse().join('/'))
    }

    function getNextDay() {
        const nextDate = new Date();
        nextDate.setDate(nextDate.getDate() + 1);
        const month = `${nextDate.getMonth() + 1}`
        return `${nextDate.getDate()}/${month.length < 2 ? '0' + month : month}/${nextDate.getFullYear()}`
    }

    const warehouseAddress = localStorage.getItem('warehouse.address') ? localStorage.getItem('warehouse.address') : ''
    const estimatedDelivery = getNextDay()
    const [startDate, setStartDate] = useState(warehouseAddress ? formatDate(estimatedDelivery) : new Date())
    const [startDateWarehouse, setStartDateWarehouse] = useState(warehouseAddress ? formatDate(estimatedDelivery) : new Date())
    /* end DeliveryMethods */

    const AdditionalNotesChange = e => {
        dispatch(changeAdditionalNotes(e.target.value))
    }

    useEffect(() => {
        dispatch(changePaymentMethod(localStorage.getItem('payment.id')))
        dispatch(changeDeliveryMethod('1'))
        dispatch(changeWarehousePickup(localStorage.getItem('warehouse.id.real')))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/terms-and-conditions`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                json.data && setTermsOfSaleText(json.data[0].body)
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }, [])

    const getPaymentTranslations = (paymentObject) => {
        return JSON.parse(paymentObject)[LangFix(localStorage.getItem('locale'))] ? JSON.parse(paymentObject)[LangFix(localStorage.getItem('locale'))] : ''
    }

    return (
        <>
            <div className="breadcrumbs_on_2_colummns">
                <Breadcrumbs itemlist={[{ 'url': '/myshoppingcart/', 'title': t('niki.b2b.cart.shoppingcart') }, { 'url': '/checkout/', 'title': t('niki.b2b.cartflow.deliverymethod') }]} />
                {step === 'one' &&
                    <ol className="cart_steps">
                        <li><a href="/myshoppingcart/">{t('niki.b2b.cartflow.cart')}</a></li>
                        <li className="current">{t('niki.b2b.cartflow.delivery')}</li>
                        <li>{t('niki.b2b.feedback.confirmation')}</li>
                    </ol>
                }
                {step === 'two' &&
                    <ol className="cart_steps">
                        <li><a href="/myshoppingcart/">{t('niki.b2b.cartflow.cart')}</a></li>
                        <li>{t('niki.b2b.cartflow.delivery')}</li>
                        <li className="current">{t('niki.b2b.feedback.confirmation')}</li>
                    </ol>
                }
            </div>
            <div className="wrapper">
                <h1>{cartName ? `${cartIDReal} ${cartName}` : `${t('niki.b2b.cart.shoppingcart')} ${cartIDReal}`}</h1>
                <div className="checkout_columns">
                    <div className="checkout_columns__content">
                        <div className="checkout_accorderon checkout_accorderon__addresses active">
                            <div className="checkout_accorderon__title" onClick={AccordeonClick}>{t('niki.b2b.cartflow.addresses')}</div>
                            <div className={['checkout_accorderon__content', 'checkout_accorderon__content_delivery', deliveryMethod].join(' ')}>
                                <div className="checkout_accorderon__content__inner">
                                    <div className="checkout_accorderon__content__title">{t('niki.b2b.deliveryaddresses.billingaddress')}</div>
                                    <hr />
                                    <dl className="checkout_accorderon__content__unit">
                                        <dt>{t('niki.b2b.deliveryaddresses.companyname')}:</dt>
                                        <dd>{userData.companyname}</dd>
                                    </dl>
                                    {localStorage.getItem('local.tax.number')
                                        &&
                                        <dl className="checkout_accorderon__content__unit">
                                            <dt>{t('niki.b2b.deliveryaddresses.vatid')}:</dt>
                                            <dd>{localStorage.getItem('local.tax.number')}</dd>
                                        </dl>
                                    }
                                    <dl className="checkout_accorderon__content__unit">
                                        <dt>{t('niki.b2b.deliveryaddresses.billingaddress')}:</dt>
                                        <dd>{userData.street}<br />
                                            {userData.city}<br />
                                            {userData.postalcode}<br />
                                            {userData.country}</dd>
                                    </dl>
                                    {/* <a href="/edit-business-address/" className="btn">{t('niki.b2b.cartflow.editbusinessaddress')}</a> */}
                                </div>
                                <div className="checkout_accorderon__content__inner">
                                    <div className="checkout_accorderon__content__title">{t('niki.b2b.cartflow.deliveryaddress')}</div>
                                    <hr />
                                    <dl className="checkout_accorderon__content__unit">
                                        <dt>{t('niki.b2b.deliveryaddresses.companyname')}:</dt>
                                        <dd>{userData.companyname}</dd>
                                    </dl>
                                    <dl className="checkout_accorderon__content__unit">
                                        <dt>{t('niki.b2b.deliveryaddresses.billingaddress')}:</dt>
                                        <dd>{userData.street}<br />
                                            {userData.city}<br />
                                            {userData.postalcode}<br />
                                            {userData.country}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <DeliveryMethods deliveryMethod={deliveryMethod} RadioClick={RadioClick} AccordeonClick={AccordeonClick} startDate={startDate}
                            setStartDate={setStartDate} startDateWarehouse={startDateWarehouse} setStartDateWarehouse={setStartDateWarehouse} isStepTwo={isStepTwo}
                        />

                        <div ref={myRef}></div>

                        {step === 'two' &&
                            <>
                                <div className="checkout_accorderon active">
                                    <div className="checkout_accorderon__title" onClick={AccordeonClick}>{t('niki.b2b.cartflow.paymentmethod')}</div>
                                    <div className="checkout_accorderon__content">
                                        <div className="checkout_accorderon__content__delivery">
                                            {t('niki.b2b.cartflow.paymentmethodtext')}
                                            <ul className="payment_method">
                                                {/* <li><label><input type="radio" name="payment" data-value="1" onClick={ChangePayment} defaultChecked />{t('niki.b2b.cartflow.cashondelivery')}</label></li> */}
                                                <li>
                                                    <label>
                                                        <input type="radio" name="payment" data-value={localStorage.getItem('payment.id')} onClick={ChangePayment} defaultChecked />
                                                        {`${getPaymentTranslations(localStorage.getItem('payment_type_name_translations')) || localStorage.getItem('payment.name')} — 
                                                ${getPaymentTranslations(localStorage.getItem('payment_condition_matchcode_translations')) || localStorage.getItem('payment.condition')}`}
                                                    </label>
                                                </li>
                                                {/*<li><label><input type="radio" name="payment" data-value={t('niki.b2b.cartflow.wiretransfer')} onClick={ChangePayment} />{t('niki.b2b.cartflow.wiretransfer')}</label></li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="checkout_accorderon active">
                                    <div className="checkout_accorderon__title" onClick={AccordeonClick}>{t('niki.b2b.cartflow.additionalnotes')}</div>
                                    <div className="checkout_accorderon__content">
                                        <div className="checkout_accorderon__content__delivery">
                                            <div className="additional_notes__columns">
                                                <div>{t('niki.b2b.cartflow.ifyouhave')}.</div>
                                                <textarea className="form-control" placeholder={t('niki.b2b.productpage.additionalinfomation')} onChange={AdditionalNotesChange}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}

                    </div>
                    <div className="checkout_columns__aside">
                        <div className="checkout_columns__aside__unit">
                            <div className="checkout_columns__aside__unit__header">{t('niki.b2b.shoppingcart.summary')}</div>
                            {shoppingCartSummary.length > 0 && !isStepTwo
                                ? <>
                                    {shoppingCartSummary.map((s, i) => {
                                        return (
                                            <div className="shopping_cart_summary" key={i}>
                                                <div>
                                                    {t('niki.b2b.feedback.supplier')}: {s.supplierName.includes('Monolith') ? '' : t('niki.b2b.cart.partner')} {s.supplierName}
                                                </div>
                                                <div className="cart_aside__table">
                                                    <div>{t('niki.b2b.orders.totalskus')}</div>
                                                    <div>{s.count}</div>
                                                </div>
                                                <div className="cart_aside__table">
                                                    <div>{t('niki.b2b.cart.total')}</div>
                                                    <div>{s.total}</div>
                                                </div>
                                                <hr />
                                            </div>
                                        )
                                    })}
                                </>
                                : <div className="cart_aside__table">
                                    <div>{t('niki.b2b.orders.totalskus')}</div>
                                    <div>{cartNumProducts}</div>
                                </div>
                            }
                            {cartSumm !== cartSummOld && !isStepTwo &&
                                <>
                                    {shoppingCartSummary.length === 0 && <hr />}
                                    <div className="cart_aside__table red">
                                        <div>{t('niki.b2b.shoppingcart.totalsavings')}</div>
                                        <div>{PriceFormat(cartSummOld - cartSumm)}</div>
                                    </div>
                                </>
                            }
                            {(cartSumm !== cartSummOld || isStepTwo) && <hr />}
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalgros')}</div>
                                <div className="cart_aside__table__big_price">{PriceFormat(cartSummProducts + cartSummVat)}</div>
                            </div>
                            <NavLink to="/myshoppingcart/" className="btn btn-outline btn-block btn-modify-cart">{t('niki.b2b.cartflow.modifycart')} <ArrowLeft className="top0" /></NavLink>
                        </div>

                        <DeliveryPayment ProceedToPayment={ProceedToPayment} startDate={startDate} startDateWarehouse={startDateWarehouse} isAccountBlocked={isAccountBlocked}
                            deliveryMethod={deliveryMethod} step={step} paymentMethod={paymentMethod} isStepTwo={isStepTwo} setIsErrorOrderCreation={setIsErrorOrderCreation}
                        />
                    </div>
                </div>
            </div>
            {isOpenTermsSaleAlert && <AlertPopup text={termsOfSaleText} textAlignJustify={true} closeAlert={() => dispatch(setIsOpenTermsSaleAlert(false))} />}
            {isErrorOrderCreation && <AlertPopup text={t('niki.b2b.order_creation_error') + '.'} closeAlert={() => setIsErrorOrderCreation(false)} />}
        </>
    )
}