import { t } from 'i18next'
import { xCompanyID, companyName, xTenant } from './config'
import defaultImage from './assets/images/placeholder.jpg'

export const ShowLoader = () => document.querySelector('.loader').classList.add('active')
export const HideLoader = () => document.querySelector('.loader').classList.remove('active')
export const getProductsIdArray = (items) => items.map(item => item.id)

export const returnLangForFlag = (lang) => {
    return lang ? lang === 'en'
        ? 'gb' : lang
        : 'gb'
}
export const LangFix = e => {
    return e === 'gb' ? 'en' : e
}

export const FormatDate = localDate => {
    const fullDate = new Date(localDate)
    return `${String(fullDate.getDate()).padStart(2, '0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}
export const DateFormat = localDate => {
    if (localDate) return localDate.split(' ')[0].replaceAll('/', '.')
}

export const PriceFormat = (price, showCurrency = true, showDecimalPart = true) => {
    if (isNaN(price) || price === null || price === '') {
        return ''
    }
    let toReturn
    const thousandSeparator = localStorage.getItem('currency.thousand')
    const decimalSeparator = localStorage.getItem('currency.decimal')
    const priceStr = String(parseFloat(price).toFixed(2)).replace(',', '.')
    const parts = priceStr.split('.')
    let intPart = parts[0]
    const decimalPart = parts[1] || ''

    if (thousandSeparator && intPart.length > 3) {
        let newIntPart = ''
        for (let i = intPart.length - 1, j = 0; i >= 0; i--, j++) {
            if (j > 0 && j % 3 === 0) {
                newIntPart = thousandSeparator + newIntPart
            }
            newIntPart = intPart[i] + newIntPart
        }
        intPart = newIntPart
    }
    let formattedDecimalPart = ''
    if (showDecimalPart && decimalSeparator && decimalPart.length > 0) {
        formattedDecimalPart = decimalSeparator + decimalPart
    }
    toReturn = `${intPart}${formattedDecimalPart}`
    if (showCurrency) toReturn = `${localStorage.getItem('currency.left')}${toReturn}${localStorage.getItem('currency.right')}`
    return toReturn
}

export const CapacityString = (capacity, capacity_unit_code, price) => {
    switch (capacity_unit_code) {
        case 'ML':
            return `${PriceFormat(price / capacity * 1000)} / ${t('niki.b2b.lt')}`
        case 'CL':
            return `${PriceFormat(price / capacity * 100)} / ${t('niki.b2b.lt')}`
        case 'LT':
            return `${PriceFormat(price / capacity)} / ${t('niki.b2b.lt')}`
        case 'GM':
            return `${PriceFormat(price / capacity * 1000)} / ${t('niki.b2b.kg')}`
        case 'KG':
            return `${PriceFormat(price / capacity)} / ${t('niki.b2b.kg')}`
        default:
            return null
    }
}

export const GetMainImage = (attachments, attachment_id) => {
    let imgsrc = defaultImage
    if (attachments.data) {
        attachments.data.forEach(e => {
            if (e.id === attachment_id) imgsrc = e.url
        })
    }
    return imgsrc
}
export const GetMainImageCart = (attachments, attachment_id) => {
    let imgsrc = defaultImage
    if (attachments.data) {
        attachments.data.forEach(e => {
            if (e.id === attachment_id) imgsrc = e.awsFileUrl
        })
    }
    return imgsrc
}

export const GetDiscountPrice = (itemDiscounts, priceline_id) => {
    let toReturn = { show: false }
    if (itemDiscounts.data && itemDiscounts.data.length > 0 && itemDiscounts.data[0]) {
        toReturn.show = true
        toReturn.price = itemDiscounts.data[0].unit_price
        toReturn.gross = itemDiscounts.data[0].tax_included_price
        toReturn.vat = itemDiscounts.data[0].tax_included_price - itemDiscounts.data[0].unit_price
        toReturn.pvp = 0
    }
    return toReturn
}

export const GetTotalNet = data => {
    let toReturn = 0
    if (data && data.length > 0) {
        data.forEach(e => {
            toReturn += parseFloat(e.unit_price) * parseFloat(e.qty)
        })
    }
    return toReturn
}

export const VatByCartDetails = cartDetailsData => {
    let vatReturn = 0
    if (cartDetailsData && cartDetailsData.length > 0) {
        cartDetailsData.forEach(e => {
            if (e.tax_included_price && parseFloat(e.tax_included_price) > 0 && e.unit_price && parseFloat(e.unit_price) > 0 && e.qty && parseFloat(e.qty) > 0) {
                vatReturn += (parseFloat(e.tax_included_price) - parseFloat(e.unit_price)) * parseInt(e.qty)
            }
        })
    }
    return vatReturn
}
export const NetByCartDetails = cartDetailsData => {
    let netReturn = 0
    if (cartDetailsData && cartDetailsData.length > 0) {
        cartDetailsData.forEach(e => {
            if (e.unit_price && parseFloat(e.unit_price) > 0 && e.qty && parseFloat(e.qty) > 0) {
                netReturn += parseFloat(e.unit_price) * parseInt(e.qty)
            }
        })
    }
    return netReturn
}

export const TranslateJSON = json => {
    let newJson = {}
    for (var key in json) {
        newJson[key] = t(json[key])
    }
    return newJson
}

export const ArrayUniqe = array => [...new Set(array)]

export const RemoveHistoryLocalStorage = () => {
    for (var key in localStorage) {
        if (key.startsWith("history_")) {
            localStorage.removeItem(key)
        }
    }
}

export const GetHistoryData = productID => {
    if (productID === null || productID === '') {
        return false
    }
    let toReturn = []
    for (var key in localStorage) {
        if (key.startsWith(`history_${productID}_`)) {
            toReturn.push(JSON.parse(localStorage[key]))
        }
    }
    return toReturn
}

export const CalculateRating = data => {
    if (data && data.length > 0) {
        let count = 0
        let summ = 0
        data.forEach(e => {
            count++
            summ += parseInt(e.value)
        })
        return { count: count, rating: parseFloat((summ / count).toFixed(1)) }
    } else {
        return false
    }
}

export function removeNonPrintableCharacters(str) {
    return str.replace(/[^\x20-\x7E]/g, '');
}

export const GetGlobalBannerData = e => {
    const toReturn = []
    const images = {}
    const links = {}

    if (e.attachments?.data) {
        e.attachments.data.forEach(el => images[el.id] = el.url)
    }

    if (e.content_url === null && e.json && e.attachments?.data) {
        const jsonData = JSON.parse(removeNonPrintableCharacters(e.json))

        jsonData.images && jsonData.images.forEach(el => {
            for (let key in el) {
                links[key] = el[key]
            }
        })
    }

    // productsIdArray: e.items?.data?.length ? getProductsIdArray(e.items.data) : [],

    e.attachments && e.attachments.data && e.attachments.data.forEach(j => {
        j?.links && j.links.forEach(u => {
            if ((u.extension === "jpg" || u.extension === "jpeg" || u.extension === "png") && u.template === "original") {
                const tempObj = {
                    image: u.url,
                    link: links[j.id],
                    title: e.matchcode,
                    bannerId: j.id,
                }
                toReturn.push(tempObj)
            }
        })
    })

    return toReturn
}

export const GetSimpleBannerData = el => {
    const data = { simple: '', mime: '', webp: '' }
    if (el.mimetype === 'image/webp') {
        data.webp = el.url
    } else {
        data.simple = el.url
        data.mime = el.mimetype
    }
    return data
}

export const ResetCart = () => {
    localStorage.removeItem('cart.uuid')
    localStorage.removeItem('cart.id')
    localStorage.removeItem('cart.id.real')
}

export const FixCompanyName = str => {
    if (companyName === 'Monolith United Kingdom') {
        return str
    } else if (str === undefined) {
        return ' '
    } else {
        let toReturn = ''
        toReturn = str.replace('Monolith United Kingdom', companyName)
        toReturn = str.replace('MONOLITH (UK) LTDUK', companyName)
        toReturn = toReturn.replace('MONOLITH (UK)', companyName)
        toReturn = toReturn.replace('Monolith (UK)', companyName)
        //toReturn = toReturn.replace('Monolith UK',companyName)
        return toReturn
    }
}

export const FetchPreferencesPost = async (lang = LangFix(localStorage.getItem('locale'))) => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/api-module-preferences`
    const requestOptionsPost = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Accept-Language': lang,
            'Content-Type': 'application/json',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        },
        body: JSON.stringify({
            module_id: '999',
            user_id: localStorage.getItem('user.id'),
            component_id: 'b2b',
            preferences: `{"language":"${lang}"}`
        })
    }
    const response = await fetch(url, requestOptionsPost)
    const json = await response.json()
    return json
}

export const getStockStatus = (e) => {
    const available = e?.item?.data?.itemStocks?.data?.length > 0 ? e.item.data.itemStocks.data.filter(i => i.warehouse_id === localStorage.getItem('warehouse.id'))[0]?.available : null
    if (available > 20) {
        return 'instock'
    } else if (available > 0) {
        return 'low'
    } else {
        return 'outofstock'
    }
}

export const getTranslateStockStatus = (e) => {
    const available = e?.itemStocks?.data?.length > 0 ? e.itemStocks.data.filter(i => i.warehouse_id === localStorage.getItem('warehouse.id'))[0]?.available : null
    if (available > 20) {
        return t('niki.b2b.instock')
    } else if (available > 0) {
        return t('niki.b2b.low')
    } else {
        return t('niki.b2b.outofstock')
    }
}

export const translationsForSearchBox = () => {
    return { placeholder: t('niki.b2b.header.search_here'), submitTitle: t('niki.b2b.header.search') }
};

export const returnProductIcons = (type, value, el) => {
    // niki.b2b.extrafield.wine
    // niki.b2b.extrafield.beer
    if (type === 'boolean') {
        if (typeof value === 'boolean' ? value : value === 'true') {
            switch (el) {
                case 4:
                    return { label: 'niki.b2b.extrafield.frozen', icon: 'Frozen' };
                case 5:
                    return { label: 'niki.b2b.extrafield.alcohol', icon: 'Alcohol' };
                case 9:
                    return { label: 'niki.b2b.extrafield.chilled', icon: 'Chilled' };
                case 30:
                    return { label: 'niki.b2b.extrafield.ambient', icon: 'Ambient' };
                case 89:
                    return { label: 'niki.b2b.extrafield.hallal', icon: 'Hallal' };
                case 90:
                    return { label: 'niki.b2b.extrafield.kosher', icon: 'Kosher' };
                default:
                    return ''
            }
        }
    }
    return ''
}

export const returnItemExtrafields = (items, isValue = true) => {
    if (Array.isArray(items) && items.length > 0) {
        items.forEach(el => {
            const resFilter = !!el?.id?.value ? returnProductIcons(el?.type?.value, el?.value?.value, +el?.id?.value) : returnProductIcons(el?.type, el?.value, +el?.id)
            if (!!resFilter) return resFilter
        })
    } else {
        for (let key in items) {
            const item = items[key]
            const resFilter = !!item?.id?.value ? returnProductIcons(item?.type?.value, item?.value?.value, +item?.id?.value) : returnProductIcons(item?.type, item?.value, +item?.id)
            if (!!resFilter) return resFilter
        }
    }
}

export const getTranslatedName = (e) => {
    if (e?.itemDescriptions?.data) {
        return e.itemDescriptions.data.filter(el => el.locale === LangFix(localStorage.getItem('locale')))[0]?.name || ''
    }
}

export const returnAddressDelivery = (a) => {
    return {
        addressName: a.matchcode || '',
        street: a.street_1,
        city: a.city,
        postalCode: a.postal_code || '',
        country: a.country_name || '',
        addressType: a.address_type_id_real || 0,
        id: a.id || '',
        street_2: a.street_2 || '',
        street_number: a.street_number || '',
        country_id_real: a.country_id_real || 826,
        state_id_real: a.state_id_real || 157
    }
}

export const returnAddressForChange = (a) => {
    return {
        matchcode: a.addressName || '',
        country_id: a.country_id_real,
        state_id: a.state_id_real,
        street_1: a.street || '',
        street_number: a.street_number || '',
        street_2: a.street_2 || '',
        postal_code: a.postalCode || '',
        city: a.city,
    }
}

export const formatNumber = (number, decimalPlaces = 1) => {
    const roundedNumber = Math.round(number * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    const formattedNumber = roundedNumber.toFixed(decimalPlaces);

    return formattedNumber
}

export const storingCartData = (uuid, idReal, idHash) => {
    localStorage.setItem('cart.uuid', uuid)
    localStorage.setItem('cart.id.real', idReal)
    localStorage.setItem('cart.id', idHash)
}

export const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}

export const onBannerView = (banner, navigate) => {
    banner.link.includes('ids') ? navigate(`/all-products/?banner=${banner.bannerId}`) : navigate(banner.link)
    banner.link.includes('ids') && localStorage.setItem('banner.data', JSON.stringify({ productsIdArray: banner.link.slice(4).split(',') }))
}

export const checkValueOrNonInteger = (value, sale_unit_code) => {
    const regex = /^\d+(\.)?(\d{1,2})?$/
    const inputValue = sale_unit_code === 'KG' && typeof value === "string"
        ? !value
            ? ''
            : value.includes('.')
                ? value.slice(0, value.indexOf('.') + 3)
                : value
        : parseInt(value) || 0

    if (sale_unit_code === 'KG' && !regex.test(value)) {
        return ''
    } else {
        return inputValue
    }
}