import React, { useEffect, useState } from 'react'
import { ShowLoader, HideLoader } from '../../functions'
import { t } from "i18next"

import { getRequestOptionsNoAuth } from '../../helpers/apiHelper'

import '../../assets/css/style.css'
import '../../assets/css/loginform.css'

const FetchResendOTP = async (url,requestOptions) => {
    const response = await fetch(url,requestOptions)
    return response.ok
}

const maskEmail = email => {
    let maskedEmail = ''
    let emailParts = email.split('@')
    let username = emailParts[0]
    let domain = emailParts[1]
    var maskedUsername = ''
    var usernameLength = username.length
    if (usernameLength > 4) {
        var maskLength = Math.floor(usernameLength / 2)
        maskedUsername += username.substring(0, usernameLength - maskLength).replace(/./g, '*')
        maskedUsername += username.substring(usernameLength - maskLength, usernameLength)
    } else {
        maskedUsername = username.replace(/./g, '*')
    }
    maskedEmail = `${maskedUsername}@${domain}`
    return maskedEmail
}

const maskPhone = phoneNumber => {
    if (phoneNumber && phoneNumber.length > 0) {
        const masked = phoneNumber.slice(0, -2).replace(/\d/g, '*')
        const visible = phoneNumber.slice(-2)
        return `${masked}${visible}`
    } else {
        return phoneNumber
    }
}

const urlEmail = `${process.env.REACT_APP_API_DOMAIN}/login/email-api`
const urlPhone = `${process.env.REACT_APP_API_DOMAIN}/clients/web/phone`

export const CodeLifeTimer = ({ from, setWrongCode, status }) => {

    const [seconds, setSeconds] = useState(299)
    
    useEffect(()=>{
        if (status) {
            const interval = setInterval(() => {
                setSeconds(seconds => seconds - 1)
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [status, seconds])
    
    const ResendOTP = () => {
        const url = from === 'email' ? urlEmail : urlPhone

        const requestOptions = getRequestOptionsNoAuth('POST', null, 'application/json')
        requestOptions.body = JSON.stringify(
            from === 'email'
                ? { email: localStorage.getItem('account.user.email') }
                : { phone: localStorage.getItem('account.user.phone') }
        )
    
        ShowLoader()
        FetchResendOTP(url,requestOptions).then(response => {
            if (response) {
                document.querySelector(`.six_digits__single:first-child`).focus()
                setSeconds(299)
                setWrongCode(false)
            }
        })
        .finally(() => HideLoader())
    }

    return (
        <>
            {seconds > 0
                ? <div className="alert alert_resolved">
                    {t('niki.b2b.login.wesentcode')} {from === 'email' ? maskEmail(localStorage.getItem('account.user.email')) : maskPhone(localStorage.getItem('account.user.phone'))}. {t('niki.b2b.login.pleaseenter')} {seconds / 60 > 1 ? Math.floor(seconds / 60) + ':' : ''}
                        {seconds > 60
                            ? (seconds - Math.floor(seconds / 60) * 60) > 9
                                ? seconds - Math.floor(seconds / 60) * 60
                            : `0${seconds - Math.floor(seconds / 60) * 60}`
                        : seconds === 60 ? '1:00' : seconds
                    } {t('niki.b2b.login.seconds')}...
                </div>
                : <div className="resend_otp marginbottom20"><button className="btn btn-primary" type="button" onClick={ResendOTP}>{t('niki.b2b.login.resendotp')}</button></div>
            }
        </>
    )
}
