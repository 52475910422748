import { useEffect, useRef, useState, useMemo } from "react"
import { useDispatch } from 'react-redux'
import { useParams, NavLink } from 'react-router-dom'
import { t } from "i18next"
import { priceLineID, companySupplier } from "../config"
import { PriceFormat, GetMainImageCart, TranslateJSON, GetHistoryData, FormatDate, getTranslateStockStatus, getStockStatus, getTranslatedName, ShowLoader, HideLoader, storingCartData } from "../functions"
import { getRequestOptions } from "../helpers/apiHelper"
import { setCartHeaders, clearCartContent } from '../reduxToolkit/cartSlice'
import { setIsMore5CartsAlert } from '../reduxToolkit/universalSlice'

import { Breadcrumbs } from "../breadcrumbs"
import { TablePreloader } from "../components/TablePreloader"
import { ProdHistoryPopup } from "../components/ProdHistoryPopup"
import { ModalDeleteCart } from "../components/ModalDeleteCart"
import { PopupWithInput } from "../components/PopupWithInput"

import { ReactComponent as MoreVertical } from './../assets/svg/morevertical.svg'
import { ReactComponent as Refresh } from './../assets/svg/refresh.svg'
import { ReactComponent as ProdHistoryIcon } from './../assets/svg/prodhistory.svg'

import "./../assets/css/cart.css"

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

import { AG_GRID_LOCALE } from "../aggridtranslations/locale.full"

import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey("CompanyName=SmartDigit, Lda,LicensedApplication=NIKI24,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-030570,SupportServicesEnd=27_August_2023_[v2]_MTY5MzA5MDgwMDAwMA==197101bde71f7a31a7dac0b3d3f0a6c8")

const GetDiscountPrice = data => {
    let toReturn = 0
    /*
    if (data && data.length > 0) {
        data.forEach(e => {
            if (e.priceline_id === priceline_id) toReturn = e.tax_included_price
        })
    }
    */
    if (data && data.length > 0 && data[0]) toReturn = data[0].tax_included_price
    return toReturn
}

const ShoppingCartContent = props => {
    
    const dispatch = useDispatch()

    const gridRef = useRef(null)
    const moreMenuEl = useRef(null)
    const moreMenuOverlayEl = useRef(null)
    const cartRenameWrapperEl = useRef(null)
    const cartPopupEl = useRef(null)
    const refEnterCartName = useRef(null)

    const uuid = props.uuid
    
    const [rowData, setRowData] = useState([])
    const [cartNumProducts, setCartNumProducts] = useState(0)
    const [orderIDReal, setOrderIDReal] = useState('')
    const [createdAt, setCreatedAt] = useState('')
    const [dataLoaded, setDataLoaded] = useState(false)
    const [cartID, setCartID] = useState('')
    const [cartSumm, setCartSumm] = useState(0)
    const [cartSummVat, setCartSummVat] = useState(0)
    const [cartSummOld, setCartSummOld] = useState(0)
    const [cartName, setCartName] = useState('')
    const [newCartName, setNewCartName] = useState('')

    // const groupRowRendererParams = useMemo(() => {
    //     return {
    //       innerRenderer: CustomGroupRow,
    //       suppressCount: true,
    //     };
    //   }, []);

    /* HistoryProduct */
    const [openHistoryProduct, setOpenHistoryProduct] = useState(false)
    const [idHistoryProduct, setIdHistoryProduct] = useState('')
    const [productData, setProductData] = useState({})

    useEffect(() => {
        if (openHistoryProduct) {
            const PopupSelector = `.product_history__wrapper_${idHistoryProduct}`
            document.querySelector(PopupSelector).classList.add('active')
        }      
    }, [openHistoryProduct, idHistoryProduct])

    const ShowProdHistory = e => {
        setOpenHistoryProduct(true)
        setIdHistoryProduct(e.target.dataset.id)
        setProductData(JSON.parse(e.target.dataset.data))
    }
    /* HistoryProduct */

    const columnDefsWork = [
        {
            headerName: '',
            field: 'image',
            width: 90,
            floatingFilter: false,
            pinned: 'left',
            cellRenderer: params => <NavLink to={`/productpage/${params.data.product_id}/`} onClick={() => localStorage.setItem('url.back',`/shoppingcart/${uuid}/`)} className="grid_prod_img"><img src={params.data.image} alt={params.data.name} /></NavLink>
        },
        {
            headerName: 'ID',
            width: 120,
            field: 'item_id',
            pinned: 'left',
        },
        {
            field: 'name',
            headerName: t('niki.b2b.cartflow.product'),
            filter: 'agTextColumnFilter',
            width: 300,
            autoHeight: true,
            cellRenderer: params => {
                const prodHistory = GetHistoryData(params.data.product_id)
                return (
                    <>
                        <strong className="ag_prodname">{params.data.name} {params.data.item_id}</strong>
                        {prodHistory.length > 0
                        &&
                        <>
                            <button
                                className="ag_prodhistory"
                                onClick={ShowProdHistory}
                                data-id={params.data.item_id}
                                data-data={JSON.stringify(params.data)}
                            ><Refresh className="ag_prodhistory__refresh" />{prodHistory.filter((_, index) => index === 0).map((e,i) =>
                                <span key={`historyel${params.data.id}${i}`}>{FormatDate(e.created_at)} &mdash; {e.quantity} {t('niki.b2b.returnproducts.units')}</span>
                            )}<ProdHistoryIcon className="ag_prodhistory__list" /></button>
                        </>
                        }
                    </>
                )
            }
        },
        {
            field: 'group',
            headerName: t('niki.b2b.shoppingcart.group'),
            width: 100,
            enableRowGroup: true,
            filter: false,
        },
        {
            field: 'type',
            headerName: t('niki.b2b.feedback.type'),
            width: 100,
            enableRowGroup: true,
            filter: false,
        },
        {
            field: 'qty',
            headerName: t('niki.b2b.feedback.qty'),
            width: 90,
            filter: false,
        },
        {
            field: 'supplier',
            headerName: t('niki.b2b.feedback.supplier'),
            enableRowGroup: true,
        },
        {
            field: 'translateStock',
            headerName: t('niki.b2b.feedback.status'),
            width: 90,
            filter: false,
            enableRowGroup: true,
            cellRenderer: params => {
                return <div className="aggrid_status__wrapper"><div className={['aggrid_status', params.data.instock].join(' ')}></div></div>
            }
        },
        {
            field: '',
            headerName: `${t('niki.b2b.allproducts.discounts')}.`,
            width: 100,
            filter: false,
            cellRenderer: params => {
                const priceOld = parseFloat(params.data.priceOld)
                const price = parseFloat(params.data.price)
                if (priceOld > 0) {
                    return <div className="aggrid_discount">-{Math.round((priceOld-price)/priceOld*100)}%</div>
                } else {
                    return null
                }
            }
        },
        {
            field: 'price',
            headerName: t('niki.b2b.cart.unipr'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params =>  `${PriceFormat(params.value)}`
        },
        {
            field: 'total',
            headerName: t('niki.b2b.cart.total'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => {
                return `${PriceFormat(params.value)}`
            }
        },
        {
            field: 'vat',
            headerName: t('niki.b2b.product.vat'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => params.data.vat > 0 ? `${params.value}%` : null
        },
    ]

    useEffect(()=>{
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-header/${uuid}/${localStorage.getItem('account.entity.id')}?realKey=1`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data.id_real && setOrderIDReal(json.data.id_real)
            json.data.readable_created_at && setCreatedAt(json.data.readable_created_at)
            json.data.id && setCartID(json.data.id)
            json.data.matchcode && setCartName(json.data.matchcode)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    },[uuid])

    const gridStyles = {
        color: 'var(--darkgrey)',
        fontSize: '16px'
    }
    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        }
    }, [])

    useEffect(()=>{
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details?limit=1000&cart_header_uuid=${uuid}&include=item,item.itemCategories,item.itemDescriptions,item.attachments,item.itemPrices,item.itemDiscounts,item.itemKinds,item.itemFamily,item.itemStocks&warehouse_id=${localStorage.getItem('warehouse.id')}&showVat=true&source_price_id[]=${priceLineID}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            setRowData([])
            let totalSKUsCart = 0
            let totalSummCart = 0
            let totalSummCartOld = 0
            let totalSummCartVat = 0
            json.data.forEach(e => {
                const prodQty = (e.qty && parseFloat(e.qty)) || 0
                let price = (e.item && e.item.data && e.item.data.itemPrices && e.item.data.itemPrices.data && e.item.data.itemPrices.data[0] && e.item.data.itemPrices.data[0].unit_price && parseFloat(e.item.data.itemPrices.data[0].unit_price)) || 0
                let prodGross = (e.item && e.item.data && e.item.data.itemPrices && e.item.data.itemPrices.data && e.item.data.itemPrices.data[0] && e.item.data.itemPrices.data[0].tax_included_price && parseFloat(e.item.data.itemPrices.data[0].tax_included_price)) || 0
                let priceOld = 0
                if (e.item && e.item.data && e.item.data.itemDiscounts && e.item.data.itemDiscounts.data && e.item.data.itemDiscounts.data[0] && e.item.data.itemDiscounts.data[0].unit_price) {
                    priceOld = price
                    price = parseFloat(e.item.data.itemDiscounts.data[0].unit_price)
                    prodGross = parseFloat(e.item.data.itemDiscounts.data[0].tax_included_price)
                }
               
                const prodVat = prodGross - price
                totalSKUsCart++
                totalSummCart += price*prodQty
                totalSummCartOld += (priceOld > 0 ? priceOld : price)*prodQty
                totalSummCartVat += prodQty*prodVat
                const tempObj = {
                    id: e.id,
                    product_id: e.item.data.id,
                    item_id: e.item.data.item_id,
                    name: getTranslatedName(e.item.data) ? getTranslatedName(e.item.data) : e.matchcode,
                    qty: e.qty,
                    qty_in_box: e.qty_in_box,
                    price: price,
                    priceOld: priceOld,
                    total: parseFloat(e.qty*price).toFixed(2),
                    price_discount: GetDiscountPrice(e.item.data.itemDiscounts.data) || 0,
                    group: (e.item.data.itemFamily && e.item.data.itemFamily.data && e.item.data.itemFamily.data.description) || '',
                    type: (e.item.data.itemKinds && e.item.data.itemKinds.data && e.item.data.itemKinds.data[0] && e.item.data.itemKinds.data[0].name) || '',
                    image: GetMainImageCart(e.item.data.attachments,e.item.data.attachment_id),
                    translateStock: getTranslateStockStatus(e?.item?.data),
                    instock: getStockStatus(e),
                    vat: Math.round(prodVat / price * 100),
                    supplier: companySupplier,
                    UNIcode: e.UNI,
                }
                setRowData(prevState => ([...prevState, tempObj]))
            })
            setCartNumProducts(totalSKUsCart)
            setCartSumm(totalSummCart)
            setCartSummOld(totalSummCartOld)
            setCartSummVat(totalSummCartVat)
            setDataLoaded(true)
        })
        .catch(error => console.error(error))
    },[uuid])

    // const [gridApi, setGridApi] = useState()
    // const onGridReady = useCallback(params => {
    //     const { api, columnApi } = params
    //     setGridApi({ api, columnApi })
    // })

    const localeText = useMemo(() => {
        return TranslateJSON(AG_GRID_LOCALE)
    }, [])

    const ShowMoreMenu = () => {
        moreMenuEl.current.classList.add('active')
        moreMenuOverlayEl.current.classList.add('active')
    }
    const CloseMoreMenu = () =>{
        moreMenuEl.current.classList.remove('active')
        moreMenuOverlayEl.current.classList.remove('active')
    }
    const ShowRenamePopup = () => {
        CloseMoreMenu()
        cartRenameWrapperEl.current.classList.add('active')
    }
    const CloseRenamePopup = () => {
        cartRenameWrapperEl.current.classList.remove('active')
    }
    const dispatchCartHeaders = (cartH) => {
        dispatch(setCartHeaders({
            id: cartH.id,
            idreal: cartH.real_id,
            name: cartH.matchcode || ''
        }))
    }

    const CreateNewCart = (e) => {
        e.preventDefault()
        const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
        requestOptionsCart.body = JSON.stringify({
            company_id: localStorage.getItem('account.company.hashed'),
            customer_account_id: localStorage.getItem('account.entity.id'),
            status_id: 'J8RvMwowm7k2pNOr',
            realKey: '1',
            matchcode: newCartName,
        })

        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-headers`,requestOptionsCart)
        .then(response => response.json())
            .then(json => {
                if (json.message && json.errors) {
                    closePopupEnterCartName()
                    dispatch(setIsMore5CartsAlert(true))
                }
                if (json.data) {
                    dispatch(clearCartContent())
                    // setCartID(json.data.id)
                    setCartName(newCartName)
                    setOrderIDReal(json.data.id_real)
                    setCreatedAt(json.data.readable_created_at)
                    setRowData([])
                    localStorage.setItem('create_new_cart', JSON.stringify(json.data))
                    storingCartData(json.data.uuid, json.data.real_id, json.data.id)
                    localStorage.setItem('cart_data',`${json.data.uuid},${json.data.real_id},${json.data.id}`)
                    dispatchCartHeaders(json.data)
                    closePopupEnterCartName()
                    setNewCartName('')
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }
    const DeleteCart = () => cartPopupEl.current.classList.add('active')
    const ClosePopup = () => cartPopupEl.current.classList.remove('active')
    const ConfirmDelete = (deleteID) => {
        window.location.href = '/shoppingcarts/'
    }

    const [newName,setNewName] = useState('')
    const SubmitNewName = e => {
        e.preventDefault()
        const requestOptionsPatchMatchcode = getRequestOptions('PATCH', null, 'application/json')
        requestOptionsPatchMatchcode.body = JSON.stringify({ matchcode: newName })
    
        try {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-header/${uuid}/${localStorage.getItem('account.entity.id')}`,requestOptionsPatchMatchcode)
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    dispatchCartHeaders(json.data)
                    setCartName(json.data.matchcode)
                    CloseRenamePopup()
                }
            })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    }

    const OpenCart = () => {
        const cart = localStorage.getItem('cart_data').split(',')
        storingCartData(cart[0], cart[1], cart[2])
        window.location.href = '/myshoppingcart/'
    }

    const openPopupEnterCartName = () => {
        refEnterCartName.current.classList.add('active')
        CloseMoreMenu()
    }
    const closePopupEnterCartName = () => refEnterCartName.current.classList.remove('active')

    return (
        <>
            <Breadcrumbs itemlist={[
                {'url':'/account/','title':t('niki.b2b.menu.myaccount')},
                {'url':'/shoppingcarts/','title':t('niki.b2b.cart.shoppingcarts')},
                {'url':`/shoppingcart/${uuid}/`,'title':`${t('niki.b2b.cart.shoppingcart')} ${orderIDReal}`}
            ]} />
            <div className="wrapper">
                <h1>
                    {cartName ? `${orderIDReal} — ${cartName}` : `${t('niki.b2b.cart.shoppingcart')} ${orderIDReal}`}
                    <span className="more_button">
                        <button onClick={ShowMoreMenu}><MoreVertical /></button>
                        <div className="more_menu" ref={moreMenuEl}>
                            <button onClick={openPopupEnterCartName}>{t('niki.b2b.cartflow.createnewcart')}</button>
                            <button onClick={ShowRenamePopup}>{t('niki.b2b.cartflow.rename')}</button>
                            <button onClick={DeleteCart}>{t('niki.b2b.shoppingcart.deletecart')}</button>
                        </div>
                    </span>
                </h1>
                <div className="myshoppingcart__created">{t('niki.b2b.cart.created')}: <span>{createdAt}</span></div>

                <div className="cart_columns">
                    
                    <div className="cart_content">
                        {openHistoryProduct &&
                            <ProdHistoryPopup
                                matchcode={productData.name}
                                unit_code={productData.UNIcode}
                                qty_in_box={productData.qty_in_box}
                                item_id={productData.item_id}
                                imgsrc={productData.image}
                                setOpenHistoryProduct={setOpenHistoryProduct}
                                product_id={productData.product_id}
                            />
                        }

                        {dataLoaded
                            ? <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefsWork}
                                    defaultColDef={defaultColDef}
                                    // onGridReady={onGridReady}
                                    rowHeight="76"
                                    rowStyle={gridStyles}
                                    rowGroup={'true'}
                                    animateRows={true}
                                    singleClickEdit={true}
                                    groupDisplayType={'groupRows'}
                                    groupRowRenderer={'agGroupCellRenderer'}
                                    rowGroupPanelShow={'always'}
                                    localeText={localeText}
                                    suppressDragLeaveHidesColumns={true}
                                    // groupRowRendererParams={groupRowRendererParams}
                                >
                                </AgGridReact>
                            </div>
                            : <TablePreloader />
                        }
                    </div>
                    
                    <div className="cart_aside">
                        <div className="cart_aside__inner">
                            <div className="cart_aside__header">{t('niki.b2b.shoppingcart.summary')}</div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalskus')}</div>
                                <div>{cartNumProducts}</div>
                            </div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalnet')}</div>
                                <div>{PriceFormat(cartSumm)}</div>
                            </div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalvat')}</div>
                                <div>{PriceFormat(cartSummVat)}</div>
                            </div>
                            {cartSumm !== cartSummOld
                            &&
                            <>
                                <hr />
                                <div className="cart_aside__table red">
                                    <div>{t('niki.b2b.shoppingcart.totalsavings')}</div>
                                    <div>{PriceFormat(cartSummOld-cartSumm)}</div>
                                </div>
                            </>
                            }
                            <hr />
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalgros')}</div>
                                <div className="cart_aside__table__big_price">{PriceFormat(cartSumm+cartSummVat)}</div>
                            </div>
                            <button className="btn btn-block btn-primary checkout_now" onClick={OpenCart}>{t('niki.b2b.edit_this_cart')}</button>
                        </div>
                    </div>
                
                </div>
            </div>
            <div className="more_menu__overlay" onClick={CloseMoreMenu} ref={moreMenuOverlayEl}></div>
            <PopupWithInput refWrapper={cartRenameWrapperEl} onSubmit={SubmitNewName} setNewValue={setNewName}
                close={CloseRenamePopup} title='niki.b2b.cartflow.rename' titleSubmitButton='niki.b2b.cartflow.renamecart'
            />
            <PopupWithInput refWrapper={refEnterCartName} onSubmit={CreateNewCart} setNewValue={setNewCartName}
                close={closePopupEnterCartName} title='niki.b2b.cart.enter_cart_name' titleSubmitButton='niki.b2b.cart.create'
            />
            <ModalDeleteCart refEl={cartPopupEl} ClosePopup={ClosePopup} cartIDReal={orderIDReal} skus={rowData.length} gros={cartSumm+cartSummVat}
                cartID={cartID} ConfirmDelete={ConfirmDelete}
            />
        </>
    )
}

export const ShoppingCart = () => {
    const { id } = useParams()
    return (
        <ShoppingCartContent uuid={id} />
    )
}