export const AG_GRID_LOCALE = {
    // Set Filter
    selectAll: 'niki.grid.select_all',
    selectAllSearchResults: 'niki.grid.select_all_search_results',
    searchOoo: 'niki.grid.search_ooo',
    blanks: 'niki.grid.blanks',
    noMatches: 'niki.grid.no_matches',
    
    // Number Filter & Text Filter
    filterOoo: 'niki.grid.filter_ooo',
    equals: 'niki.grid.equals',
    notEqual: 'niki.grid.not_equal',
    empty: 'niki.grid.empty',
    
    // Number Filter
    lessThan: 'niki.grid.less_than',
    greaterThan: 'niki.grid.greater_than',
    lessThanOrEqual: 'niki.grid.less_than_or_equal',
    greaterThanOrEqual: 'niki.grid.greater_than_or_equal',
    inRange: 'niki.grid.in_range',
    inRangeStart: 'niki.grid.in_range_start',
    inRangeEnd: 'niki.grid.in_range_end',
    
    // Text Filter
    contains: 'niki.grid.contains',
    notContains: 'niki.grid.not_contains',
    startsWith: 'niki.grid.starts_with',
    endsWith: 'niki.grid.ends_with',
    
    // Date Filter
    dateFormatOoo: 'niki.grid.date_format_ooo',
    
    // Filter Conditions
    andCondition: 'niki.grid.and_condition',
    orCondition: 'niki.grid.or_condition',
    
    // Filter Buttons
    applyFilter: 'niki.grid.apply_filter',
    resetFilter: 'niki.grid.reset_filter',
    clearFilter: 'niki.grid.clear_filter',
    cancelFilter: 'niki.grid.cancel_filter',
    
    // Filter Titles
    textFilter: 'niki.grid.text_filter',
    numberFilter: 'niki.grid.number_filter',
    dateFilter: 'niki.grid.date_filter',
    setFilter: 'niki.grid.set_filter',
    
    // Side Bar
    columns: 'niki.grid.columns',
    filters: 'niki.grid.filters',
    
    // columns tool panel
    pivotMode: 'niki.grid.pivot_mode',
    groups: 'niki.grid.groups',
    rowGroupColumnsEmptyMessage: 'niki.grid.row_group_columns_empty_message',
    values: 'niki.grid.values',
    valueColumnsEmptyMessage: 'niki.grid.value_columns_empty_message',
    pivots: 'niki.grid.pivots',
    pivotColumnsEmptyMessage: 'niki.grid.pivot_columns_empty_message',
    
    // Header of the Default Group Column
    group: 'niki.grid.group',
    
    // Other
    loadingOoo: 'niki.grid.loading_ooo',
    noRowsToShow: 'niki.grid.no_rows_to_show',
    enabled: 'niki.grid.enabled',
    
    // Menu
    pinColumn: 'niki.grid.pin_column',
    pinLeft: 'niki.grid.pin_left',
    pinRight: 'niki.grid.pin_right',
    noPin: 'niki.grid.no_pin',
    valueAggregation: 'niki.grid.value_aggregation',
    autosizeThiscolumn: 'niki.grid.autosize_thiscolumn',
    autosizeAllColumns: 'niki.grid.autosize_all_columns',
    groupBy: 'niki.grid.group_by',
    ungroupBy: 'niki.grid.ungroup_by',
    resetColumns: 'niki.grid.reset_columns',
    expandAll: 'niki.grid.expand_all',
    collapseAll: 'niki.grid.collapse_all',
    copy: 'niki.grid.copy',
    ctrlC: 'niki.grid.ctrl_c',
    copyWithHeaders: 'niki.grid.copy_with_headers',
    paste: 'niki.grid.paste',
    ctrlV: 'niki.grid.ctrl_v',
    export: 'niki.grid.export',
    csvExport: 'niki.grid.csv_export',
    excelExport: 'niki.grid.excel_export',
    
    // Enterprise Menu Aggregation and Status Bar
    sum: 'niki.grid.sum',
    min: 'niki.grid.min',
    max: 'niki.grid.max',
    none: 'niki.grid.none',
    count: 'niki.grid.count',
    avg: 'niki.grid.avg',
    filteredRows: 'niki.grid.filtered_rows',
    selectedRows: 'niki.grid.selected_rows',
    totalRows: 'niki.grid.total_rows',
    totalAndFilteredRows: 'niki.grid.total_and_filtered_rows',
    more: 'niki.grid.more',
    to: 'niki.grid.to',
    of: 'niki.grid.of',
    page: 'niki.grid.page',
    nextPage: 'niki.grid.next_page',
    lastPage: 'niki.grid.last_page',
    firstPage: 'niki.grid.first_page',
    previousPage: 'niki.grid.previous_page',
    
    // Pivoting
    pivotColumnGroupTotals: 'niki.grid.pivot_column_group_totals',
    
    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'niki.grid.pivot_chart_and_pivot_mode',
    pivotChart: 'niki.grid.pivot_chart',
    chartRange: 'niki.grid.chart_range',
    
    columnChart: 'niki.grid.column_chart',
    groupedColumn: 'niki.grid.grouped_column',
    stackedColumn: 'niki.grid.stacked_column',
    normalizedColumn: 'niki.grid.normalized_column',
    
    barChart: 'niki.grid.bar_chart',
    groupedBar: 'niki.grid.grouped_bar',
    stackedBar: 'niki.grid.stacked_bar',
    normalizedBar: 'niki.grid.normalized_bar',
    
    pieChart: 'niki.grid.pie_chart',
    pie: 'niki.grid.pie',
    doughnut: 'niki.grid.doughnut',
    
    line: 'niki.grid.line',
    
    xyChart: 'niki.grid.xy_chart',
    scatter: 'niki.grid.scatter',
    bubble: 'niki.grid.bubble',
    
    areaChart: 'niki.grid.area_chart',
    area: 'niki.grid.area',
    stackedArea: 'niki.grid.stacked_area',
    normalizedArea: 'niki.grid.normalized_area',
    
    histogramChart: 'niki.grid.histogram_chart',
    
    // Charts
    pivotChartTitle: 'niki.grid.pivot_chart_title',
    rangeChartTitle: 'niki.grid.range_chart_title',
    settings: 'niki.grid.settings',
    data: 'niki.grid.data',
    format: 'niki.grid.format',
    categories: 'niki.grid.categories',
    defaultCategory: 'niki.grid.default_category',
    series: 'niki.grid.series',
    xyValues: 'niki.grid.xy_values',
    paired: 'niki.grid.paired',
    axis: 'niki.grid.axis',
    navigator: 'niki.grid.navigator',
    color: 'niki.grid.color',
    thickness: 'niki.grid.thickness',
    xType: 'niki.grid.x_type',
    automatic: 'niki.grid.automatic',
    category: 'niki.grid.category',
    number: 'niki.grid.number',
    time: 'niki.grid.time',
    xRotation: 'niki.grid.x_rotation',
    yRotation: 'niki.grid.y_rotation',
    ticks: 'niki.grid.ticks',
    width: 'niki.grid.width',
    height: 'niki.grid.height',
    length: 'niki.grid.length',
    padding: 'niki.grid.padding',
    spacing: 'niki.grid.spacing',
    chart: 'niki.grid.chart',
    title: 'niki.grid.title',
    titlePlaceholder: 'niki.grid.title_placeholder',
    background: 'niki.grid.background',
    font: 'niki.grid.font',
    top: 'niki.grid.top',
    right: 'niki.grid.right',
    bottom: 'niki.grid.bottom',
    left: 'niki.grid.left',
    labels: 'niki.grid.labels',
    size: 'niki.grid.size',
    minSize: 'niki.grid.min_size',
    maxSize: 'niki.grid.max_size',
    legend: 'niki.grid.legend',
    position: 'niki.grid.position',
    markerSize: 'niki.grid.marker_size',
    markerStroke: 'niki.grid.marker_stroke',
    markerPadding: 'niki.grid.marker_padding',
    itemSpacing: 'niki.grid.item_spacing',
    itemPaddingX: 'niki.grid.item_padding_x',
    itemPaddingY: 'niki.grid.item_padding_y',
    layoutHorizontalSpacing: 'niki.grid.layout_horizontal_spacing',
    layoutVerticalSpacing: 'niki.grid.layout_vertical_spacing',
    strokeWidth: 'niki.grid.stroke_width',
    offset: 'niki.grid.offset',
    offsets: 'niki.grid.offsets',
    tooltips: 'niki.grid.tooltips',
    callout: 'niki.grid.callout',
    markers: 'niki.grid.markers',
    shadow: 'niki.grid.shadow',
    blur: 'niki.grid.blur',
    xOffset: 'niki.grid.x_offset',
    yOffset: 'niki.grid.y_offset',
    lineWidth: 'niki.grid.line_width',
    normal: 'niki.grid.normal',
    bold: 'niki.grid.bold',
    italic: 'niki.grid.italic',
    boldItalic: 'niki.grid.bold_italic',
    predefined: 'niki.grid.predefined',
    fillOpacity: 'niki.grid.fill_opacity',
    strokeOpacity: 'niki.grid.stroke_opacity',
    histogramBinCount: 'niki.grid.histogram_bin_count',
    columnGroup: 'niki.grid.column_group',
    barGroup: 'niki.grid.bar_group',
    pieGroup: 'niki.grid.pie_group',
    lineGroup: 'niki.grid.line_group',
    scatterGroup: 'niki.grid.scatter_group',
    areaGroup: 'niki.grid.area_group',
    histogramGroup: 'niki.grid.histogram_group',
    groupedColumnTooltip: 'niki.grid.grouped_column_tooltip',
    stackedColumnTooltip: 'niki.grid.stacked_column_tooltip',
    normalizedColumnTooltip: 'niki.grid.normalized_column_tooltip',
    groupedBarTooltip: 'niki.grid.grouped_bar_tooltip',
    stackedBarTooltip: 'niki.grid.stacked_bar_tooltip',
    normalizedBarTooltip: 'niki.grid.normalized_bar_tooltip',
    pieTooltip: 'niki.grid.pie_tooltip',
    doughnutTooltip: 'niki.grid.doughnut_tooltip',
    lineTooltip: 'niki.grid.line_tooltip',
    groupedAreaTooltip: 'niki.grid.grouped_area_tooltip',
    stackedAreaTooltip: 'niki.grid.stacked_area_tooltip',
    normalizedAreaTooltip: 'niki.grid.normalized_area_tooltip',
    scatterTooltip: 'niki.grid.scatter_tooltip',
    bubbleTooltip: 'niki.grid.bubble_tooltip',
    histogramTooltip: 'niki.grid.histogram_tooltip',
    noDataToChart: 'niki.grid.no_data_to_chart',
    pivotChartRequiresPivotMode: 'niki.grid.pivot_chart_requires_pivot_mode',
    chartSettingsToolbarTooltip: 'niki.grid.chart_settings_toolbar_tooltip',
    chartLinkToolbarTooltip: 'niki.grid.chart_link_toolbar_tooltip',
    chartUnlinkToolbarTooltip: 'niki.grid.chart_unlink_toolbar_tooltip',
    chartDownloadToolbarTooltip: 'niki.grid.chart_download_toolbar_tooltip',
    
    // ARIA
    ariaHidden: 'niki.grid.aria_hidden',
    ariaVisible: 'niki.grid.aria_visible',
    ariaChecked: 'niki.grid.aria_checked',
    ariaUnchecked: 'niki.grid.aria_unchecked',
    ariaIndeterminate: 'niki.grid.aria_indeterminate',
    ariaDefaultListName: 'niki.grid.aria_default_list_name',
    ariaColumnSelectAll: 'niki.grid.aria_column_select_all',
    ariaInputEditor: 'niki.grid.aria_input_editor',
    ariaDateFilterInput: 'niki.grid.aria_date_filter_input',
    ariaFilterList: 'niki.grid.aria_filter_list',
    ariaFilterInput: 'niki.grid.aria_filter_input',
    ariaFilterColumnsInput: 'niki.grid.aria_filter_columns_input',
    ariaFilterValue: 'niki.grid.aria_filter_value',
    ariaFilterFromValue: 'niki.grid.aria_filter_from_value',
    ariaFilterToValue: 'niki.grid.aria_filter_to_value',
    ariaFilteringOperator: 'niki.grid.aria_filtering_operator',
    ariaColumn: 'niki.grid.aria_column',
    ariaColumnList: 'niki.grid.aria_column_list',
    ariaColumnGroup: 'niki.grid.aria_column_group',
    ariaRowSelect: 'niki.grid.aria_row_select',
    ariaRowDeselect: 'niki.grid.aria_row_deselect',
    ariaRowToggleSelection: 'niki.grid.aria_row_toggle_selection',
    ariaRowSelectAll: 'niki.grid.aria_row_select_all',
    ariaToggleVisibility: 'niki.grid.aria_toggle_visibility',
    ariaSearch: 'niki.grid.aria_search',
    ariaSearchFilterValues: 'niki.grid.aria_search_filter_values',
    
    // ARIA LABEL FOR DIALOGS
    ariaLabelColumnMenu: 'niki.grid.aria_label_column_menu',
    ariaLabelCellEditor: 'niki.grid.aria_label_cell_editor',
    ariaLabelDialog: 'niki.grid.aria_label_dialog',
    ariaLabelSelectField: 'niki.grid.aria_label_select_field',
    ariaLabelTooltip: 'niki.grid.aria_label_tooltip',
    ariaLabelContextMenu: 'niki.grid.aria_label_context_menu',
    ariaLabelSubMenu: 'niki.grid.aria_label_sub_menu',
    ariaLabelAggregationFunction: 'niki.grid.aria_label_aggregation_functionn'
}