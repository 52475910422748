import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { updateReturnSelectedQTY } from "./../reduxToolkit/returnsSlice"

export const InputRendererReturns = params => {

    const maxValue = parseInt(params.data.qty)

    /*
    params.api.forEachNode(function (node) {
        // node.setExpanded(node.id === '1')
        node.setExpanded(true)
    })
    */

    const dispatch = useDispatch()
    const selectedContentQTYs = useSelector(state => state.returnsContent.selectedContentQTYs)
    const uuid = params.data.uuid
    const [inputValue, setInputValue] = useState(selectedContentQTYs[uuid])
    
    useEffect(() => {
        setInputValue(params.data.qty)
        dispatch(updateReturnSelectedQTY({uuid: params.data.uuid, qty: params.data.qty}))
        params.setValue(params.data.qty)
          // eslint-disable-next-line
    }, [])
    
    const InputReturnCountChange = e => {
        const localValue = parseInt(e.target.value)
        setInputValue(localValue)
        dispatch(updateReturnSelectedQTY({uuid: uuid, qty: localValue}))
        params.setValue(localValue)
    }
    const PlusInputReturnCount = () => {
        const localValue = inputValue + 1 > maxValue ? maxValue : inputValue + 1
        setInputValue(localValue)
        dispatch(updateReturnSelectedQTY({uuid: uuid, qty: localValue}))
        params.setValue(localValue)
    }
    const MinusInputReturnCount = () => {
        const localValue = inputValue - 1 < 0 ? 0 : inputValue - 1
        setInputValue(localValue)
        dispatch(updateReturnSelectedQTY({uuid: uuid, qty: localValue}))
        params.setValue(localValue)
    }
    return (
        <div className="input_renderer_returns">
            <button
                className="input_renderer_returns__button minus"
                onClick={MinusInputReturnCount}
            >-</button>
            <input
                className="input_renderer_returns__input"
                value={inputValue || 0}
                onChange={InputReturnCountChange}
            />
            <button
                className="input_renderer_returns__button plus"
                onClick={PlusInputReturnCount}
                disabled={inputValue === params.data.qty}
            >+</button>
        </div>
    )
}