import { useRef, useEffect, useState } from "react"
import { t } from "i18next"
import './../assets/css/alertpopup.css'

export const AlertPopup = ({text, addclass, textAlignJustify, closeAlert, onFirstButtonClick, onSecondButtonClick, towButtons, titleFirstButton, titleSecondButton, ...props}) => {
    const closeButtonRef = useRef(null)
    const alertWrapperEl = useRef(null)
    const [isCloseAlert, setIsCloseAlert] = useState(false)
    const [isCloseAlertFirstButton, setIsCloseAlertFirstButton] = useState(false)
    const [isCloseAlertSecondButton, setIsCloseAlertSecondButton] = useState(false)

    useEffect(() => {
       closeAlert && closeButtonRef.current.focus()
    }, [closeAlert])
    
    useEffect(() => {
        let timer;
        let timerButton;

        clearTimeout(timer);
        timer = setTimeout(() => {
            alertWrapperEl.current.classList.add('active')
        }, 200); 
        
        clearTimeout(timerButton);
        timerButton = setTimeout(() => {
            closeButtonRef.current.focus()
        }, 1200);   
      
        return () => {
            clearTimeout(timer)
            clearTimeout(timerButton)
        };
    }, [])

    useEffect(() => {
        let timer;

        if (isCloseAlert && closeAlert) {
            clearTimeout(timer);
            timer = setTimeout(() => closeAlert(), 400)
        }else if (isCloseAlertFirstButton && onFirstButtonClick) {
            clearTimeout(timer);
            timer = setTimeout(() => onFirstButtonClick(), 400)
        } else if (isCloseAlertSecondButton && onSecondButtonClick) {
            clearTimeout(timer);
            timer = setTimeout(() => onSecondButtonClick(), 400)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isCloseAlert, isCloseAlertFirstButton, isCloseAlertSecondButton])

    const closeAlertPopup = () => {
        setIsCloseAlert(true)
        alertWrapperEl.current.classList.remove('active')
    }

    const firstButtonClick = () => {
        setIsCloseAlertFirstButton(true)
        alertWrapperEl.current.classList.remove('active')
    }

    const secondButtonClick = () => {
        setIsCloseAlertSecondButton(true)
        alertWrapperEl.current.classList.remove('active')
    }

    return (
        <div className={[`alert_popup__wrapper`, addclass].join(' ')} ref={alertWrapperEl}>
            <div className="alert_popup__content">
            <div className={["alert_popup__content__text", textAlignJustify ? "alert_popup__text_align_justify" : ""].join(' ')} dangerouslySetInnerHTML={{ __html: text }} />
                <div className="wrapper__button_primary">
                    {towButtons
                        ? <div className="alert_popup__buttons">
                            <button className="btn btn-info" onClick={firstButtonClick}>{titleFirstButton ? t(titleFirstButton) : t('niki.b2b.shoppingcart.delete')}</button>
                            {titleSecondButton
                                ? <button className="btn btn-primary" onClick={secondButtonClick} ref={closeButtonRef} >{titleSecondButton ? t(titleSecondButton) : t('niki.b2b.returnproducts.close')}</button>
                                : <button className="btn btn-primary" onClick={closeAlertPopup} ref={closeButtonRef} >{t('niki.b2b.returnproducts.close')}</button>
                            }
                        </div>
                        : <button className="btn btn-primary" onClick={closeAlertPopup} ref={closeButtonRef} >{t('niki.b2b.returnproducts.close')}</button>
                    }
                </div>
                <button className="alert_popup__content__close" onClick={closeAlertPopup}></button>
            </div>
            <div className="alert_popup__overlay" onClick={closeAlertPopup}></div>
        </div>
    )
}