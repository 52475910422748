import React, { useEffect, useState } from 'react'
import { ShowLoader, HideLoader } from '../../functions'
import { t } from "i18next"
import { AlertPopup } from '../AlertPopup'
import { ChooseLang } from './ChooseLang'
import { getRequestOptionsNoAuth } from '../../helpers/apiHelper'

export const LoginFormFooter = () => {

    useEffect(()=>{
        document.body.classList.contains('preload') && document.body.classList.remove('preload')
    })

    const [privacyPolicyText, setPrivacyPolicyText] = useState('')
    const [privacyPolicyLoaded, setPrivacyPolicyLoaded] = useState(false)
    const [mandatoryText, setMandatoryText] = useState('')
    const [mandatoryLoaded, setMandatoryLoaded] = useState(false)
    const [isOpenPrivacyPolicyAlert, setIsOpenPrivacyPolicyAlert] = useState(false)
    const [isOpenMandatoryAlert, setIsOpenMandatoryAlert] = useState(false)
    
    const ShowPrivacyPolicy = () => {
        if (privacyPolicyLoaded) {
            setIsOpenPrivacyPolicyAlert(true)
        } else {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/privacy-policies-public`,getRequestOptionsNoAuth('GET','text/plain'))
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    setPrivacyPolicyText(json.data[0].body)
                    setPrivacyPolicyLoaded(true)
                    setIsOpenPrivacyPolicyAlert(true)
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }
    
    const ShowMandatory = () => {
        if (mandatoryLoaded) {
            setIsOpenMandatoryAlert(true)
        } else {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/terms-and-conditions-public`,getRequestOptionsNoAuth('GET','text/plain'))
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    setMandatoryText(json.data[0].body)
                    setMandatoryLoaded(true)
                    setIsOpenMandatoryAlert(true)
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }
    
    return (
        <>
            <ChooseLang />
            <div className="login_form__footer"><span onClick={ShowPrivacyPolicy} className="like_link">{t('niki.b2b.footer.privacypolicy')}</span> | <span className="like_link" onClick={ShowMandatory}>{t('niki.b2b.footer.mandatorydisclosures')}</span></div>
            {isOpenPrivacyPolicyAlert && <AlertPopup text={privacyPolicyText} textAlignJustify={true} closeAlert={() => setIsOpenPrivacyPolicyAlert(false)} />}
            {isOpenMandatoryAlert && <AlertPopup text={mandatoryText} textAlignJustify={true} closeAlert={() => setIsOpenMandatoryAlert(true)} />}
        </>
    )
}