import { useEffect, useState } from "react"
import { NavLink } from 'react-router-dom'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select';
import { t } from "i18next"
import { getRequestOptions } from "../helpers/apiHelper"
import { ShowLoader, HideLoader, returnAddressDelivery, returnAddressForChange } from '../functions'
import { Breadcrumbs } from "../breadcrumbs"

import { ReactComponent as Invoices } from './../assets/svg/dainvoices.svg'
import { ReactComponent as Orders } from './../assets/svg/daorders.svg'
import { ReactComponent as Debts } from './../assets/svg/dadebts.svg'
import { ReactComponent as ShoppingCarts } from './../assets/svg/dashoppingcarts.svg'
import { ReactComponent as Account } from './../assets/svg/daaccount.svg'
import { ReactComponent as RedStar } from './../assets/svg/redstar.svg'
import { ReactComponent as More } from './../assets/svg/damore.svg'

const entityId = JSON.parse(localStorage.getItem('entity_id'))
const addresses = JSON.parse(localStorage.getItem('address.object'))
const defaultState = {
    matchcode: '',
    country_id: '',
    state_id: '', 
    street_1: '',
    street_number: '',
    street_2: '',
    postal_code: '',
    city: '',
    entity_account_id: localStorage.getItem('account.entity.id.real'),
}

const GetDeliveryAddresses = async () => {
    // const URL = `${process.env.REACT_APP_API_DOMAIN}/entity-account-address/${localStorage.getItem('account.entity.id')}?include=company&realKey=true`
    const URL = `${process.env.REACT_APP_API_DOMAIN}/entity-addresses/${entityId.entityId}?realKey=true`
    const response = await fetch(URL,getRequestOptions())
    const json = await response.json()
    return json
}

const LoadCartsData = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/cart-headers/${localStorage.getItem('account.entity.id')}?include=status,company,cartDetails,items&realKey=1&orderBy=id&sortedBy=desc&showVat=true`
    const response = await fetch(URL,getRequestOptions())
    const json = await response.json()
    return json
}
const LoadDebtsData = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/entity-ledgers?search=entity_account_id%3A${localStorage.getItem('account.entity.id.real')}&realKey=1`
    const response = await fetch(URL,getRequestOptions())
    const json = await response.json()
    return json
}

const DeliveryAddressesContent = () => {

    const [tableData, setTableData] = useState([])
    const [selectAddressId, setSelectAddressId] = useState('')
    const [isOpenForm, setOpenForm] = useState(false)
    const [isOpenChangePop, setOpenChangePop] = useState(false)
    const [numberCarts, setNumberCarts] = useState(0)
    const [numberDebts, setNumberDebts] = useState(0)
    const [addressToChange, setAddressToChange] = useState(null)

    const showMenu = (address) => {
        setOpenChangePop(true)
        setSelectAddressId(address.id)
    }
    const hideMenu = () => setOpenChangePop(false)
    const showForm = () => setOpenForm(true)
    const openChangePop = (id) => isOpenChangePop && id === selectAddressId 

    const deleteAddress = (address) => {
        setOpenChangePop(false)
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/addresses/${address.id}`, getRequestOptions('DELETE'))
            .then(response => {
                setTableData(tableData.filter(adr => adr.id !== address.id))
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }
    
    const updateAddress = (bodyRequest) => {
        hideMenu()
        const requestOptionsPut = getRequestOptions('PUT', null, 'application/json')
        requestOptionsPut.body = JSON.stringify(bodyRequest)
        
        fetch(`${process.env.REACT_APP_API_DOMAIN}/addresses/${selectAddressId}?realKey=1`, requestOptionsPut)
            .then(response => response.json()).then(json => {
                if (json.data) {
                    const newChangeAddress = returnAddressDelivery(json.data)
                    const changeOfAddress = tableData.map(obj => {
                        return obj.id === selectAddressId ? newChangeAddress : obj
                    });
                    setTableData(changeOfAddress)
                }
            })
            .catch(error => console.error(error)) 
        .catch(error => console.error(error))  
            .catch(error => console.error(error)) 
            .finally(() => {
                setOpenForm(false)
                setAddressToChange(null)
            })
    }

    const onChangedAddress = (address) => {
        showForm()
        hideMenu()
        setAddressToChange(returnAddressForChange(address))
    }
   
    useEffect(() => {
        try {
            LoadCartsData().then(json => json.data && setNumberCarts(json.data.length))
        } catch (error) {
            console.error(error)
        }

        try {
            LoadDebtsData().then(json => json.data && setNumberDebts(json.data.length))
        } catch (error) {
            console.error(error)
        }
    }, [])
    
    useEffect(() => {
        !isOpenForm && setAddressToChange(null)
    }, [isOpenForm])

    useEffect(()=>{
        ShowLoader()
        GetDeliveryAddresses()
        .then(json => {
            if (json.data) {
                setTableData(json.data.map(a => returnAddressDelivery(a)))
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }, [])
    
    return (
        <>
            <div className="wrapper">
                <h1>{t('niki.b2b.account.deliveryaddresses')}</h1>
                <div className="delivery_addresses__columns">
                    <div>
                        <div className="delivery_addresses__info">
                            <div className="delivery_addresses__info__header">{t('niki.b2b.deliveryaddresses.defaultdelivery')}: {addresses.matchcode}</div>
                            <hr />
                            <div className="delivery_addresses__info__grid">
                                <div>
                                    <div className="delivery_addresses__info__grid__def">{t('niki.b2b.deliveryaddresses.companyname')}:</div>
                                    <div>{addresses.matchcode}</div>
                                </div>
                                <div>
                                    <div className="delivery_addresses__info__grid__def">{t('niki.b2b.deliveryaddresses.phone')}:</div>
                                    <div>{addresses.contact.data.phone}</div>
                                </div>
                                <div>
                                    <div className="delivery_addresses__info__grid__def">{t('niki.b2b.login.vatid')}:</div>
                                    <div>{localStorage.getItem('local.tax.number')}</div>
                                </div>
                                <div>
                                    <div className="delivery_addresses__info__grid__def">{t('niki.b2b.login.email')}:</div>
                                    <div>{addresses.contact.data.email}</div>
                                </div>
                                <div>
                                    <div className="delivery_addresses__info__grid__def">{t('niki.b2b.deliveryaddresses.billingaddress')}:</div>
                                    <div>{addresses.street_1} {addresses.street_2}<br />{addresses.postal_code} {addresses.state_name}<br />{addresses.country_name}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className="delivery_addresses__buttons">
                            <li><NavLink to="/invoices/"><Invoices />{t('niki.b2b.menu.invoicesreturns')}</NavLink></li>
                            <li><NavLink to="/orders/"><Orders />{t('niki.b2b.account.orders')}</NavLink></li>
                            <li><NavLink to="/debts/"><Debts />{t('niki.b2b.account.debts')} {numberDebts > 0 && <span className="delivery_addresses__buttons__alert">{numberDebts}</span>}</NavLink></li>
                            <li><NavLink to="/shoppingcarts/" className="delivery_addresses__buttons__shoppingcarts"><ShoppingCarts />{t('niki.b2b.cart.shoppingcarts')} {numberCarts > 0 && <span className="delivery_addresses__buttons__alert">{numberCarts}</span>}</NavLink></li>
                            <li><NavLink to="/account/"><Account />{t('niki.b2b.account.account')}</NavLink></li>
                        </ul>
                    </div>
                </div>
                <div className="delivery_addresses__header_button">
                    <h3>{t('niki.b2b.account.deliveryaddresses')}</h3>
                    <button className="btn" onClick={showForm}>{t('niki.b2b.deliveryaddresses.request')}</button>
                </div>
                <table className="table delivery_addresses__table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('niki.b2b.deliveryaddresses.addressname')}</th>
                            <th>{t('niki.b2b.deliveryaddresses.street')}</th>
                            <th>{t('niki.b2b.deliveryaddresses.city')}</th>
                            <th>{t('niki.b2b.deliveryaddresses.postalcode')}</th>
                            <th>{t('niki.b2b.deliveryaddresses.country')}</th>
                            <th>{t('niki.b2b.deliveryaddresses.edit')}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {tableData.map((e,i)=>
                        <tr key={`tr${i}`} className={`current_tr_${e.addressType}`}>
                            <td>{e.addressType === 1 ? <RedStar className="star" /> : ''}</td>
                            <td>{e.addressName}</td>
                            <td>{e.street} {e.street_number}</td>
                            <td>{e.city}</td>
                            <td>{e.postalCode}</td>
                            <td>{e.country}</td>
                            <td>
                                {e.addressType !== 1 && <div className="relative">
                                    <button className="delivery_addresses__more" onClick={() => showMenu(e)}><More /></button>
                                    <div className={openChangePop(e.id) ? "delivery_addresses__table_menu active" : "delivery_addresses__table_menu"}>
                                        {/* <button>{t('niki.b2b.deliveryaddresses.setasdefault')}</button> */}
                                        <button onClick={() => onChangedAddress(e)}>{t('niki.b2b.deliveryaddresses.editaddress')}</button>
                                        <button onClick={() => deleteAddress(e)}>{t('niki.b2b.deliveryaddresses.deleteaddress')}</button>
                                    </div>
                                    <div className={openChangePop(e.id) ? "delivery_addresses__table_menu__overlay active" : "delivery_addresses__table_menu__overlay"} onClick={hideMenu}></div>
                                </div>}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {isOpenForm && <DeliveryPopup setTableData={setTableData} isOpenForm={isOpenForm} setOpenForm={setOpenForm} addressToChange={addressToChange} updateAddress={updateAddress}/>}
        </>
    )
}

const DeliveryPopup = ({setTableData, isOpenForm, setOpenForm, addressToChange, updateAddress}) => {
    const [state,setState] = useState(defaultState)

    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const FormChange = e => {
        setState({...state,[e.target.name]:e.target.value})
    }
    const GetDeliveryCountries = async () => {
        fetch(`${process.env.REACT_APP_API_DOMAIN}/countries`, getRequestOptions())
        .then(response => response.json()).then(json => {
                if (json?.data?.length > 0) {
                    setCountries(json.data.map(c => { return ({ value: c.id, label: `${c.name} (${c.iso_3166_3})` }) }))
                }
        })
        .catch(error => console.error(error))
    }
    
    const GetDeliveryStates = async (countryId) => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_DOMAIN}/states?country_id=${countryId}`, getRequestOptions())
        .then(response => response.json()).then(json => {
            if (json?.data?.length > 0) {
                setRegions(json.data.map(c => { return ({ value: c.id, label: c.short_name }) }))
                setState(prev => ({ ...prev, state_id: json.data[0].id }))
            } else {setRegions([])}
        })
        .catch(error => console.error(error))
        .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (addressToChange) {
            GetDeliveryCountries()
            GetDeliveryStates(addressToChange.country_id)

            for (let key in addressToChange) {
                setState(prev => ({...prev, [key]: addressToChange[key]}))
            }
        } else {
            GetDeliveryCountries()
            GetDeliveryStates(826)
        }
    }, [addressToChange])

    useEffect(() => {
        selectedCountry && setState(prev => ({ ...prev, country_id: selectedCountry.value }))
        selectedCountry && GetDeliveryStates(selectedCountry.value)
    }, [selectedCountry])

    useEffect(() => {
        selectedRegion && setState(prev => ({ ...prev,  state_id: selectedRegion.value}))
    }, [selectedRegion])

    useEffect(() => {
        if (!selectedCountry && !addressToChange) {
            countries.length > 0 && regions.length > 0 && setState(prev => ({ ...prev, state_id: regions[0].value, country_id: countries.filter(c => c.value === 826)[0].value}))
        }
    }, [countries, regions, selectedCountry, addressToChange])

    const ClosePopup = (e) => {
        e.preventDefault()
        setOpenForm(false)
    }
    
    const FormSubmit = e => {
        e.preventDefault()
        const bodyRequest = state
        for (let key in state) {
            if (state[key] === '') return
        }
        if (addressToChange) {
            updateAddress(bodyRequest)
        } else {
            const requestOptionsPost = getRequestOptions('POST', null, 'application/json')
            requestOptionsPost.body = JSON.stringify(bodyRequest)
            
            fetch(`${process.env.REACT_APP_API_DOMAIN}/addresses-entity`, requestOptionsPost)
                .then(response => response.json()).then(json => {
                    if (json.data) {
                        setTableData(prev => [...prev, returnAddressDelivery(json.data)])
                    }
                })
                .catch(error => console.error(error))
                .finally(() => setOpenForm(false))
        }   
    }

    return (
        <div className={["delivery_addresses__popup__wrapper", isOpenForm && "active"].join(' ')}>
            {countries.length > 0 && <form className="delivery_addresses__popup__content" onSubmit={FormSubmit}>
                <div className="delivery_addresses__popup__content__header">{t('niki.b2b.deliveryaddresses.requestchanges')}: {t('niki.b2b.account.deliveryaddress')}</div>
                <div className="delivery_addresses__popup__rows">
                    <div className="delivery_addresses__popup__row">
                        <label htmlFor="matchcode" className="delivery_addresses__popup__label">{t('niki.b2b.deliveryaddresses.addressname')}</label>
                        <input type="text" className="form-control" name="matchcode" id="matchcode" value={state.matchcode} onChange={FormChange} />
                    </div>
                    <div className="delivery_addresses__popup__row two_columns">
                        <div>
                            <label htmlFor="country" className="delivery_addresses__popup__label">{t('niki.b2b.deliveryaddresses.country')}</label>
                            <Select
                                id="country" name="country"
                                className="form-control-select"
                                defaultValue={addressToChange?.country_id ? countries.filter(r => r.value === addressToChange?.country_id)[0] : countries.filter(c => c.value === 826)[0]}
                                onChange={setSelectedCountry}
                                options={countries}
                                placeholder={t('niki.b2b.header.search')}
                                isLoading={isLoading}
                                noOptionsMessage={({inputValue}) => !inputValue ? null : t('niki.b2b.not_found')} 
                            />
                        </div>
                        <div>
                            <label htmlFor="province" className="delivery_addresses__popup__label">{t('niki.b2b.deliveryaddresses.provinceregion')}</label>
                            {regions.length > 0 && !isLoading
                                ? < Select
                                    id="province" name="province"
                                    className="form-control-select"
                                    defaultValue={addressToChange?.state_id ? regions.filter(r => r.value === addressToChange?.state_id)[0] : regions[0]}
                                    onChange={setSelectedRegion}
                                    options={regions}
                                    placeholder={t('niki.b2b.header.search')}
                                    noOptionsMessage={({inputValue}) => !inputValue ? null : t('niki.b2b.not_found')} 
                                />
                                :  <select className="form-control" id="region" name="region" value={state.region} onChange={FormChange}>
                                {regions.map((c, index) => <option key={index}>{c.regions}</option>)}
                                </select>
                            }
                        </div>
                    </div>
                    <div className="delivery_addresses__popup__row one_two_columns">
                        <div>
                            <label htmlFor="street_1" className="delivery_addresses__popup__label">{t('niki.b2b.deliveryaddresses.street')}</label>
                            <input type="text" className="form-control" id="street_1" name="street_1" value={state.street_1} onChange={FormChange} />
                        </div>
                        <div>
                            <label htmlFor="street_number" className="delivery_addresses__popup__label">{t('niki.grid.number')}</label>
                            <input type="text" className="form-control" id="street_number" name="street_number" value={state.street_number} onChange={FormChange} />
                        </div>
                    </div>
                    <div className="delivery_addresses__popup__row">
                        <div>
                            <label htmlFor="street_2" className="delivery_addresses__popup__label">{t('niki.b2b.deliveryaddresses.street')} 2</label>
                            <input type="text" className="form-control" id="street_2" name="street_2" value={state.street_2} onChange={FormChange} />
                        </div>
                    </div>
                    <div className="delivery_addresses__popup__row two_columns">
                        <div>
                            <label htmlFor="postal_code" className="delivery_addresses__popup__label">{t('niki.b2b.deliveryaddresses.postalcode')}</label>
                            <input type="text" className="form-control" id="postal_code" name="postal_code" value={state.postal_code} onChange={FormChange} />
                        </div>
                        <div>
                            <label htmlFor="city" className="delivery_addresses__popup__label">{t('niki.b2b.deliveryaddresses.city')}</label>
                            <input type="text" className="form-control" id="city" name="city" value={state.city} onChange={FormChange} />
                        </div>
                    </div>
                    <div className="right">
                        <button type="submit" className="btn btn-info">{t('niki.b2b.deliveryaddresses.requestchanges')}</button>
                    </div>
                </div>
                <button className="delivery_addresses__popup__close" onClick={ClosePopup}></button>
            </form>}
            <div className="delivery_addresses__popup__overlay" onClick={ClosePopup}></div>
        </div>
    )
}

export const DeliveryAddresses = () => {
    return (
        <>
            <Breadcrumbs itemlist={[
                {'url':'/account/','title':t('niki.b2b.account.my')},
                {'url':'/deliveryaddresses/','title':t('niki.b2b.account.deliveryaddresses')},
            ]} />
            <DeliveryAddressesContent />
        </>
    )
}