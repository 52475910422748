import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { LangFix } from "./functions"
import translationEN from "../src/translations/en.json"
import translationES from "../src/translations/es.json"
import translationRU from "../src/translations/ru.json"
import translationPT from "../src/translations/pt.json"
import translationFR from "../src/translations/fr.json"
import translationDE from "../src/translations/de.json"
import translationGR from "../src/translations/gr.json"
import translationIT from "../src/translations/it.json"

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  ru: {
    translation: translationRU
  },
  pt: {
    translation: translationPT
  },
  fr: {
    translation: translationFR
  },
  de: {
    translation: translationDE
  },
  gr: {
    translation: translationGR
  },
  it: {
    translation: translationIT
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    returnEmptyString: false,
    // debug: true,
    lng: LangFix(localStorage.getItem('locale')),
    interpolation: {
      escapeValue: false
    },
  })

export default i18n
