import { useEffect, useState } from "react"
import { t } from "i18next"
import { useDispatch, useSelector } from "react-redux"
import { formatNumber } from '../../functions'
import { setShoppingCartSummary, setIsProductSearch } from '../../reduxToolkit/cartSlice'


import "../../assets/css/cart.css"

export const CustomGroupRow = (props) => {
    const dispatch = useDispatch()
   
    const node = props.node;
    const aggData = node.aggData;

    const [groupName, setGroupName] = useState(node.key);
    const [qtyCount, setQtyCount] = useState(aggData.qty);
    const [totalCount, setTotalCount] = useState(aggData.total.slice(0,1) + formatNumber(aggData.total.slice(1), 2));
   
    const isProductSearch = useSelector(state => state.cartContent.isProductSearch)

    const refreshUi = () => {
        const node = props.node;
        const aggData = node.aggData;

        setGroupName(node.key);
        setQtyCount(aggData.qty);
        setTotalCount(aggData.total);
    };

    const dataChangedListener = () => refreshUi();

    useEffect(() => {
        props.api.addEventListener('cellValueChanged', dataChangedListener);
        props.api.addEventListener('filterChanged', dataChangedListener);

        return () => {
        props.api.removeEventListener('cellValueChanged', dataChangedListener);
        props.api.removeEventListener('filterChanged', dataChangedListener);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isProductSearch) {
            dispatch(setShoppingCartSummary({
                count: node.allChildrenCount, total: totalCount, supplierName: groupName, estimated_delivery: aggData.estimated_delivery, entity_account_id: node.allLeafChildren[0].data.entity_account_id
            }))
            dispatch(setIsProductSearch(false))
        }
        // eslint-disable-next-line
    }, [isProductSearch]);
    
    return (
        <div className='wrapper__custom_group_row'>
            <span>{groupName} &nbsp;&nbsp;( {node.allChildrenCount} )</span>
            <span aria-label={t('niki.b2b.feedback.qty')}>
                    {t('niki.b2b.feedback.qty')} {qtyCount}
            </span>
            <span aria-label={t('niki.b2b.cart.total')}>
                    {t('niki.b2b.cart.total')} {totalCount}
            </span>
        </div>
    );
};