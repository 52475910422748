import { t } from 'i18next'
import '../../assets/css/product.css'
import '../../assets/css/svg.css'

import { ReactComponent as TruckSmall } from '../../assets/svg/checkoutTruckSmall.svg'

const SuppliedPartner = ({ item_type_id, productView }) => {
    return (
        <>
            {(item_type_id === 98 || item_type_id === 99) &&                  
            <div className={['product_supplied_partner', productView === 'grid' ? '' : 'product_supplied_partner__tree'].join(' ')}>
                    <div>
                        <div><strong>{t('niki.b2b.cartflow.estimateddelivery')}</strong></div>
                        {t('niki.b2b.product.could_different')}
                    </div>
                <TruckSmall /> 
            </div>}
        </>  
    )
}
export default SuppliedPartner;