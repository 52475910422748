
import { useEffect, useState, useRef } from 'react'

import classes from './LoadableImage.module.css'
import cn from 'classnames'


const LoadableImage = (props) => {
    const {src, alt, height, margin, styleImg} = props
    const [isLoaded, setIsLoaded] = useState(false)
    const imageRef = useRef(null)    

    useEffect(() => {
        if (imageRef.current) {
           imageRef.current.onload = () => {setIsLoaded(true)}
       }
    }, [])
   
    return (
        <div className={cn( classes.container, {
            [classes.containerLoaded]: isLoaded
        })} style={{height: !isLoaded ? height : 'auto', margin: margin && margin}}>
            <img ref={imageRef} src={src} alt={alt} className={cn(classes.image, {
                [classes.imageLoaded]: isLoaded
            })} style={{...styleImg}} />
        </div>
    )
}

export default LoadableImage;