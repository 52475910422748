import { getRequestOptionsNoAuth, getBanners, updateMarketingCampaignIds } from './apiHelper'

const GetNewAccessToken = async () => {
    const requestOptions = getRequestOptionsNoAuth('POST', null, 'application/json')
    requestOptions.body = JSON.stringify({
        refresh_token: localStorage.getItem('refresh.token'),
        client_info: localStorage.getItem('client.info'),
        username: localStorage.getItem('username'),
        // grant_type: 'refresh_token',
        // client_id: process.env.REACT_APP_CLIENT_WEB_ID,
        // client_secret: process.env.REACT_APP_CLIENT_WEB_SECRET,
    })
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/clients/web/refresh`,requestOptions)
    const json = await response.json()
    return json
}

export const goToLogin = () => {
    localStorage.removeItem('access.token')
    localStorage.removeItem('access.token.lifetime')
    localStorage.removeItem('account.user.name')
    localStorage.removeItem('account.entity.id')
    localStorage.removeItem('account.entity.id.real')
    localStorage.removeItem('account.entity.name')
    localStorage.removeItem('account.company')
    localStorage.removeItem('overdue.date')
    localStorage.removeItem('overdue.summ')
    localStorage.removeItem('enter.shop')
    localStorage.removeItem('account.user.email')
    localStorage.removeItem('account.user.phone')
    window.location.href = '/'
}

export const CheckTokenLifeTime = () => {

    // const tokenLifetime = 60 * 60 * 24 * 1000
    
    localStorage.setItem('history.ids', undefined)
    try {
        updateMarketingCampaignIds()
        getBanners().then(json => {
            json.data && localStorage.setItem('banners_data', JSON.stringify(json.data))
        })
    } catch (error) {
        console.error(error)
    }
    try {
        GetNewAccessToken()
            .then(json => {
                // console.log('GetNewAccessToken', json, typeof json)
                
                if (json.access_token && json.refresh_token) {
                    localStorage.setItem('access.token',json.access_token)
                    localStorage.setItem('refresh.token',json.refresh_token)
                    // localStorage.setItem('access.token.lifetime', parseInt(Date.now()) + tokenLifetime)
                    localStorage.setItem('access.token.lifetime',parseInt(Date.now())+parseInt(json.expires_in)*1000)
                    
                    // if (localStorage.getItem('current.url') && localStorage.getItem('current.url').length > 0) {
                    //     navigate(localStorage.getItem('current.url'))
                    // } else {
                    //     const country = localStorage.getItem('locale.full')
                    //     navigate('/')
                    //     localStorage.setItem('locale.full',country)
                    // }
                } else {
                    goToLogin()
                }
            }).catch((e) => {
                console.error(e)
                goToLogin()
            })
    } catch (e) {
        console.error(e)
        goToLogin()
    }
}
