import { useEffect, useState, memo} from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'

import { priceLineID, pricelineIDRecommended } from '../../config'
import { PriceFormat, CapacityString, GetMainImage, FormatDate, GetHistoryData, LangFix, CalculateRating, returnItemExtrafields, HideLoader, ShowLoader, scrollTop, checkValueOrNonInteger } from '../../functions'
import { getRequestOptions, getCartsData, paramsForCartDetails } from "../../helpers/apiHelper"
import {prepareProductToCart } from "../../helpers/functionsRedux"
import { setIsOpenNoCartAlert, setIsOpenProductNotFoundAlert } from '../../reduxToolkit/universalSlice'
import { updateProduct } from '../../reduxToolkit/productsSlice'
import { updateCartProduct, deleteCartProduct } from '../../reduxToolkit/cartSlice'
import SuppliedPartner from './SuppliedPartner'
import InFavorite from './InFavorite'
import { RegularVatGross } from './RegularVatGross'
import { StarRating } from './StarRating'
import { QtyInBox } from './QtyInBox'

import { ProdHistoryPopup } from '../ProdHistoryPopup'
import { AlertPopup } from '../AlertPopup'

import '../../assets/css/product.css'
import '../../assets/css/svg.css'

import { ReactComponent as TradeMark } from '../../assets/svg/r.svg'
import { ReactComponent as Barcode } from '../../assets/svg/barcode.svg'
import { ReactComponent as Refresh } from '../../assets/svg/refresh.svg'
import { ReactComponent as Box } from '../../assets/svg/box.svg'
import { ReactComponent as PutToCart } from '../../assets/svg/puttocart.svg'
import { ReactComponent as Book } from '../../assets/svg/book.svg'
import { ReactComponent as New } from '../../assets/svg/new.svg'
import { ReactComponent as Trash } from '../../assets/svg/trash.svg'
import { ReactComponent as StarEmpty } from '../../assets/svg/starempty.svg'
import { ReactComponent as Star } from '../../assets/svg/star.svg'
import { ReactComponent as ProductHistory } from '../../assets/svg/producthistory.svg'
import { ReactComponent as InfoWhite } from '../../assets/svg/infowhite.svg'
import { ReactComponent as ChevronUp } from '../../assets/svg/chevronup.svg'
import { ReactComponent as ChevronDown } from '../../assets/svg/chevrondown.svg'
import defaultImage from '../../assets/images/placeholder.jpg'

const getHighlightedText = (text, highlight) => {
    if (text) {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
        return <span> { parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { background: '#FFE600' } : {} }>
                { part }
            </span>)
        } </span>
    }
}

const GetVoteData = async productID => {
    const fetchURL = `${process.env.REACT_APP_API_DOMAIN}/items/${productID}/rating-info`
    const response = await fetch(fetchURL,getRequestOptions())
    const json = await response.json()
    return json
}

const HideStarRating = () => document.querySelector('.rating_popup__wrapper.active').classList.remove('active')

export const Product = memo((props) => {

    const dispatch = useDispatch()

    const productInBox = parseInt(props.itemUnitBarcodeByUnitCodeBox?.data?.coefficient) || parseInt(props.itemUnitBarcodeByUnitCodeBox?.coefficient) || 1
    const selectedLanguage = LangFix(localStorage.getItem('locale'))
    const propsCapacity = !!props.capacity ? props.capacity : 1

    const UpdateCartValue = (value) => {
        const localValue = typeof value === "string" ? parseFloat(value) : value
        if (localValue === 0) {
            RemoveCartItem()
            return
        }

        const productID = props.id
        const cartDetail = cartDetailsIDs[productID]
        setCartButtonStatus('waiting')
        ShowLoader()
        const requestOptions = getRequestOptions('POST', null, 'application/json')
        requestOptions.body = JSON.stringify({ qty: localValue })

        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details/${cartDetail}`,requestOptions)
            .then(response => {
                if (response.ok) {
                    dispatch(updateCartProduct({productid: props.id, cartdetail: cartDetail, qty: localValue}))
                    dispatch(updateProduct({id: props.id, qty: localValue}))
                } else {
                    setCartAmount(productQTYs[props.id])
                }
            // response.ok && dispatch(updateCartProduct({productid: props.id, cartdetail: cartDetail, qty: localValue}))
            // response.ok && dispatch(updateProduct({id: props.id, qty: localValue}))
            })
            .catch(error => {
                console.error(error)
                dispatch(setIsOpenProductNotFoundAlert(true))
                setCartAmount(productQTYs[props?.id])
            })
            .finally(() => {
                setCartButtonStatus('normal')
                HideLoader()
            })
    }

    let ratingDataValue = {rating: 0, count: 0}
    if (props.ratings && props.ratings.length > 0) {
        const ratingDataValueObj = CalculateRating(props.ratings)
        ratingDataValue.count = ratingDataValueObj.count
        ratingDataValue.rating = ratingDataValueObj.rating
    }
    
    const productsInCart = useSelector(state => state.cartContent.productIDs)
    const productQTYs = useSelector(state => state.cartContent.productQTYs)
    const cartDetailsIDs = useSelector(state => state.cartContent.cartDetailsIDs)
    
    // eslint-disable-next-line
    const [productHistory, setProductHistory] = useState([])
    const [cartButtonStatus, setCartButtonStatus] = useState('normal')
    const [ratingStatus, setRatingStatus] = useState('waiting')
    const [isOpenAlert, setIsOpenAlert] = useState(false)
    const [ratingData, setRatingData] = useState({ rating: ratingDataValue.rating, ratingVoices: ratingDataValue.count })
    const [cartAmount, setCartAmount] = useState(productQTYs[props.id] || productInBox || 0)

    let itemID = props.item_id || props.source_item_id
    let productMatchcode = (props.itemDescriptions && props.itemDescriptions[LangFix(selectedLanguage)] && props.itemDescriptions[LangFix(selectedLanguage)].name) || props.matchcode
    let productBrand = props.itemBrand?.data?.name || (props.brand && props.brand.name && props.brand.name[LangFix(selectedLanguage)]) || props.brand?.name || ' '
    let productBarcode = props.barcode || null
    
    useEffect(() => {
        if (GetHistoryData(props.id)) {
            const productHistoryFullUser = GetHistoryData(props.id)
            setProductHistory([])
            productHistoryFullUser.forEach(e => {
                const tempObj = {
                    date: e.created_at,
                    units: e.quantity,
                }
                setProductHistory(prevState => ([...prevState, tempObj]))
            })
        }
    }, [props.id])

    if (props.query) {
        const searchMask = props.query
        productMatchcode = getHighlightedText(productMatchcode,searchMask)
        productBrand = getHighlightedText(productBrand,searchMask)
        productBarcode = getHighlightedText(productBarcode,searchMask)
        itemID = getHighlightedText(itemID,searchMask)
    }

    let productIcons = []
    if (props.ItemExtrafieldsAnswers) {
        productIcons.push(returnItemExtrafields(props.ItemExtrafieldsAnswers) ? returnItemExtrafields(props.ItemExtrafieldsAnswers) : { label:'', icon: ''})
    }

    let price = 0
    // if (props.itemPrices && props.itemPrices?.length > 0) price = parseFloat(props.itemPrices.filter(i => i.priceline_id === priceLineID)[0]?.unit_price)
    if (props.itemPrices && props.itemPrices.data && props.itemPrices.data[0]) price = parseFloat(props.itemPrices.data[0].unit_price)
    let priceOld = 0
    let vatOld = 0
    let grossOld = 0
    let discount = 0
    let showOldData = false
    let gross = 0
    
    if (props.itemPrices) {
        for (var key in props.itemPrices) {
            if (props.itemPrices[key].priceline_id === priceLineID) {
                price = props.itemPrices[key].unit_price
                gross = props.itemPrices[key].tax_included_price
            }
        }
    }

    let capacity = CapacityString(propsCapacity, props.capacity_unit_code, price)
    let capacityOld = 0
    if (props.itemPrices && props.itemPrices.data && props.itemPrices.data[0] && props.itemPrices.data[0].tax_included_price) gross = parseFloat(props.itemPrices.data[0].tax_included_price)
    if (props.itemPrices && props.itemPrices?.length > 0) gross = parseFloat(props.itemPrices.filter(i => i.priceline_id === priceLineID)[0]?.tax_included_price)
    let vat = gross - price

    if ((props.itemDiscounts?.data?.length > 0) || (props.itemDiscounts && props.itemDiscounts[priceLineID])) {
        showOldData = true
        priceOld = price
        vatOld = vat
        grossOld = gross
        if (props.itemDiscounts.data && props.itemDiscounts.data.length > 0) {
            price = props.itemDiscounts.data[0].unit_price
            gross = props.itemDiscounts.data[0].tax_included_price
        } else if (props.itemDiscounts[priceLineID]) {
            price = parseFloat(props.itemDiscounts[priceLineID].unit_price)
            gross = parseFloat(props.itemDiscounts[priceLineID].tax_included_price)
        }
        vat = gross - price
        discount = Math.round((priceOld-price)/priceOld*100)
        capacity = CapacityString(propsCapacity,props.capacity_unit_code,price)
        capacityOld = CapacityString(propsCapacity,props.capacity_unit_code,priceOld)
    }

    let markup = 0
    let margin = 0
    let rrp = props.itemRecommendedPrices && props.itemRecommendedPrices[pricelineIDRecommended] && props.itemRecommendedPrices[pricelineIDRecommended].tax_included_price
    if (rrp) {
        markup = Math.round((rrp-gross)/gross*100)
        margin = Math.round((rrp-gross)/rrp*100)
    }

    let showStock = true
    let available = (props.itemStocks.data && props.itemStocks.data[0] && props.itemStocks.data[0].available) || 0
    const stockUnitCode = props?.sale_unit_code_translations[selectedLanguage] ? props.sale_unit_code_translations[selectedLanguage] : props.sale_unit_code
    if (props.itemStocks && props.itemStocks?.length > 0) {
        available = parseInt(props.itemStocks.filter(i => i.source_warehouse_id === localStorage.getItem('warehouse.id'))[0]?.available)
    }
    const notShowText = (parseInt(localStorage.getItem('show.stock')) === 0 || parseInt(localStorage.getItem('show.stock')) === 1)
    let stockStatus = 'outofstock'
    let stockText = `${t('niki.b2b.product.outofstock')}`
    if (parseInt(localStorage.getItem('show.stock')) === 0) {
        showStock = false
    } else if (available > 20) {
        stockStatus = 'instock'
        stockText = notShowText ?  t('niki.b2b.allproducts.instock') : `${PriceFormat(available,false,false)} ${stockUnitCode}`
    }else if (available > 0) {
        stockStatus = 'low'
        stockText = notShowText ? t('niki.b2b.product.stocklow') : `${t('niki.b2b.product.stocklow')} ${PriceFormat(available, false, false)} ${props.productView === 'grid' ? '' : stockUnitCode}`
    }

    let imgsrc = defaultImage
    if (props.attachments) imgsrc = GetMainImage(props.attachments,props.attachment_id)
    if (props.attachment) imgsrc = props.attachment
    let clickedoncart = props.clickedoncart ? 'clickedoncart' : 'notclickedoncart'

    const ProdHistory = (props.itemHistories && props.itemHistories.data) || GetHistoryData(props.id)

    const country = props.country?.data?.iso_3166_2 || props.country?.iso_3166_2
    
    useEffect(() => {
        productQTYs[props.id] > 0 && setCartAmount(productQTYs[props.id])
    // eslint-disable-next-line
    }, [productQTYs[props.id]])

    async function CartDetailData() {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details?include=item,item.itemDescriptions&cart_header_uuid=${localStorage.getItem('cart.uuid')}`,getRequestOptions())
        const json = await response.json()
        return json
    }

    const returnError = (error, from) => {
        setCartButtonStatus('normal')
        console.error(error)
        const setErrorFunc = () => from ?  dispatch(setIsOpenProductNotFoundAlert(true)) : setIsOpenAlert(true)
        
        if (localStorage.getItem('cart.uuid')) {
            getCartsData()
                .then(json => {
                    let existCart = false
                    json.data && json.data.forEach(e => {
                        existCart = e.uuid === localStorage.getItem('cart.uuid') && true
                    })
                    existCart
                        ? setErrorFunc()
                        : dispatch(setIsOpenNoCartAlert(true))
                })
                .catch(error => {
                    setErrorFunc()
                    console.error(error)
                })
        } else {
            setErrorFunc()
        }
    }
   
    const AddToCart = (qty) => {
        const productID = props.id
        const multiplier = productInBox || 1

        if (localStorage.getItem('cart.uuid')) {
            const uuid = localStorage.getItem('cart.uuid')
            CartDetailData()
                .then(json => {
                    let CartDetail = ''
                    let qtyInCart = 0
                    if (json.data) {
                        json.data.forEach(e => {
                            if (e.item && e.item.data.id && e.item.data.id === productID) {
                                CartDetail = e.id
                                qtyInCart = parseInt(e.qty)
                            }
                        })
                    }
                    if (CartDetail.length > 0) {
                        UpdateCartValue(qty + qtyInCart)
                        setCartAmount(qty + qtyInCart)
                        localStorage.setItem('current.cart.detail', CartDetail)
                    } else {
                        ShowLoader()
                        const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
                        requestOptionsCart.body = JSON.stringify({
                            cart_header_uuid: uuid,
                            item_id: productID,
                            matchcode: props.matchcode,
                            variation_id: '3',
                            qty_in_box: multiplier.toString(),
                            qty: multiplier.toString(),
                            unit_price: price.toString(),
                            tax_included_price: gross.toString()
                        })
                    
                        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details?${paramsForCartDetails}`, requestOptionsCart)
                        .then(response => response.json())
                        .then(json => {
                            json.data && prepareProductToCart(json.data, dispatch)
                            setCartButtonStatus('normal')
                        })
                        .catch(error => returnError(error, 'add'))
                        .finally(() => HideLoader())
                    }
                })
                .catch(error => returnError(error, 'add'))
                .finally(() => HideLoader())
        } else {
            let detaildata = []
            detaildata[0] = {
                matchcode: props.matchcode,
                variation_id: '1',
                qty: multiplier.toString(),
                unit_price: price.toString(),
                tax_included_price: gross.toString(),
                qty_in_box: multiplier.toString(),
                item_id: productID
            }
            const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
            requestOptionsCart.body = JSON.stringify({
                company_id: props.company_id,
                customer_account_id: localStorage.getItem('account.entity.id'),
                status_id: 'J8RvMwowm7k2pNOr',
                details: detaildata
            })
        
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-headers`,requestOptionsCart)
            .then(response => response.json())
            .then(json => {
                json.data && prepareProductToCart(json.data, dispatch)
                setCartButtonStatus('normal')
            })
            .catch(error => returnError(error))
            .finally(() => HideLoader())
        }
    }

    const RemoveCartItem = () => {
        setCartButtonStatus('waiting')
        const productID = props.id
        const cartDetail = cartDetailsIDs[productID]
        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details/${cartDetail}`,getRequestOptions('DELETE'))
        .then(response => {
            response.ok ? dispatch(deleteCartProduct(cartDetail)) :  dispatch(setIsOpenProductNotFoundAlert(true))
        })
        .catch(error => {
            console.error(error)
            dispatch(setIsOpenProductNotFoundAlert(true))
        })
        .finally(() => {
            HideLoader()
            setCartButtonStatus('normal')
        })
    }

    const ClickOnCartButton = e => {
        setCartButtonStatus('waiting')
        const whatToToggleClass = e.target.parentNode.parentNode
        const multiplier = productInBox || 1
        whatToToggleClass.classList.contains('notclickedoncart') ? whatToToggleClass.className = 'cart_stock clickedoncart' : whatToToggleClass.className = 'cart_stock notclickedoncart'
        setCartAmount(multiplier)
        AddToCart(multiplier)
    }

    const CartPlus = () => {
        const multiplier = productInBox || 1
        const amountProduct = typeof cartAmount === "string" ? parseFloat(cartAmount) : cartAmount
        const resultSum = amountProduct + multiplier
        const inputValue = Number.isInteger(resultSum) ? resultSum : resultSum.toFixed(2)
        
        setCartAmount(inputValue)
        UpdateCartValue(inputValue)
    }

    const CartMinus = () => {
        const multiplier = productInBox || 1
        const subtractionResult = cartAmount - multiplier
        const difference = Number.isInteger(subtractionResult) ? subtractionResult : subtractionResult.toFixed(2)

        difference < multiplier ? setCartAmount(multiplier) : setCartAmount(difference)
        UpdateCartValue(difference)
    }

    const [isChangedInput, setChangedInput] = useState(false)
    
    const CartChange = e => {
        const value = e.target.value
        typeof value === "string" && !value && setCartAmount('')
        
        const inputValue = checkValueOrNonInteger(value, props.sale_unit_code)
        if (inputValue === '') return
        
        setCartAmount(inputValue)
        setChangedInput(true)
    }

    const delayedUpdate = () => {
        setChangedInput(false)
        UpdateCartValue(cartAmount)
    }
   
    useEffect(() => {
        let timer;
        if (isChangedInput) {
            if (cartAmount) {
                clearTimeout(timer);
                timer = setTimeout(() => delayedUpdate(), 1950);
            } else {
                clearTimeout(timer);
                timer = setTimeout(() => RemoveCartItem(), 2650);
            }
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [cartAmount]);
    
    const ClickOnRemoveCart = e => {
        RemoveCartItem()
    }

     /* HistoryProduct */
    const [openHistoryProduct, setOpenHistoryProduct] = useState(false)
    const [idHistoryProduct, setIdHistoryProduct] = useState('')

    useEffect(() => {
        if (openHistoryProduct) {
            const PopupSelector = `.product_history__wrapper_${idHistoryProduct}`
            document.querySelector(PopupSelector).classList.add('active')
        }
    }, [openHistoryProduct, idHistoryProduct])
     /* HistoryProduct */
   
    const ProductShortDesc = () => {
        const ShowProdHistory = e => {
            setOpenHistoryProduct(true)
            setIdHistoryProduct(e.target.dataset.id)
        }
        return (
            <ul className="product_in_listing__spec">
                {productBrand && (
                    <li>
                        <span><TradeMark /></span>
                        <div className="product_in_listing__spec__brand">
                            {productBrand}
                            <div>{t('niki.b2b.tooltip.brand')}</div>
                        </div>
                    </li>
                )}
                {productBarcode && (
                    <li>
                        <span><Barcode /></span>
                        <div className="product_in_listing__spec__brand">
                            <div>{t('niki.b2b.tooltip.barcode')}</div>
                            {productBarcode}
                        </div>
                    </li>
                )}
                {(ProdHistory && ProdHistory.length > 0) && (
                    <li>
                        <span><Refresh /></span>
                        <button className="history_button" data-id={itemID} onClick={ShowProdHistory}>
                            {ProdHistory.filter((e, i) => i === 0 || i === 1).map((e, i) => <span key={`prodhistory${i}`}>{FormatDate(e.created_at)} &mdash; {e.quantity} {t('niki.b2b.returnproducts.units')} <ProductHistory /></span>)}
                        </button>
                    </li>
                )}
            </ul>
        )
    }
  
    const StarRatingClick = e => {
        const ratingVote = e.target.value
        const productID = e.target.dataset.id
        const ratingURL = `${process.env.REACT_APP_API_DOMAIN}/items/${productID}/ratings`
        const requestOptionsPostRating = getRequestOptions('POST', null, 'application/json')
        requestOptionsPostRating.body = JSON.stringify({
            value: ratingVote,
            comment: '',
        })
        
        ShowLoader()
        fetch(ratingURL,requestOptionsPostRating)
        .then(response => {
            response.status === 200 && setRatingStatus('success')
            ShowLoader()
            GetVoteData(productID)
            .then(json => {
                json.data && setRatingData({
                    rating: json.data.average,
                    ratingVoices: json.data.count,
                })
            })
            return response.json()
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }
    const [productImgIsValid, setProductImgIsValid] = useState(imgsrc?.endsWith("g"));
    const returnMarginTop = () => props.productView === 'grid'
        ? GetHistoryData(props.id).length > 0
            ? showOldData
                ? GetHistoryData(props.id).length === 1 ? 23 : 2
                : 0
            : showOldData ? 30 : 10
        : 0
    
    const OpenProductHistory = () => {
            setOpenHistoryProduct(true)
            setIdHistoryProduct(itemID)
    }

    const returnHistoryField = () => {
        return GetHistoryData(props.id).length > 0
            ? <div className="wrapper__price" onClick={OpenProductHistory}>
                <>
                    <Refresh />
                    <div>
                        {GetHistoryData(props.id).map((e, i) => <div key={`orderhistoryunit${i}`} className="order_history_unit_grid">
                            {FormatDate(e.created_at)} &mdash; {e.quantity} {stockUnitCode ? stockUnitCode : t('niki.b2b.returnproducts.units')}
                        </div>)}
                    </div>
                    <ProductHistory />
                </>
            </div>
            : <>
                <div className={["prod_qty_in_box", props.productView !== 'grid' && "prod_qty_in_box__list"].join(' ')}>
                    <div>{t('niki.b2b.tooltip.box')}</div>
                    <Box />{productInBox || 1} {stockUnitCode}
                </div>
                <div style={{color: 'white'}}>.</div>
            </>
    }
    
    const checkoutCartAmount = typeof cartAmount === 'string' ? parseFloat(cartAmount) : cartAmount
    const showDeletev = () => productQTYs[props.id] > checkoutCartAmount ? productQTYs[props.id] > productInBox : checkoutCartAmount > productInBox
   
    const onProductClick = () => {
        scrollTop()
        localStorage.setItem('is_on_product_card', 'true')
    }

    return (
        <div key={`product${props.id}`} className={[`product_in_listing`,productsInCart.includes(props.id) ? `incart` : `notincart`].join(' ')}>
            <div className="product_in_listing__first">
                <NavLink to={`/productpage/${props.id}/`} onClick={onProductClick} className="product_in_listing__link">
                    <picture className="product_in_listing__picture">
                        {productImgIsValid
                            ? <>
                                <source srcSet={imgsrc} type="image/jpeg" />
                                <img
                                    src={imgsrc}
                                    alt={props.matchcode}
                                    onError={() => setProductImgIsValid(false)}
                                    className="product_in_listing__image"
                                    loading="lazy"
                                />
                            </>
                            : <>
                                <source srcSet={defaultImage} type="image/jpeg" />
                                <img
                                    src={defaultImage}
                                    alt={props.matchcode}
                                    className="product_in_listing__image"
                                    loading="lazy"
                                />
                            </>
                        }
                    </picture>
                </NavLink>
                {(props.productView === 'grid' && productIcons.length > 0)
                    ? <div className="wrapper_star_rating_product_icons">
                        {productIcons.length > 0 && <ul className="product_in_listing__spec_icons" style={{ display: 'flex' }}>
                            {productIcons.map((el, i) => <li key={`specicon${i}`} data-title={t(el.label)} className={`${el.icon.toLowerCase()}_icon`}style={{width: 30, height: 30, marginRight: 3}}></li>)}
                        </ul>}
                        <StarRating id={props.id} ratingData={ratingData} />
                    </div >
                    : <StarRating id={props.id} ratingData={ratingData} />
                }
                <div className={`rating_popup__wrapper rating_popup__wrapper_${props.id}`}>
                    <div className="rating_popup__content">
                        <div className="rating_popup__title">{t('niki.b2b.vote.whatdoyouthink')}<br />
                            {props?.itemDescriptions[selectedLanguage]?.name ? props.itemDescriptions[selectedLanguage].name : props.matchcode}
                        </div>
                        <div className="rating_popup__img"><img src={imgsrc} alt={props.matchcode} /></div>
                        <div className="star-rating">
                            <input type="radio" name="rating" value="1" data-id={props.id} onClick={StarRatingClick} /><i></i>
                            <input type="radio" name="rating" value="2" data-id={props.id} onClick={StarRatingClick} /><i></i>
                            <input type="radio" name="rating" value="3" data-id={props.id} onClick={StarRatingClick} /><i></i>
                            <input type="radio" name="rating" value="4" data-id={props.id} onClick={StarRatingClick} /><i></i>
                            <input type="radio" name="rating" value="5" data-id={props.id} onClick={StarRatingClick} /><i></i>
                        </div>
                        <div className="hide_on_voted center">{t('niki.b2b.vote.registervote')}.</div>
                        {ratingStatus === 'success' && <div className="rating_popup__success"><InfoWhite /> {t('niki.b2b.vote.voted')}</div>}
                        <div className="rating_popup__summary">
                            {t('niki.b2b.vote.results')}
                            <span className="starwrapper" data-id={props.id}>
                                <span className="starempty">
                                    <StarEmpty /><StarEmpty /><StarEmpty /><StarEmpty /><StarEmpty />
                                </span>
                                <span className="starsnotempty" style={{width: ratingData.rating*20+'%'}}>
                                    <Star /><Star /><Star /><Star /><Star />
                                </span>
                            </span>
                            ({ratingData.ratingVoices} {t('niki.b2b.product.votes')})
                        </div>
                        <button className="rating_popup__close" onClick={HideStarRating}></button>
                    </div>
                    <div className="rating_popup__overlay" onClick={HideStarRating}></div>
                </div>
                {itemID && <div className="product_in_listing__code">
                    {itemID}
                    <div>{t('niki.b2b.tooltip.ref')}</div>
                </div>}
                <ul className="product_badges">
                    {(country) && <li className={['badge-country', 'flag-icon-' + country.toLowerCase()].join(' ')}>
                        {/* <div>{t('niki.tooltip.ref')}</div> */}
                    </li>}
                    {props.is_new && <li className="badge-new"><New /></li>}
                    {props.is_booklet && <li className="badge-book"><Book /></li>}
                </ul>
                <InFavorite data={props} favoritesClick={props.setClickFavorites} />
                {props.productView === 'grid' && <SuppliedPartner item_type_id={props.source_item_type_id} productView={props.productView} />}
            </div>
            <div className="product_in_listing__second">
                <NavLink to={`/productpage/${props.id}/`} onClick={onProductClick} className="product_in_listing__link">
                    <span className="product_in_listing__title">{productMatchcode}</span>
                </NavLink>
                {productBrand
                    ? props.productView === 'grid'
                        ? <div className="wrapper_product_brand_grid">
                            <div className="product_brand_grid"><TradeMark />
                                {productBrand}
                                <div>{t('niki.b2b.tooltip.brand')}</div>
                            </div>
                            {showOldData && <div className="nowrap">
                                {discount > 0 && <span className="regular_discount">-{discount}%</span>}
                            </div>}
                        </div>
                        : <div className="product_brand"><TradeMark />{productBrand}</div>
                    : null
                }
                <ProductShortDesc />
                {productIcons.length > 0 && <ul className="product_in_listing__spec_icons">
                    {productIcons.map((el, i) => <li key={`specicon${i}`} data-title={t(el.label)} className={`${el.icon.toLowerCase()}_icon`}></li>)}
                </ul>}
            </div>
            <div className="product_in_listing__third">
                { props.productView === 'grid'
                    ? showOldData
                        ? <>
                            <div className="wrapper_content_regular_price_grid">
                                {returnHistoryField()}
                                <div className="display_grid">
                                    <div className="regular_price_grid">{PriceFormat(priceOld)}</div>
                                    <div className="regular_price">{PriceFormat(price)}</div>
                                </div>
                            </div>
                            <div className="wrapper_content_regular_price_grid">
                                <QtyInBox props={props} productInBox={productInBox} stockUnitCode={stockUnitCode} />
                                {capacity
                                    ? <div className="regular_capacity">{capacity}</div>
                                    : <div className="regular_capacity" style={{color: 'white'}}>.</div>
                                }
                            </div>
                         </>
                        : <>
                            <div className="wrapper_content_regular_price_grid">
                                {returnHistoryField()}
                            </div>
                            <div className="wrapper__old_price">
                                <div>
                                    <QtyInBox props={props} productInBox={productInBox} stockUnitCode={stockUnitCode} />
                                    <div className="regular_capacity"></div>
                                </div>
                                <div className="display_grid" style={{ marginTop: GetHistoryData(props.id).length > 0 ? GetHistoryData(props.id).length === 1 ? 27 : 0 : 17}}>
                                    <div className="regular_price">{PriceFormat(price)}</div>
                                    {capacity
                                        ? <div className="regular_capacity">{capacity}</div>
                                        : <div className="regular_capacity" style={{color: 'white'}}>.</div>
                                    }
                                </div>
                            </div>
                        </>
                    : <div className={[`product_in_listing__table_price`, showOldData ? `show_old_prices` : `hide_old_prices`].join(' ')}>
                        <div>
                            {price
                                ? <>
                                    <div className="nowrap">
                                        <span className="regular_price">{PriceFormat(price)}</span>
                                        {discount > 0 && <span className="regular_discount">-{discount}%</span>}
                                    </div>
                                    <div className="regular_capacity">{capacity}</div>
                                    <RegularVatGross vat={vat} gross={gross} price={price} />
                                </>
                                : null
                            }
                        </div>
                        <div className={[`product_in_listing__table_price__second`, !showOldData && `nodata`].join(' ')}>
                            {showOldData
                                ? <>
                                    <div>
                                        <span className="regular_price">{PriceFormat(priceOld)}</span>
                                    </div>
                                    <div className="regular_capacity">{capacityOld}</div>
                                    <RegularVatGross vat={vatOld} gross={grossOld} price={priceOld} />
                                </>
                                : null
                            }
                        </div>
                        <div className={!rrp && `nodata`}>
                            {rrp
                                ? <div className="regular_rrp">
                                    <span>{t('niki.b2b.product.pvp')}</span>
                                    <div>{PriceFormat(rrp)}</div>
                                    <div className={["product_page__price", "product_page__price_list"].join(' ')}>
                                        <div>
                                            {t('niki.b2b.product.markup')}
                                        </div>
                                        <ChevronUp />
                                    </div>
                                    <div>{markup}%</div>
                                    <div className={["product_page__price", "product_page__price_down_list"].join(' ')}>
                                        <div>
                                            {t('niki.b2b.product.margin')}
                                        </div>
                                        <ChevronDown />
                                    </div>
                                    <div>{margin}%</div>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                }
                <div className={['cart_stock', clickedoncart].join(' ')} style={{ marginTop: props.productView === 'grid' ? 0 : 48 }}>
                    {props.productView !== 'grid' && <div className={["prod_qty_in_box", "prod_qty_in_box__list"].join(' ')}>
                        <div>{t('niki.b2b.tooltip.box')}</div>
                        <Box />{productInBox || 1} {stockUnitCode}
                    </div>}
                    {props.productView !== 'grid' && <div className="center"></div>}
                    <div style={{ marginTop: returnMarginTop(), width: props.productView === 'grid' ? 150 : 'auto'}}>
                        {showStock && <div className={['stock_status', stockStatus].join(' ')}>
                            {stockText}
                            <div>{t('niki.b2b.tooltip.in_stock')}</div>
                        </div>}
                    </div>
                    <div style={{ marginTop: returnMarginTop(), zIndex: 1 }}>
                        {productsInCart.includes(props.id)
                            ? cartButtonStatus === 'waiting'
                                ? <button className={[`put_to_cart`,cartButtonStatus,'put_to_cart_big_size', 'put_to_cart_notempty'].join(' ')}>
                                    <div className="spinner_ring"><div></div><div></div><div></div><div></div></div>
                                </button>
                                : <div className="div_clickedoncart">
                                {showDeletev()
                                    ? <button className="put_to_cart__small_minus" onClick={CartMinus}>-</button>
                                    : <button className="remove_from_cart" onClick={ClickOnRemoveCart}><Trash /></button>
                                }
                                    <input className="cart_amount_input" value={cartAmount} onChange={CartChange} onBlur={(e) => e.target.value === '' && RemoveCartItem()} />
                                <button className="put_to_cart__small_plus" onClick={CartPlus}>+</button>
                            </div>
                            : <div className="div_notclickedoncart">
                                <button className={[`put_to_cart`,cartButtonStatus,'put_to_cart_big_size'].join(' ')} onClick={ClickOnCartButton}>
                                    <PutToCart className="cart_icon" />
                                    <div className="spinner_ring"><div></div><div></div><div></div><div></div></div>
                                </button>
                            </div>
                        }
                    </div>
                </div>
                <InFavorite data={props} />
                {props.productView !== 'grid' && <SuppliedPartner item_type_id={props.source_item_type_id} productView={props.productView} />}
                {openHistoryProduct &&
                    <ProdHistoryPopup
                        matchcode={props?.itemDescriptions[selectedLanguage]?.name ? props.itemDescriptions[selectedLanguage].name : props.matchcode}
                        unit_code={stockUnitCode}
                        qty_in_box={productInBox}
                        item_id={itemID}
                        imgsrc={imgsrc}
                        product_id={props.id}
                        setOpenHistoryProduct={setOpenHistoryProduct}
                    />
                }
            </div>
            {isOpenAlert && <AlertPopup text={t('niki.b2b.error_adding_product_cart') + '. ' + t('niki.b2b.contact_your_manager') + '.'} closeAlert={() => setIsOpenAlert(false)} />}
        </div>
    )
})