import { useEffect, useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { priceLineID, searchClient } from './config'
import { t } from 'i18next'
import { ArrayUniqe, translationsForSearchBox } from './functions'
import { getMeilisearchIndexName } from "./helpers/apiHelper"

import { InstantSearch, SearchBox, Configure, connectInfiniteHits } from 'react-instantsearch-dom'

import { Breadcrumbs } from './breadcrumbs'
import { Product } from './components/product/Product'
import { ProductLoadingPlaceholder } from './components/productlistplaceholder'

const ProductsPerPage = 24

let segmentationInsert = ''
if (localStorage.getItem('account.segmentationids')) segmentationInsert = ArrayUniqe(localStorage.getItem('account.segmentationids').split(',')).join(',')

const startFacetFilters = [
    // `company_id=${xCompanyID}`,
    `itemPrices.${priceLineID}.priceline_id=${priceLineID}`,
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    `itemSegmentations IN [${segmentationInsert}]`,
]

const CustomInfiniteHits = connectInfiniteHits(({ hits, hasMore, refineNext }) => {
    
    const loaderRef = useRef(null)

    const [isFetching, setIsFetching] = useState(false)

    const handleScroll = () => {
        if (!hasMore || isFetching) return
        if (loaderRef.current && loaderRef.current.getBoundingClientRect().top <= window.innerHeight) {
            setIsFetching(true)
        }
    }
  
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
        // eslint-disable-next-line
    }, [hits, isFetching, hasMore])
  
    useEffect(() => {
        if (!isFetching) return
        refineNext()
        setIsFetching(false)
        // eslint-disable-next-line
    }, [isFetching])
  
    return (
        <div>
            <div className="products_listing">
                {hits.map(hit => (
                    <Product {...hit} productView='grid'/>
                ))}
            </div>
            {hasMore && <div ref={loaderRef}><ProductLoadingPlaceholder /></div>}
        </div>
    )
})

export const Search = () => {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    let queryURL = searchParams.get("q") || ''
    const defaultSearchQuery = queryURL || ''
    const [query,setQuery] = useState(queryURL || '')

    const ChangeSearchValue = e => {
        const localValue = e.target.value
        setQuery(localValue)
        const currentURL = new URL(window.location.href)
        currentURL.searchParams.set('q', localValue)
        window.history.replaceState(null, '', currentURL)
    }

    return (
        <>
            <Breadcrumbs itemlist={[
                {'url':`/search/?q=${query}`,'title':`${t('niki.b2b.header.search')} «${query}»`},
            ]} />
            
            <div className="wrapper search_page">
                <h1>{t('niki.b2b.search.h1')} &laquo;{query}&raquo;</h1>
                <InstantSearch
                    indexName={getMeilisearchIndexName("items")}
                    searchClient={searchClient}
                >
                    <SearchBox
                        defaultRefinement={defaultSearchQuery}
                        onChange={ChangeSearchValue}
                        translations={translationsForSearchBox()}
                    />
                    <Configure
                        facetFilters={startFacetFilters}
                        hitsPerPage={ProductsPerPage}
                        attributesToHighlight={[]}
                        attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions','brand','country']}
                    />
                    <CustomInfiniteHits />
                </InstantSearch>
            </div>
        </>
    )
}