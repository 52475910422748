export const TablePreloader = ({rows = 'monolith', columns = 'london'}) => {
    return (
        <>
            <div className="content_placeholder content_placeholder__with_table"></div>
            {Array.from(rows).map((e,i) =>
                <div className="table_loader" key={`tr${e}${i}`} style={{gridTemplateColumns: `repeat(${columns.length}, 1fr)`}}>{Array.from(columns).map((f,j) =>
                    <div className="content_placeholder" key={`td${f}${j}`}></div>
                )}</div>
            )}
        </>
    )
}