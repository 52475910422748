import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'
import { xCompanyID, xTenant } from "../config"
import { getRequestOptions } from "../helpers/apiHelper"

import axios from 'axios'

import { Breadcrumbs } from "../breadcrumbs"
import { Alert } from '../components/alert'
import { ContactsAside } from "../components/contactsaside"

import './../assets/css/staticpages.css'

// import { ReactComponent as Printer } from './../assets/svg/feedbackstatusprinter.svg'
import { ReactComponent as Upload } from './../assets/svg/feedbackupload.svg'

const ShowLoader = () => document.querySelector('.loader').classList.add('active')
const HideLoader = () => document.querySelector('.loader').classList.remove('active')

const FormatDate = inputData => {
    const fullDate = new Date(inputData)
    return `${String(fullDate.getDate()).padStart(2,'0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}
const FormatDateTime = inputData => {
    const fullDate = new Date(inputData)
    return `${String(fullDate.getDate()).padStart(2,'0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()} at ${fullDate.getHours()}:${String(fullDate.getMinutes()).padStart(2,'0')}`
}

const GetFeedbackData = async feedbackID => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/feedbacks/${feedbackID}?realKey=true&include=feedbackType,status`,getRequestOptions())
    const json = await response.json()
    return json
}
const GetMessageData = async messageID => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/message/${messageID}?include=comments`,getRequestOptions())
    const json = await response.json()
    return json
}
const GetCommentData = async messageID => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/comments?realKey=1&message_id%5B%5D=${messageID}&include=attachments&layout=collection`,getRequestOptions())
    const json = await response.json()
    return json
}
// const GetImageByURL = async url => {
//     const config = {
//         headers: {
//             'content-type': 'multipart/form-data',
//             'X-Tenant': process.env.REACT_APP_XTENANT,
//             'X-CompanyID': xCompanyID,
//             'Authorization': `Bearer ${localStorage.getItem('access.token')}`,
//         },
//         responseType: 'blob',
//     }
//     const res = await axios.get(url,config)
//     return res
// }
const feedbackDataDefault = { id: '', processID: '', replyEmail: '', date: '', name: '' }

const FeedbackStatusContent = props => {
    const feedbackID = props.feedbackID
    const [messageIDGlobal,setMessageIDGlobal] = useState('')
    const [commentIDFirst,setCommentIDFirst] = useState('')
    const [userMessage,setUserMessage] = useState('')
    const [attachments,setAttachments] = useState({})
    const [creatorName,setCreatorName] = useState('')
    const [uploadedImages,setUploadedImages] = useState([])
    const [messageText,setMessageText] = useState([])
    const [sendCopyEmail,setSendCopyEmail] = useState('false')
    const [comments,setComments] = useState([])
    // eslint-disable-next-line
    const [firstMessageImages,setFirstMessageImages] = useState([])
    const [feedbackData,setFeedbackData] = useState(feedbackDataDefault)

    useEffect(()=>{
        ShowLoader()
        try {
            GetFeedbackData(feedbackID)
            .then(json => {
                json.data && setFeedbackData({
                    id: json.data.id_real,
                    processID: json.data.process_id,
                    processIDReal: json.data.process_id_real,
                    subject: json.data.matchcode,
                    replyEmail: json.data.reply_email,
                    date: json.data.created_at,
                    name: json.data.reply_name,
                    orderID: json.data.document_id_real,
                    messageID: json.data.message_id,
                    status: json.data.status && json.data.status.data && json.data.status.data.matchcode.toLowerCase(),
                })
                json.data.message_id && setMessageIDGlobal(json.data.message_id)
                json.data.message_id && GetMessageData(json.data.message_id).then(json => {
                    if (json.data && json.data.comments && json.data.comments.data && json.data.comments.data[0] && json.data.comments.data[0].value && json.data.comments.data[0].value.message) {
                        setCommentIDFirst(json.data.comments.data[0].id)
                        setUserMessage(json.data.comments.data[0].value.message)
                    }
                    setComments([])
                    if (json.data && json.data.comments && json.data.comments.data && json.data.comments.data.length > 0) json.data.comments.data.forEach(e => {
                        const tempObj = {
                            id: e.id,
                            name: e.creator.data.Name,
                            date: e.created_at,
                            message: e.value.message,
                        }
                        setCreatorName(e.creator.data.Name)
                        setComments(prevState => ([...prevState, tempObj]))
                    })
                })
                json.data.message_id && GetCommentData(json.data.message_id).then(json => {
                    setAttachments({})
                    if (json.data && json.data.length > 0) {
                        json.data.forEach(e => {
                            const commentIDL = e.id
                            let tempArr = []
                            let imgArr = []
                            if (e.attachments && e.attachments.data && e.attachments.data.length > 0) {
                                e.attachments.data.forEach(attachment => {
                                    const url = attachment.awsFileUrl
                                    tempArr.push(url)
                                    const image = url
                                    imgArr.push(image)
                                    setFirstMessageImages(prevState => ([...prevState, image]))
                                    /*
                                    GetImageByURL(url).then(res => {
                                        const image = URL.createObjectURL(res.data)
                                        imgArr.push(image)
                                        setFirstMessageImages(prevState => ([...prevState, image]))
                                    })
                                    */
                                })
                            }
                            const newObj = attachments
                            newObj[commentIDL] = imgArr
                            setAttachments(newObj)
                        })
                    }
                })
            })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
        // eslint-disable-next-line
    },[])

    const AddReplyForm = e => {
        e.preventDefault()
        const requestOptionsComment = getRequestOptions('POST', null, 'application/json')
        requestOptionsComment.body = JSON.stringify({
            text: messageText,
            send_copy_to_email: sendCopyEmail,
        })
        
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/messages/${messageIDGlobal}/comments?include=attachments`,requestOptionsComment)
        .then(response => response.json())
        .then(json => {
            if (json.data) {
                const localCommentID = json.data.id
                const tempObj = {
                    id: localCommentID,
                    name: creatorName,
                    date: json.data.created_at,
                    message: json.data.value.message,
                }
                setComments(prevState => ([...prevState, tempObj]))
                let tempArr = []
                let imgArr = []
                if (json.data.attachments && json.data.attachments.data && json.data.attachments.data.length > 0) {
                    json.data.attachments.data.forEach(attachment => {
                        const url = attachment.awsFileUrl
                        tempArr.push(url)
                        const image = url
                        imgArr.push(image)
                        setFirstMessageImages(prevState => ([...prevState, image]))
                        /*
                        GetImageByURL(url).then(res => {
                            const image = URL.createObjectURL(res.data)
                            imgArr.push(image)
                            setFirstMessageImages(prevState => ([...prevState, image]))
                        })
                        */
                    })
                    const newObj = attachments
                    newObj[localCommentID] = imgArr
                    setAttachments(newObj)
                    setUploadedImages([])
                    document.getElementById(localCommentID).scrollIntoView()
                }
                setMessageText('')
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const SelectFile = e => {
        let data = new FormData()
       

        const files = Array.from(e.target.files)
        files.forEach(file => data.append('attachments[]',file))

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'X-Tenant': xTenant,
                'X-CompanyID': xCompanyID,
                'Authorization': `Bearer ${localStorage.getItem('access.token')}`,
            },
        }
        ShowLoader()
        axios.post(`${process.env.REACT_APP_API_DOMAIN}/messages/${messageIDGlobal}/comment-upload`,data,config)
        .then(res => {
            if (res.data && res.data.length > 0) {
                res.data.forEach(e => {
                    const url = e.url.medium || e.url
                    setUploadedImages(prevState => ([...prevState, url]))
                })
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    return (
        <>
            <Breadcrumbs itemlist={[
                {'url':'/account/','title':t('niki.b2b.account.my')},
                {'url':'/feedback/','title':t('niki.b2b.menu.feedback')},
                {'url':`/feedback-status/${feedbackID}/`,'title':`${t('niki.b2b.menu.feedback')} ${feedbackData.id}`},
            ]} />
            <div className="wrapper">
                <h1>{t('niki.b2b.menu.feedback')} {feedbackData.id}</h1>

                <div className="feedback_status__columns">
                    <div>
                        {feedbackData.status && <Alert text={`Status ${feedbackData.status}`} type={feedbackData.status} />}
                        <div className="feedback_status__columns__grey">
                            <h2>{feedbackData.subject}</h2>
                            {(feedbackData.name && feedbackData.date) && <div className="feedback_status__columns__grey__sent_by">{t('niki.b2b.feedback.sentby')}: {feedbackData.name}, on {FormatDateTime(feedbackData.date)}</div>}
                            <div className="feedback_status__columns__grey__text">{userMessage}</div>
                            {(attachments[commentIDFirst] && attachments[commentIDFirst].length > 0)
                            &&
                                <div className="feedback_status__columns__grey__attachments">
                                    <div className="feedback_status__columns__grey__attachments__header">{t('niki.b2b.feedback.attachments')}</div>
                                    <div className="feedback_status__columns__grey__attachments__images">
                                        {attachments[commentIDFirst].map((e,i) =>
                                            <a
                                                href={e}
                                                target="_blank"
                                                rel="noopener noreferrer nofollow"
                                                key={`attachment${i}`}
                                                className="feedback_status__columns__grey__attachments__image"
                                            ><img src={e} alt="attachment" /></a>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>

                        {comments.length > 0 && comments.map((e,i) =>
                            <div className="feedback_status__reply" key={`reply${i}`} id={e.id}>
                                <h2>{t('niki.b2b.feedback.replyfrom')} {e.name}</h2>
                                <div className="feedback_status__columns__grey__sent_by">{t('niki.b2b.feedback.sentby')}: {e.name}, on {FormatDateTime(e.date)}</div>
                                <div>{e.message}</div>
                                {(attachments[e.id] && attachments[e.id].length > 0)
                                &&
                                    <div className="submit_feedback__uploaded_images">{attachments[e.id].map((e,i) => <a className="submit_feedback__uploaded_image" key={`attachment${e.id}${i}`} href={e} target="_blank" rel="noopener noreferrer nofollow"><img src={e} alt={`Attachment feddback ${e.id}`} /></a>)}</div>
                                }
                            </div>
                        )}

                        <form onSubmit={AddReplyForm}>
                            <h2>{t('niki.b2b.feedback.addreply')}</h2>
                            <textarea
                                className="form-control marginbottom20"
                                placeholder={t('niki.b2b.feedback.message')}
                                onChange={e => setMessageText(e.target.value)}
                                value={messageText}
                                required
                            ></textarea>
                            <div className="marginbottom20">
                                <label className="btn feedback_status__upload"><input type="file" name="file" onChange={SelectFile} multiple accept="image/*" />{t('niki.b2b.feedback.uploadattachments')}<Upload /></label>
                            </div>
                            {uploadedImages.length > 0
                            &&
                                <div className="submit_feedback__uploaded_images">{uploadedImages.map((e,i) => <a className="submit_feedback__uploaded_image" key={`uploadedimage${i}`} href={e} target="_blank" rel="noopener noreferrer nofollow"><img src={e} alt="Uploaded" /></a>)}</div>
                            }
                            <label className="feedback_status__checkbox">
                                <input
                                    type="checkbox"
                                    onChange={e => setSendCopyEmail(e.target.checked ? 'true' : 'false')}
                                /> {t('niki.b2b.feedback.copytoemail')}
                            </label>
                            <button type="submit" className="btn btn-info">{t('niki.b2b.feedback.submit')}</button>
                        </form>
                    </div>

                    <div>
                        <div className="feedback_status__details">
                            <h2>{t('niki.b2b.feedback.details')}</h2>
                            <div className="feedback_status__details__table">
                                <strong>{t('niki.b2b.feedback.id')}</strong>
                                <span>{feedbackData.id}</span>
                            </div>
                            <div className="feedback_status__details__table">
                                <strong>{t('niki.b2b.feedback.orderid')}</strong>
                                <span>{feedbackData.orderID}</span>
                            </div>
                            <div className="feedback_status__details__table">
                                <strong>{t('niki.b2b.debts.processid')}</strong>
                                <span>{feedbackData.processIDReal}</span>
                            </div>
                            <div className="feedback_status__details__table">
                                <strong>{t('niki.b2b.product.date')}</strong>
                                <span>{FormatDate(feedbackData.date)}</span>
                            </div>
                            {/* <hr />
                            <button className="feedback_status__details__button"><Printer />{t('niki.b2b.feedback.print')}</button> */}
                        </div>
                        <div className="contacts__support__header">{t('niki.b2b.account.supportteam')}</div>
                        <ContactsAside type="buttons_bottom" />
                    </div>
                </div>
            </div>
        </>
    )
}

export const FeedbackStatus = () => {
    const { id } = useParams()
    return (
        <FeedbackStatusContent feedbackID={id} />
    )
}