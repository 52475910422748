import { useState, useEffect } from "react"
import { Breadcrumbs } from "../breadcrumbs"
import { t } from "i18next"
import { getRequestOptions } from "../helpers/apiHelper"

const MandatoryDisclosuresContent = () => {
    const [data,setData] = useState('')
    const [dataLoaded,setDataLoaded] = useState(false)
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_DOMAIN}/terms-and-conditions`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data && setData(json.data[0].body)
            json.data && setDataLoaded(true)
        })
    },[])
    return (
        <div className="wrapper">
            <h1>{t('niki.b2b.footer.mandatorydisclosures')}</h1>
            {!dataLoaded
                ? <ContentPlaceholder />
                : <div dangerouslySetInnerHTML={{ __html: data }} />
            }
        </div>
    )
}

export const MandatoryDisclosures = () => {
    return (
        <>
            <Breadcrumbs itemlist={[{'url':'/mandatory-disclosures/','title':t('niki.b2b.footer.mandatorydisclosures')}]} />
            <MandatoryDisclosuresContent />
        </>
    )
}

const ContentPlaceholder = () => {
    return (
        <>
            <div className="content_placeholder marginbottom40 width50"></div>
            <div className="content_placeholder marginbottom15"></div>
            <div className="content_placeholder marginbottom15"></div>
            <div className="content_placeholder marginbottom40 width75"></div>
            <div className="content_placeholder marginbottom15 width25"></div>
            <div className="content_placeholder marginbottom15"></div>
            <div className="content_placeholder marginbottom15"></div>
            <div className="content_placeholder marginbottom40 width75"></div>
            <div className="content_placeholder marginbottom15 width25"></div>
            <div className="content_placeholder marginbottom15"></div>
            <div className="content_placeholder marginbottom15"></div>
            <div className="content_placeholder marginbottom40 width75"></div>
            <div className="content_placeholder marginbottom15 width25"></div>
            <div className="content_placeholder marginbottom15"></div>
            <div className="content_placeholder marginbottom15"></div>
            <div className="content_placeholder marginbottom40 width75"></div>
        </>
    )
}