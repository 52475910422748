import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import { FormatDate } from '../../functions'

import { changeWarehousePickup, setDataCartDocumentRequest } from '../../reduxToolkit/checkoutSlice'
import { ReactComponent as Truck } from '../../assets/svg/checkouttruck.svg'
import { ReactComponent as TruckSmall } from '../../assets/svg/checkoutTruckSmall.svg'
import { ReactComponent as MonolitSmall } from '../../assets/svg/monolithIconSmall.svg'
import { ReactComponent as Location } from '../../assets/svg/checkoutlocation.svg'
import CustomDatePicker from "../DatePicker"

const today = new Date();
const milliseconds = today.getTime();

export const DeliveryMethods = ({deliveryMethod, RadioClick, AccordeonClick, startDate, setStartDate, startDateWarehouse, setStartDateWarehouse, isStepTwo}) => {

    const dispatch = useDispatch()

    const shoppingCartSummary = JSON.parse(localStorage.getItem('suppliers_data')) || []
    const warehouseAddress = localStorage.getItem('warehouse.address') ? localStorage.getItem('warehouse.address') : ''
    const warehouseAddressID = localStorage.getItem('warehouse.id.real')
    const warehouseAddressShort = warehouseAddress?.slice(0, warehouseAddress.lastIndexOf(','))
    const arrayDifferentSuppliers = []
    const hasDifferentSuppliers = shoppingCartSummary.filter(e => e.estimated_delivery !== null)
    const arrayWithNull = shoppingCartSummary.filter(e => e.estimated_delivery === null)

    const warehousePickupChange = e => {
        dispatch(changeWarehousePickup(e.target.value))
    }

    hasDifferentSuppliers.length > 0 && hasDifferentSuppliers.forEach(e => Array.isArray(e) ? arrayDifferentSuppliers.push(e) : arrayDifferentSuppliers.push([e]))
    arrayDifferentSuppliers.unshift(arrayWithNull)
    
    const [startDateMulti, setStartDateMulti] = useState([])

    const getDateSupplier = (supplierName, howDate) => {        
        howDate === null
            ? handleStartDateMulti(supplierName, today.setTime(milliseconds+86400000)) 
            : handleStartDateMulti(supplierName, today.setTime(milliseconds+86400000*howDate))
    }
    
    const handleStartDateMulti = (id, value) => {
        const newStates = [...startDateMulti]
        newStates[id] = value
        setStartDateMulti(newStates)
    }

    const returnSupplierTotalSum = (supplier) => {
        if (supplier.length > 1) {
            const valuta = supplier[0].total.slice(0,1)
            let sumTotal = supplier.reduce((s, el) => s + +el.total.slice(1, el.total.length), 0)
            return valuta+parseFloat(sumTotal).toFixed(2)
        } else {
            return supplier[0].total
        }
    }

    useEffect(() => {
        if (isStepTwo) {
            let counter = 0
            const newArrayCartDocumentRequest = []
           
            const addDateToObjects = (array) => {
                return array.forEach((innerArray, i) => {
                    innerArray.length === 1 ? counter += 1 : counter += 0
                    if (Array.isArray(innerArray)) { 
                        return addDateToObjects(innerArray);
                    } else {
                        const date = FormatDate(startDateMulti[counter]).split('.').reverse().join('-')
                        newArrayCartDocumentRequest.push({ entity_account_id: innerArray.entity_account_id, delivery_at: date })
                    }
                });
            };
            addDateToObjects(arrayDifferentSuppliers)
            dispatch(setDataCartDocumentRequest(newArrayCartDocumentRequest))
        }
         // eslint-disable-next-line
    }, [isStepTwo])

    return (
        <div className="checkout_accorderon checkout_accorderon__delivery_method active">
            <div className="checkout_accorderon__title" onClick={AccordeonClick}>{t('niki.b2b.cartflow.deliverymethod')}</div>
            <div className="checkout_accorderon__content">
                <div className="checkout_accorderon__content__delivery">
                    <input
                        id="standardradio"
                        type="radio"
                        name="delivery"
                        value="1"
                        className="checkout_accorderon__content__delivery__input"
                        onClick={RadioClick}
                        defaultChecked={true}
                    />
                    <label className="checkout_accorderon__content__delivery__label" htmlFor="standardradio">{t('niki.b2b.cartflow.standarddelivery')}</label>
                    <div className="checkout_accorderon__content__delivery__price">{t('niki.b2b.cartflow.free')}</div>
                    <div className="checkout_delivery__monday_to_friday">{t('niki.b2b.expected_delivery__monday_through_friday')}</div>

                    {shoppingCartSummary.length > 0 && arrayDifferentSuppliers.length > 0
                        ? <>
                            {arrayDifferentSuppliers.map((suppliers, i) => {
                                return (
                                    <div key={i} className="marginbottom30">
                                        <div>{t('niki.b2b.cartflow.delivery')} {i + 1}</div>
                                        <div className="checkout_accorderon__content__delivery__info_accordeon">
                                            <Truck />
                                            <strong>{t('niki.b2b.cartflow.expecteddelivery')}:</strong>
                                            <CustomDatePicker
                                                selected={startDateMulti[i] || getDateSupplier(i, suppliers[0].estimated_delivery)}
                                                setStartDate={(e) => handleStartDateMulti(i, e)} calendarStartDay={suppliers[0].estimated_delivery}
                                                tomorow={true} disabled={deliveryMethod !== '1'} multipleId={i} payload={returnSupplierTotalSum(suppliers)}
                                            />              
                                            </div>
                                                {suppliers.map((supplier, ind) => {
                                                    return (
                                                        <div className="checkout_accorderon__supplier_info" key={ind}>
                                                            <div>
                                                                {supplier.supplierName.includes('Monolit') ? <MonolitSmall /> : <TruckSmall />}
                                                                {!supplier.supplierName.includes('Monolit') && t('niki.b2b.cart.partner')} {supplier.supplierName}</div>
                                                            <div>{t('niki.b2b.cartflow.totalskus')}: <div>{supplier.count}</div></div>
                                                        </div>
                                                    )
                                                })}
                                    </div>
                                )
                            })}
                        </>
                        : <>
                            <div className="checkout_accorderon__content__delivery__info">
                                <Truck />
                                <strong>{t('niki.b2b.cartflow.expecteddelivery')}:</strong>
                                <CustomDatePicker selected={startDate} setStartDate={setStartDate} tomorow={true} disabled={deliveryMethod !== '1'} />
                            </div>
                            {shoppingCartSummary.map((s, i) => {
                                return (
                                    <div className="checkout_accorderon__supplier_info" key={i}>
                                        <div>
                                            {s.supplierName.includes('Monolit') ? <MonolitSmall /> : <TruckSmall />}
                                            {!s.supplierName.includes('Monolit') && t('niki.b2b.cart.partner')} {s.supplierName}</div>
                                        <div>{t('niki.b2b.cartflow.totalskus')}: <div>{s.count}</div></div>
                                    </div>
                                )
                            })}
                        </>
                    }
                </div>
                <div className="checkout_accorderon__content__delivery active">
                    <input
                        id="warehouseradio"
                        type="radio"
                        name="delivery"
                        value="2"
                        className="checkout_accorderon__content__delivery__input"
                        onClick={RadioClick}
                        defaultChecked={false}
                    />
                    <label className="checkout_accorderon__content__delivery__label" htmlFor="warehouseradio">{t('niki.b2b.cartflow.warehousepickup')}*</label>
                    <div className="checkout_accorderon__content__delivery__price">{t('niki.b2b.cartflow.free')}</div>
                    <div className="checkout_accorderon__content__delivery__grey_info">
                        <Location /> London Warehouse
                        <br />
                        {warehouseAddress?.split('.').reverse().join('.')}
                    </div>
                    <div className="checkout_accorderon__content__delivery__info">
                        <Truck />
                        <strong>{t('niki.b2b.cartflow.expecteddelivery')}:</strong>
                        <CustomDatePicker filterDate={true} selected={startDateWarehouse} setStartDate={setStartDateWarehouse} tomorow={true} disabled={deliveryMethod !== '2'} />
                    </div>
                    {shoppingCartSummary.length > 0 && 
                        <>{shoppingCartSummary.map((supplier, i) => {
                            return (
                                <div className="checkout_accorderon__supplier_info" key={i}>
                                    <div>
                                        {supplier.supplierName.includes('Monolit') ? <MonolitSmall /> : <TruckSmall />}
                                        {!supplier.supplierName.includes('Monolit') && t('niki.b2b.cart.partner')} {supplier.supplierName}
                                    </div>
                                    <div>{t('niki.b2b.cartflow.totalskus')}: <div>{supplier.count}</div></div>
                                </div>
                            )
                        })}</>
                    }
                    <div className="checkout_accorderon__content__delivery__change_pickup">{t('niki.b2b.cartflow.changepickuplocation')}</div>
                    <select className="checkout_accorderon__content__delivery__select" onChange={warehousePickupChange}>
                        <option value={warehouseAddressID}>{warehouseAddressShort}</option>
                    </select>
                    {/* <div>* London Warehouse operating hours: Mondays to Fridays 8 a.m. to 5 p.m.</div> */}
                </div>
            </div>
        </div>
    )
}