import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t } from 'i18next'
import { ShowLoader, HideLoader, FormatDate, LangFix } from '../../functions'

import { getRequestOptions } from '../../helpers/apiHelper'
import { setIsOpenTermsSaleAlert } from '../../reduxToolkit/universalSlice'

import { ReactComponent as ArrowRight } from '../../assets/svg/checkoutwhiterightarrow.svg'

export const DeliveryPayment = ({ProceedToPayment, startDate, startDateWarehouse, deliveryMethod, step, paymentMethod, isStepTwo, setIsErrorOrderCreation, isAccountBlocked}) => {
    const dispatch = useDispatch()

    const [error,setError] = useState('good')
    const [loadingStatus,setLoadingStatus] = useState('waiting')
    const [disabled, setDisabled] = useState(false)
    const [isAcceptTerms, setAcceptTerms] = useState(false)

    const deliveryMethodRDX = parseInt(useSelector(state => state.checkoutContent.deliveryMethod))
    const additionalNotesText = useSelector(state => state.checkoutContent.additionalNotes)
    const paymentMethodRDX = useSelector(state => state.checkoutContent.paymentMethod)
    const warehousePickup = useSelector(state => state.checkoutContent.warehousePickup)
    const deliveryDates = useSelector(state => state.checkoutContent.deliveryDates)
    const dataCartDocumentRequest = useSelector(state => state.checkoutContent.dataCartDocumentRequest)

    const getPaymentTranslations = (paymentObject) => {
        return JSON.parse(paymentObject)[LangFix(localStorage.getItem('locale'))] ? JSON.parse(paymentObject)[LangFix(localStorage.getItem('locale'))] : ''
    }
   
    const FinalizeOrder = () => {
        if (document.querySelector('.accept_terms_of_sale').checked) {
            let bodyRequest = {
                carrier_id: '1',
                payment_id: paymentMethodRDX,
                footer_message: additionalNotesText,
            }
            if (deliveryMethodRDX === 1) {
                if (dataCartDocumentRequest.length > 0) {
                    bodyRequest.split_delivery_at = dataCartDocumentRequest
                    bodyRequest.entity_address_id = localStorage.getItem('address.id.real')
                    bodyRequest.split_by = 'drop_cross_mix'
                    // split_by: 'dropshipping',    
                } else {
                    bodyRequest.delivery_at = startDate
                    bodyRequest.entity_address_id = localStorage.getItem('address.id.real')
                }   
            } else {
                bodyRequest.delivery_at = startDateWarehouse
                bodyRequest.warehouse_pickup_id = warehousePickup
            }
            
            const requestOptionsOrder = getRequestOptions('POST', null, 'application/json')
            requestOptionsOrder.body = JSON.stringify(bodyRequest)
            setDisabled(true)
            setLoadingStatus('loading')
            let nextStep = '/thankyou?'
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-to-document/${localStorage.getItem('cart.uuid')}`,requestOptionsOrder)
            .then(response => response.json())
                .then(json => {
                    if (json.length > 0) {
                        let params = 'orderIds='
                        json.forEach(u => params+=u.uuid+',')
                        setError('good')
                        nextStep += params.slice(0,params.length-1)
                        localStorage.removeItem('cart.uuid')
                        localStorage.removeItem('cart.id')
                        localStorage.removeItem('cart.id.real')
                        window.location.href = nextStep
                    } else {
                        setIsErrorOrderCreation(true)
                    }
                })
                .catch(error => {
                    setIsErrorOrderCreation(true)
                    console.error(error)
                })
                .finally(() => {
                    HideLoader()
                    setLoadingStatus('waiting')
                    setDisabled(false)
                })
        } else {
            setError('accept')
        }
    }

    const onCheckboxAcceptTerms = () => {
        !isAcceptTerms && setAcceptTerms(prev => !prev)
        dispatch(setIsOpenTermsSaleAlert(true))
        setAcceptTerms(prev => !prev)
        // document.querySelector('.terms_sale').classList.add('active')
    }

    const onCheckboxAcceptChange = () => {
        setAcceptTerms(prev => {
            !prev && setError('good')
            return !prev
        })
    }
    
    return (
        <div className="checkout_columns__aside__unit">
            <div className="checkout_columns__aside__unit__header">{t('niki.b2b.cartflow.deliverypayment')}</div>
            {deliveryMethod === '1' && deliveryDates.length > 0
                ? <>
                    {isStepTwo
                        ? deliveryDates.map((s, i) => {
                            return (
                                <div key={i}>
                                    <div>{t('niki.b2b.cartflow.delivery')} {i+1}</div>
                                    <div className="cart_aside__table">
                                        <div>{t('niki.b2b.product.date')}</div>
                                        <div>&nbsp;{s.date}</div>
                                    </div>
                                    <div className="cart_aside__table">
                                        <div>{t('niki.b2b.cart.total')}</div>
                                        <div>&nbsp;{s.total}</div>
                                    </div>
                                    <div className="cart_aside__table">
                                        <div>{t('niki.b2b.cartflow.paymentmethod')}</div>
                                        <div>{getPaymentTranslations(localStorage.getItem('payment_type_name_translations')) || paymentMethod}</div>                                    </div>
                                    <hr />
                                </div> 
                        )
                        })
                        : deliveryDates.map((s, i) => {
                            return (
                                <div className="cart_aside__table" key={i}>
                                    <div>{t('niki.b2b.cartflow.delivery')} {i+1}</div>
                                    <div>&nbsp;{s.date}</div>
                                </div>
                            )
                        })
                }
                </>
                : <div className="cart_aside__table">
                    <div>{t('niki.b2b.cartflow.estimateddelivery')}</div>
                    {deliveryMethod === '1' && <div>&nbsp;{FormatDate(startDate)}</div>}
                    {deliveryMethod === '2' && <div>&nbsp;{FormatDate(startDateWarehouse)}</div>}
                </div>
            }
            {(deliveryDates.length === 0 || !isStepTwo) && <div className="cart_aside__table">
                <div>{t('niki.b2b.cartflow.paymentmethod')}</div>
                <div>{getPaymentTranslations(localStorage.getItem('payment_type_name_translations')) || paymentMethod}</div>
            </div>}
            {(deliveryDates.length === 0 || !isStepTwo) && <div className="cart_aside__table">
                <div>{t('niki.b2b.cartflow.delivery')}</div>
                <div>{t('niki.b2b.cartflow.free')}</div>
            </div>}
            {step === 'two' && error === 'accept' && <div className="please_accept_terms_of_sale">{t('niki.b2b.cartflow.acceptterms')}</div>}
            {step === 'two'
                &&
                <div className="checkout_aside_label">
                    <input type="checkbox" className="accept_terms_of_sale" checked={isAcceptTerms} onClick={onCheckboxAcceptChange}/>
                    {t('niki.b2b.cartflow.accept')}&nbsp;
                    <span className="term_of_sale" onClick={onCheckboxAcceptTerms}>
                        {t('niki.b2b.cartflow.termsofsale')}
                    </span>
                </div>
            }
        
            {step === 'one' && <button className={`btn ${isAccountBlocked ? '' : 'btn-green'} btn-block btn-proceed-payment`} disabled={isAccountBlocked} onClick={ProceedToPayment}>{t('niki.b2b.cartflow.proceedtopayment')} <ArrowRight className="top0" /></button>}
            {step === 'two'
                &&
                <button
                    className={[`btn ${isAccountBlocked ? '' : 'btn-green'} btn-block btn-proceed-payment submit_loader`, loadingStatus].join(' ')}
                    onClick={FinalizeOrder}
                    disabled={disabled || isAccountBlocked}
                >
                    <div className="waiting">{t('niki.b2b.cartflow.finalizeorder')} <ArrowRight /></div>
                    <div className="spinner_ring loading"><div></div><div></div><div></div><div></div></div>
                </button>
            }
        </div>
    )
}