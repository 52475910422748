import { xCompanyID, xTenant } from '../config'
import { ShowLoader, HideLoader, LangFix } from '../functions.js'
import { setIsOpenProductNotFoundAlert, setIsOpenNoCartAlert, setIsNotImpossiblePrint } from '../reduxToolkit/universalSlice'
import { CheckTokenLifeTime } from './checkAccessToken'

export const guruRequestOptions = {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CompanyID': xCompanyID,
        'Authorization': `Bearer ${process.env.REACT_APP_MEILISEARCH_SECRET_KEY}`
    }
}

export const getRequestOptions = (method, accept, contentType) => {
    return {
        method: method ? method : 'GET',
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        }
    }
}

export const getRequestApi = async ({ method, accept, contentType, URL, body = null }) => {
    const headers = {
        method: method ? method : 'GET',
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        },
        body
    }

    let response = {}
    try {
        const result = await fetch(URL, headers)
        if (result.status === 401) {
            response = result
            CheckTokenLifeTime()
        } else {
            response = result
        }
    } catch (error) {
        console.error(error)
    }

    return response
}

export const getRequestOptionsNoAuth = (method, accept, contentType) => {
    return {
        method: method ? method : 'GET',
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
        }
    }
}

export const getBanners = async (item_segmentations = '') => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/contents?include=contentType,attachments,items${item_segmentations}`, getRequestOptionsNoAuth())
    const json = await response.json()
    return json
}

export const updateProductInCart = (productId, qty) => {
    const requestOptions = getRequestOptions('POST', null, 'application/json')
    requestOptions.body = JSON.stringify({ qty: typeof qty === "string" ? parseFloat(qty) : qty })

    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details/${productId}`, requestOptions)
        .then(response => {
            // console.log(response)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
}

export const getOpenPDF = (documentUuid, dispatch) => {
    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/documents/${documentUuid}/print?include=details`, getRequestOptions('GET', 'text/plain'))
        .then(response => response.text())
        .then(url => window.open(`http://${url}`, '_blank', 'noreferrer'))
        .catch(error => {
            dispatch(setIsNotImpossiblePrint(true))
            console.error(error)
        })
        .finally(() => HideLoader())
}

export const getCartsData = async (url) => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/cart-headers/${localStorage.getItem('account.entity.id')}?include=status,company,cartDetails&realKey=1&limit=5&orderBy=id&sortedBy=desc`
    const response = await fetch(url ? url : URL, getRequestOptions())
    const json = await response.json()
    return json
}

export const getAccountBlocked = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/entity-account-check/${localStorage.getItem('account.entity.id')}`
    const response = await fetch(URL, getRequestOptions())
    const json = await response.json()
    // return {blocked: true}
    return json
}

export const returnErrorDuringAddProductToCart = (error, dispatch) => {
    if (localStorage.getItem('cart.uuid')) {
        getCartsData()
            .then(json => {
                let existCart = false
                json.data && json.data.forEach(e => {
                    existCart = e.uuid === localStorage.getItem('cart.uuid') && true
                })
                existCart
                    ? dispatch(setIsOpenProductNotFoundAlert(true))
                    : dispatch(setIsOpenNoCartAlert(true))
            })
            .catch(error => {
                dispatch(setIsOpenProductNotFoundAlert(true))
                console.error(error)
            })
    } else {
        dispatch(setIsOpenProductNotFoundAlert(true))
    }
    console.error(error)
}

export const fetchPreferences = async () => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/api-module-preferences?include=module,user&realKey=1&search=module_id:999`
    const response = await fetch(url, getRequestOptions())
    const json = await response.json()
    return json
}

export const paramsForCartDetails = 'include=item,item.attachments,item.entityAccount,item.itemDiscounts,item.itemPrices,item.itemDescriptions'

export const getMeilisearchIndexName = (indexName) => `${xTenant}_${xCompanyID}_${indexName}`

export const updateMarketingCampaignIds = () => {
    const entityID = localStorage.getItem('account.entity.id.real')
    fetch(`${process.env.REACT_APP_API_DOMAIN}/entity-account/${entityID}?include=company,address,address.contact,user,company.socialValues&realKey=1&type_id=8&itemSegmentations=true`, getRequestOptions())
        .then(response => response.json())
        .then(json => {
            if (json.data) {
                localStorage.setItem('marketingCampaignIds', JSON.stringify(json.data.marketingCampaignIds))
            }
        })
        .catch(error => console.error(error))
}
