import { priceLineID } from '../config'
import { GetMainImageCart, getTranslatedName } from '../functions'
import defaultImage from '../assets/images/placeholder.jpg'
import { addCartProduct } from '../reduxToolkit/cartSlice'


export const prepareProductToCart = (p, dispatch, returnResult = false) => {
    let price = 0
    let taxIncludedPrice = 0
    if (p.item?.data?.itemPrices?.data?.length > 0) {
        const itemPrices = p?.item?.data?.itemPrices.data.filter(i => i.priceline_id === priceLineID)[0]
        price = parseFloat(itemPrices?.unit_price)
        taxIncludedPrice = parseFloat(itemPrices?.tax_included_price)
    }

    let priceOld = 0
    if (p.item?.data?.itemDiscounts?.data?.length > 0) {
        priceOld = price
        price = parseFloat(p?.item?.data?.itemDiscounts.data.filter(i => i.priceline_id === priceLineID)[0]?.unit_price)
    }
    const prodImage = p.item?.data?.attachments ? GetMainImageCart(p.item.data.attachments, p.item.data.attachment_id) : defaultImage
    
    const productData = {
        name: getTranslatedName(p.item?.data) ? getTranslatedName(p.item?.data) : p.matchcode,    
        id: p.id,
        itemid: p.product_item_id,
        productid: p.product_id,
        productitemid: +p.product_item_id,
        qty: p.qty,
        qtyinbox: +p.qty_in_box,
        unitcode: p.UNI,
        price: price,
        priceold: priceOld || price,
        vat: taxIncludedPrice > price ? (taxIncludedPrice - price) : 0,
        image: prodImage,
        sale_unit_code: p.sale_unit_code,
        entity_account_id: p.item?.data?.entityAccount?.data?.id || '',
        estimated_delivery: p.item?.data?.entityAccount?.data?.estimated_delivery || null,
    }

    if (returnResult) {
        return productData
    } else { dispatch(addCartProduct(productData)) }
}