import '../../assets/css/product.css'

export const ProductPagePlaceholder = () => {
    return (
        <div className="wrapper">
            <ul className="breadcrumbs__placeholder"><li className="content_placeholder"></li><li className="content_placeholder"></li><li className="content_placeholder"></li></ul>
            <div className="productpage_grid__placeholder">
                <div>
                    <div className="badge__placeholder content_placeholder"></div>
                    <div className="badge__placeholder content_placeholder"></div>
                </div>
                <div className="productpage_main_image__placeholder content_placeholder"></div>
                <ul className="productpage_thumbs__placeholder">
                    <li className="content_placeholder"></li>
                    <li className="content_placeholder"></li>
                    <li className="content_placeholder"></li>
                    <li className="content_placeholder"></li>
                    <li className="content_placeholder"></li>
                </ul>
                <div>
                    <div className="productpage_h1__placeholder content_placeholder"></div>
                    <ul className="productpage_icons__placeholder">
                        <li className="content_placeholder"></li>
                        <li className="content_placeholder"></li>
                        <li className="content_placeholder"></li>
                    </ul>
                    <div className="productpage_pricearea__placeholder content_placeholder"></div>
                </div>
            </div>
        </div>
    )
}